import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Subscription} from 'rxjs';
import {first, take} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CountryISO} from 'ngx-intl-tel-input';

import {Invoice} from '../../invoice';
import {InvoiceService} from '../../invoice.service';
import {AlertService, AuthenticationService} from '../../../../_services';
import {MutualDependence} from '../../../../_helpers/form-custom-validators';
import {CustomValidator} from '../../../../_components';
import {Contact, ContactService} from '../../../../profile/contacts';

@Component({
  selector: 'app-invoice-owner-data',
  templateUrl: './invoice-owner-data.component.html'
})
export class InvoiceOwnerDataComponent implements OnInit, OnDestroy {
  @Input() invoice: Invoice;
  @Input() modalRef: BsModalRef;
  @Output() changed = new EventEmitter<boolean>();
  contacts: Contact[] = [];
  ownerDataForm: FormGroup;
  submitted = false;
  loading = {
    form: false,
    contact: false
  };
  private subscription: Subscription = new Subscription();
  disabled = true;
  preferredCountries: CountryISO[] = [CountryISO.Croatia, CountryISO.Germany];
  // TooltipLabel = TooltipLabel;
  countries = [];
  currentCountry = null;
  currentCountryPhone = null;

  constructor(
    private service: InvoiceService,
    private mS: BsModalService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private contactService: ContactService,
    private auth: AuthenticationService
  ) {
    const newContact = new Contact();
    newContact.id = null;
    newContact.email = 'new';
    this.contacts.push(newContact);

    // Add user as contact as well (to change back from contact)
    this.subscription.add(
      this.auth.currentUser.subscribe((user) => {
        if (user) {
          const userContact = new Contact();
          userContact.id = user.id;
          userContact.email = user.email;
          userContact.firstname = user.firstname;
          userContact.lastname = user.lastname;
          userContact.companyname = user.companyname;
          userContact.tax_id = user.tax_id;
          userContact.address1 = user.address1;
          userContact.address2 = user.address2;
          userContact.city = user.city;
          userContact.state = user.state;
          userContact.country = user.country;
          userContact.postcode = user.postcode;
          const phoneNumber = (typeof user.phonenumber !== 'undefined' && user.phonenumber !== '-') ?
            user.phonenumber.split('.') : ['', ''];
          userContact.phonenumber = phoneNumber[1];
          userContact.telephoneNumber = phoneNumber[1] + phoneNumber[2];
          this.contacts.push(userContact);
        }
      })
    );
  }

  ngOnInit(): void {
    this.loading.contact = true;
    const contSub = this.contactService.getList({})
      .pipe(take(1))
      .subscribe((res) => {
        this.loading.contact = false;
        this.contacts = this.contacts.concat(res);
      });
    this.subscription.add(contSub);

    if (!this.invoice) {
      return;
    }
    let currentCountry = 'HR';
    const currentCountryPhone = 'HR';
    currentCountry = (this.invoice.cached_data.invoiceowner.countrycode !== undefined) ?
      this.invoice.cached_data.invoiceowner.countrycode.toUpperCase() : currentCountry;

    for (const [key, value] of Object.entries(CountryISO)) {
      this.countries.push({
        code: value.toUpperCase(),
        name: key
      });
    }
    this.currentCountry = this.countries.find(country => country.code === currentCountry);
    this.currentCountryPhone = this.countries.find(country => country.code === currentCountryPhone);

    this.createForm();
  }

  private createForm() {
    this.ownerDataForm = this.formBuilder.group({
      invoiceid: [this.invoice.invoiceid, Validators.required],
      firstname: [this.invoice.cached_data.invoiceowner.firstname, Validators.required],
      lastname: [this.invoice.cached_data.invoiceowner.lastname, Validators.required],
      companyname: [this.invoice.cached_data.invoiceowner.companyname],
      taxid: [this.invoice.cached_data.invoiceowner.taxid],
      address1: [this.invoice.cached_data.invoiceowner.address1, Validators.required],
      address2: [this.invoice.cached_data.invoiceowner.address2],
      city: [this.invoice.cached_data.invoiceowner.city, Validators.required],
      state: [this.invoice.cached_data.invoiceowner.state],
      country: [this.currentCountry.code, Validators.required],
      postcode: [this.invoice.cached_data.invoiceowner.postcode, [Validators.minLength(2), CustomValidator.numbericValidator, Validators.required]],
      email: [this.invoice.cached_data.invoiceowner.email, [Validators.email, Validators.required]],
      contactid: null,
      phone: null,
      telephoneNumber: [{value: null, disabled: this.disabled}, Validators.required],
      phonenumber: null
    }, {
      validator: MutualDependence('companyname', 'taxid')
    });

    const phoneNumber = (this.invoice.cached_data.invoiceowner.phonenumber !== null &&
      this.invoice.cached_data.invoiceowner.phonenumber !== '') ?
      this.invoice.cached_data.invoiceowner.phonenumber.split('.') : '';
    const phone = (phoneNumber.length > 1) ? phoneNumber[1] : ((phoneNumber.length > 0) ? phoneNumber[0] : '');
    const telPhone = (phoneNumber.length > 1) ? phoneNumber[0] + phoneNumber[1] : ((phoneNumber.length > 0) ? phoneNumber[0] : '');
    this.ownerDataForm.patchValue({phonenumber: phone});
    this.ownerDataForm.patchValue({telephoneNumber: telPhone});
  }

  setContact(contact) {
    const cont: Contact = contact;
    const phoneNumber = (typeof cont.phonenumber !== 'undefined' && cont.phonenumber !== '-') ?
      cont.phonenumber.split('.') : ['', ''];
    this.disabled = cont.id !== null;
    this.ownerDataForm.patchValue({contactid: cont.id});
    this.ownerDataForm.patchValue({firstname: cont.firstname});
    this.ownerDataForm.patchValue({lastname: cont.lastname});
    this.ownerDataForm.patchValue({companyname: cont.companyname});
    this.ownerDataForm.patchValue({taxid: cont.tax_id});
    this.ownerDataForm.patchValue({email: (cont.id === null) ? null : cont.email});
    this.ownerDataForm.patchValue({address1: cont.address1});
    this.ownerDataForm.patchValue({address2: cont.address2});
    this.ownerDataForm.patchValue({city: cont.city});
    this.ownerDataForm.patchValue({state: cont.state});
    this.ownerDataForm.patchValue({country: cont.country});
    this.ownerDataForm.patchValue({postcode: cont.postcode});
    this.ownerDataForm.patchValue({phonenumber: phoneNumber[1]});
    this.ownerDataForm.patchValue({telephoneNumber: phoneNumber[0] + phoneNumber[1]});
    const telephoneCtrl = this.ownerDataForm.get('telephoneNumber');
    if (!this.disabled) {
      telephoneCtrl.enable();
    } else {
      telephoneCtrl.disable();
    }
  }

  customSearchFn(term: string, item: any) {
    const cont: Contact = item;
    term = term.toLocaleLowerCase();
    if (cont.id === null && cont.email === 'new') {
      return false;
    }
    return cont.firstname.toLocaleLowerCase().indexOf(term) > -1 ||
      cont.lastname.toLocaleLowerCase().indexOf(term) > -1 ||
      (`${cont.firstname} ${cont.lastname}`).toLocaleLowerCase().indexOf(term) > -1 ||
      cont.email.toLocaleLowerCase().indexOf(term) > -1;
  }

  get f() {
    return this.ownerDataForm.controls;
  }

  telInvalidPhone() {
    const defaultClass = 'form-control';
    if (!this.submitted) {
      return defaultClass;
    } else {
      return (this.f.telephoneNumber.errors === null) ? defaultClass : `${defaultClass} is-invalid`;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (!this.ownerDataForm.valid) {
      return false;
    }
    this.loading.form = true;
    // Create new contact first
    let newContactId = null;
    if (this.ownerDataForm.value.hasOwnProperty("contactid")) {
      newContactId = this.ownerDataForm.getRawValue().contactid;
    }

    // If the newContactId is set, then we have a contact that is already created
    // so we can simply update the invoice data
    if (newContactId) {
      this.updateInvoiceData();
      return;
    }

    // If the newContactId is not set, then we need to create a new contact
    // and then update the invoice data
    this.contactService
      .add(this.ownerDataForm.value)
      .pipe(first())
      .subscribe(
        (contactId) => {
          if (contactId) {
            this.alertService.success(
              $localize`Kontakt je uspješno kreiran`,
              true
            );
            this.updateInvoiceData();
          } else {
            this.alertService.error(
              $localize`Kontakt nije spremljen. Provjeri podatke i pokušaj ponovno`
            );
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  private updateInvoiceData(): void {
    const updateDataSub = this.service
      .updateInvoiceData(this.invoice.invoiceid, this.ownerDataForm.value)
      .pipe(take(1))
      .subscribe((res) => {
        this.loading.form = false;
        if (res) {
          if (res.result === "success") {
            this.modalRef.hide();
            this.submitted = true;
            this.alertService.success(res.message);
            this.invoice.cached_data.invoiceowner = res.newdata;
            this.changed.emit(true);
          } else {
            this.alertService.error(res.message);
          }
        } else {
          this.alertService.error(
            $localize`Ne mogu promijeniti podatke na računu`
          );
        }
      });
    this.subscription.add(updateDataSub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
