import {Inject, Injectable, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';

import {environment} from '../../environments/environment';

const myScripts = [
  {
    name: 'monri',
    src: environment.monriurl
  }
];

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  private scripts: any = {};

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
    if (myScripts.length > 0) {
      myScripts.forEach((script: any) => {
        this.scripts[script.name] = {
          loaded: false,
          src: script.src,
          classList: undefined,
          dataset: undefined,
          flow: undefined,
          container: undefined
        };
      });
    }
  }

  addScript(key: string, src: string, classList: string, dataset: string[], flow: string, container: string = 'head') {
    if (typeof this.scripts[key] !== 'undefined') {
      return;
    }
    this.scripts[key] = {
      loaded: false,
      src,
      classList,
      dataset,
      flow,
      container
    };
  }

  // load a single or multiple scripts
  // load(...scripts: string[]) {
  //   const promises: any[] = [];
  //   // push the returned promise of each loadScript call
  //   scripts.forEach((script) => promises.push(this.loadScript(script)));
  //   // return promise.all that resolves when all promises are resolved
  //   return Promise.all(promises);
  // }

  toCamel(s) {
    return s.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  };

  // load the script
  loadScript(name: string, renderer: Renderer2) {
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({script: name, loaded: true, status: 'Already Loaded'});
      } else {
        // load script
        const script = renderer.createElement('script');
        script.type = 'text/javascript';
        if (this.scripts[name].flow === 'lightbox') {
          if (typeof this.scripts[name].classList !== 'undefined') {
            script.classList.add(this.scripts[name].classList);
          }
          if (typeof this.scripts[name].dataset !== 'undefined') {
            for (const [key, value] of Object.entries(this.scripts[name].dataset)) {
              const scriptKey = this.toCamel(key);
              const scriptVal = value.toString();
              script.setAttribute('data-' + scriptKey, scriptVal);
            }
          }
        }
        script.src = this.scripts[name].src;
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({script: name, loaded: true, status: 'Loaded'});
        };
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        // finally append the script tag in the DOM
        if (typeof this.scripts[name].container !== 'undefined' && this.scripts[name].container !== 'head') {
          this.document.getElementById(this.scripts[name].container)[0].appendChild(script);
        } else {
          this.document.getElementsByTagName('head')[0].appendChild(script);
        }
      }
    });
  }
}
