import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {APP_CONFIG, AppConfig} from '../app-config.module';
import {Pager} from '../support/tickets/pager';
import {HandleError, HttpErrorHandler} from '../_services';
import {Product} from '../_models/product';
import {Vps} from '../_models/vps';
import {Os} from '../_models/os';
import {VpsLog} from '../_models/vps-log';
import {VpsMonitorData} from '../_models/vps-monitor-data';
import {VpsScheduledAction} from '../_models/vps-scheduled-action';
import {VpsTask} from '../_models/vps-task';
import {ProductService} from '../_services/product.service';
import {VpsIso} from '../_models/vps-iso';
import {VpsTimezone} from '../_models/vps-timezone';
import {ServerStatusServer} from '../_models/server-status-server';
import {NewtworkIssue} from '../_models/newtwork-issue';
import {VpsStatus} from '../_models/vps-status';
import {VpsBandwidthData} from '../_models/vps-bandwidth-data';
import {VpsStatsData} from '../_models/vps-stats-data';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class ServersService {
  private readonly handleError: HandleError;

  constructor(
    private http: HttpClient,
    private productService: ProductService,
    httpErrorHandler: HttpErrorHandler,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.handleError = httpErrorHandler.createHandleError('ServersService');
  }

  /**
   * Returns bandwidth statistics from virtualizor admin.
   * TODO: See with Mario which statistics are those???
   * @param yearMonth format: YYYYMM
   */
  public getBandwidth(yearMonth: string): Observable<any> {
    const data = {
      action: 'bandwidth',
      show: yearMonth
    };
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          return res.body;
        }),
        catchError(this.handleError('getBandwidth', null))
      );
  }

  public getNewToken(): Observable<any> {
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/generate-vnc-token`, {}, httpOptions)
      .pipe(
        map((res) => {
          return res.body;
        }),
        catchError(this.handleError('getNewToken', null))
      );
  }

  /**
   * Return Vps monitor data/statistics about disk, ram, cpu, monthly usage.
   * @param vpsId number
   * @param returnNumber number of dots to return
   * @param charts charts to return
   * @param loadFromCache boolean
   */
  public getVpsMonitorData(vpsId: number, returnNumber: number = 30, charts: Array<string> = null,
                           loadFromCache: boolean = false):
    Observable<VpsMonitorData> {
    returnNumber = returnNumber <= 0 ? 30 : returnNumber;
    const data = {
      action: 'monitor',
      svs: vpsId,
      returnNumber,
      charts: undefined,
      loadFromCache,
      saveCache: !loadFromCache
    };

    if (charts !== null) {
      data.charts = charts;
    }
    return this.http.post<HttpResponse<VpsMonitorData | any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          if (typeof res.body.monthly_data !== 'undefined') {
            res.body.monthly_data.forEach(set => {
              if (typeof set.name !== 'undefined' && typeof set.series !== 'undefined' && set.name === 'bandwidth') {
                set.series.forEach(dat => {
                });
              }
            });
          }
          return res.body;
        }),
        catchError(this.handleError('getVpsMonitorData', null))
      );
  }

  /**
   * Return Vps bandwidth data.
   * @param vpsId number
   * @param month string - month to return data for (YYYYMM)
   * @param loadFromCache boolean
   */
  public getVpsBandwidthData(vpsId: number, month: string, loadFromCache: boolean = false): Observable<VpsBandwidthData> {
    const data = {
      action: 'enduser-bandwidth',
      svs: vpsId,
      loadFromCache,
      saveCache: !loadFromCache,
      month
    };

    return this.http.post<HttpResponse<VpsBandwidthData | any>>(
      `${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          if (res.body.chart_data !== undefined && res.body.chart_data !== null) {
            res.body.chart_data.forEach(dat => {
              if (dat.series !== undefined && dat.series !== null) {
                dat.series.forEach(ser => {
                  if (ser.date !== undefined && ser.date !== null){
                    ser.name = new Date(ser.date);
                  }
                });
              }
            });
          }
          return res.body;
        }),
        catchError(this.handleError('getVpsBandwidthData', null))
      );
  }

  /**
   * Return Vps stats data (those are system charts).
   * @param vpsId number
   * @param show string - month to return data for (YYYYMM)
   * @param loadFromCache boolean
   */
  public getVpsStatsData(vpsId: number, show: string, loadFromCache: boolean = false): Observable<VpsStatsData> {
    const data = {
      action: 'vps_stats',
      svs: vpsId,
      loadFromCache,
      saveCache: !loadFromCache,
      show
    };

    return this.http.post<HttpResponse<VpsStatsData | any>>(
      `${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          if (res.body.charts !== undefined && res.body.charts !== null) {
            res.body.charts.forEach(chart => {
              if (chart.chart_data !== undefined && chart.chart_data !== null) {
                chart.chart_data.forEach(dat => {
                  if (dat.series !== undefined && dat.series !== null) {
                    dat.series.forEach(ser => {
                      if (ser.date !== undefined && ser.date !== null) {
                        ser.name = new Date(ser.date);
                      }
                    });
                  }
                });
              }
            });
          }
          return res.body;
        }),
        catchError(this.handleError('getVpsStatsData', null))
      );
  }

  /**
   * Returns tasks for vps.
   * @param vpsId number
   * @param page number
   * @param pageSize number
   */
  public getServerTasks(vpsId: number, page: number, pageSize: number = 10): Observable<{ pager: Pager, tasks: VpsTask[] }> {
    const data = {
      action: 'tasks',
      svs: vpsId,
      cache: true,
      page,
      reslen: pageSize
    };
    const pager = new Pager();
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          let tasks = new Array<VpsTask>();
          if (res.body.page !== undefined) {
            pager.currentPage = page;
            pager.pageSize = pageSize;
            const totalItems = parseInt(res.body.page.maxNum, 10);
            pager.totalPages = Math.ceil(totalItems / pager.pageSize);
            pager.pages = [...Array(pager.totalPages).keys()].map(i => (i + 1));
          }
          if (res.body.tasks !== undefined) {
            tasks = res.body.tasks;
          }

          return {
            pager,
            tasks
          };
        }),
        catchError(this.handleError('getServerTasks', {pager, tasks: []}))
      );
  }

  /**
   * Schedules vps action (start, stop, power off, restart)
   * @param vpsId number
   * @param date string (format yyyy-mm-dd)
   * @param hour number (between 0 - 23)
   * @param minute number (between 1 - 59)
   * @param action number (between 0 - 3)
   */
  public scheduleShutDownAction(vpsId: number, date: string, hour: number, minute: number, action: number):
    Observable<{ result: boolean, message: string }> {
    const data = {
      action: 'self_shutdown',
      svs: vpsId,
      selfshutdown: 1,
      shutdown_action: action,
      shutdown_date: date,
      shutdown_hrs: hour,
      shutdown_min: minute,
      cache: false,
      invalidateKeys: ['tasks']
    };
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          const message = res.body.done.msg !== undefined ? res.body.done.msg : '';
          if (res.body.done.done !== undefined) {
            return {
              result: res.body.done.done,
              message
            };
          }
          return {
            result: false,
            message
          };
        }),
        catchError(this.handleError('scheduleShutDownAction', {result: false, message: 'Unknown error'}))
      );
  }

  /**
   * Deletes scheduled task.
   * @param vpsId number
   * @param scheduleId number
   */
  public deleteScheduledShutDownAction(vpsId: number, scheduleId: number): Observable<{ result: boolean, message: string }> {
    const data = {
      action: 'delete_self_shutdown',
      svs: vpsId,
      delete_timer: scheduleId,
      selfshutdown: 1,
      cache: false,
      invalidateKeys: ['tasks', 'self_shutdown']
    };
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          const message = res.body.done.msg !== undefined ? res.body.done.msg : '';
          if (res.body.done.done !== undefined) {
            return {
              result: res.body.done.done,
              message
            };
          }
          return {
            result: false,
            message
          };
        }),
        catchError(this.handleError('deleteScheduledShutDownAction', {result: false, message: 'Unknown error'}))
      );
  }

  /**
   * Returns a list of scheduled tasks
   * @param vpsId number
   * @param page page number
   * @param pageSize number
   */
  public getScheduledShutDownActions(vpsId: number, page: number, pageSize: number = 10):
    Observable<{ pager: Pager, actions: VpsScheduledAction[] }> {
    const data = {
      action: 'self_shutdown',
      svs: vpsId,
      reslen: pageSize,
      cache: true,
      page
    };
    const pager = new Pager();
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          let actions = new Array<VpsScheduledAction>();
          if (res.body.page !== undefined) {
            pager.currentPage = page;
            pager.pageSize = parseInt(res.body.page.len, 10);
            const totalItems = parseInt(res.body.page.maxNum, 10);
            pager.totalPages = Math.ceil(totalItems / pager.pageSize);
            pager.pages = [...Array(Math.ceil(pager.totalPages / pager.pageSize)).keys()].map(i => (i + 1));
          }
          if (res.body.self_shutdown !== undefined) {
            actions = res.body.self_shutdown;
          }
          return {
            pager,
            actions
          };
        }),
        catchError(this.handleError('getScheduledShutDownActions', {pager, actions: []}))
      );
  }

  /**
   * Returns vps details by user name and host name.
   * @param user string
   * @param vphostname string
   * @param vpsid string
   * @param loadFromCache boolean
   */
  public getVsList(user: string, vphostname: string, vpsid: string = '', loadFromCache: boolean = false): Observable<Vps> {
    const data = {
      action: 'vs-list',
      cache: true,
      user,
      vphostname,
      vpsid
    };
    return this.http.post<HttpResponse<Vps | any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          const vsList: Vps[] = res.body ? Object.values(res.body) : null;
          let result = null;
          if (vsList.length > 0) {
            result = vsList[0];
          }
          // Take only one, first. Not sure if there are more available anyway
          return result;
        }),
        catchError(this.handleError('getVsList', null))
      );
  }

  /**
   * Sends start, stop, restart or poweroff signal to vps.
   * @param vpsid number
   * @param signal string: start, stop, restart, poweroff
   */
  public sendOperationSignalToVps(vpsid: number, signal: string): Observable<{ result: boolean, message: string }> {
    const data = {
      action: 'vs-' + signal,
      cache: false,
      invalidateKeys: ['vps', 'vs-list'],
      vpsid
    };
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          const message = res.body.done_msg !== undefined ? res.body.done_msg : '';
          if (res.body.done !== undefined) {
            return {
              result: res.body.done,
              message
            };
          }
          return {
            result: false,
            message
          };
        }),
        catchError(this.handleError('sendOperationSignalToVps', null))
      );
  }

  /**
   * Send password request change.
   * @param vpsid number
   * @param password string
   */
  public changeVncPassword(vpsid: number, password: string): Observable<{ result: boolean, message: string }> {
    const data = {
      action: 'vncpass',
      vid: vpsid,
      password
    };

    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          const message = res.body.done !== undefined && res.body.done !== null && res.body.done.msg !== undefined ? res.body.done.msg : 'No message';
          return {
            result: res.body.done !== undefined && res.body.done !== null && res.body.done,
            message
          };
        }),
        catchError(this.handleError('changeVncPassword', {result: false, message: 'Unknown error'}))
      );
  }

  public getNetworkServers(): Observable<ServerStatusServer[]> {
    const data = {
      action: 'GetServerStatus'
    };

    return this.http.post<HttpResponse<ServerStatusServer[] | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          return (typeof res.body.result !== 'undefined' && res.body.result === 'success' &&
            typeof res.body.servers !== 'undefined') ? res.body.servers : null;
        }),
        catchError(this.handleError('getNetworkServers', null))
      );
  }

  public getNetworkIssues(status: string = null): Observable<NewtworkIssue[]> {
    const data = {
      action: 'GetNetworkIssues',
      status
    };

    return this.http.post<HttpResponse<NewtworkIssue[] | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          return (typeof res.body.result !== 'undefined' && res.body.result === 'success' &&
            typeof res.body.issues !== 'undefined') ? res.body.issues : null;
        }),
        catchError(this.handleError('getNetworkIssues', null))
      );
  }

  public getNetworkServerStatus(url: string): Observable<any> {
    const data = {
      action: 'getNetworkServerStatus',
      url
    };
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/get-server-status`, data, httpOptions)
      .pipe(
        map((res) => {
          return res.body;
        }),
        catchError(this.handleError('getNetworkServerStatus', null))
      );
  }

  public getVpsIsoList(): Observable<VpsIso[]> {
    const data = {
      action: 'iso-list',
      cache: true
    };
    return this.http.post<HttpResponse<VpsIso[] | any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          return (typeof res.body.isos !== 'undefined') ? Object.values(res.body.isos) : [];
        }),
        catchError(this.handleError('getVpsIsoList', null))
      );
  }

  public getVpsTimezones(): Observable<VpsTimezone[]> {
    const data = {
      action: 'timezones',
      cache: true
    };
    return this.http.post<HttpResponse<VpsTimezone[] | any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          return res.body;
        }),
        catchError(this.handleError('getVpsIsoList', null))
      );
  }

  /**
   * Saves certain vps configuration parameters.
   */
  public saveVpsConfiguration(vps: Vps): Observable<{ result: boolean, message: string }> {
    const data = {
      action: 'hvmsettings',
      svs: vps.vpsid,
      vid: vps.vpsid,
      boot: vps.boot,
      hvmsettings: 1,
      nic_type: vps.nic_type,
      acceleration: (vps.acceleration) ? '1' : '0',
      hvm_vnc_keymap: vps.vnc_keymap,
      isos: vps.iso,
      sec_iso: vps.sec_iso,
      acpi: (vps.acpi) ? '1' : '0',
      apic: (vps.apic) ? '1' : '0',
      kvm_vga: (vps.kvm_vga) ? '1' : '0',
      enable_ppp_cp: vps.ppp,
      enable_tuntap_cp: vps.tuntap,
      vnc: (vps.vnc) ? '1' : '0',
      timezone: vps.timezone,
      cache: false,
      invalidateKeys: ['vs-list']
    };

    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          const message = res.body.done !== undefined && res.body.done !== null && res.body.done.msg !== undefined ? res.body.done.msg : 'No message';
          return {
            result: res.body.done !== undefined && res.body.done !== null && res.body.done,
            message
          };
        }),
        catchError(this.handleError('saveVpsConfiguration', {result: false, message: 'Unknown error'}))
      );
  }

  /**
   * Returns reformatted os list available for installation on vps
   */
  public getOsList(osType: string = null): Observable<Os[]> {
    const data = {
      action: 'ostemplates',
      cache: true,
      reformat: true,
      osType
    };
    return this.http.post<HttpResponse<Os[] | any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          return res.body;
        }),
        catchError(this.handleError('getOsList', []))
      );
  }

  /**
   * Sends reinstall os signal with required os id and password.
   * @param vpsId number
   * @param osId number
   * @param password string
   */
  public osReinstall(vpsId: number, osId: number, password: string): Observable<{ result: boolean, message: string }> {
    const data = {
      action: 'os-reinstall',
      svs: vpsId,
      reinsos: 1,
      newos: osId,
      newpass: password,
      conf: password,
      vid: vpsId,
      cache: false,
      invalidateKeys: ['vs-list', 'vps']
    };

    return this.http.post<HttpResponse<{ result: boolean, message: string } | any>>
    (`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          // TODO see with Mario that to do with response
          const result = {
            result: null,
            message: null
          };
          if (typeof res.body.error !== 'undefined' && res.body.error.length > 0) {
            res.body.error.forEach(val => {
              result.message += val;
            });
          } else {
            result.result = res.body;
          }
          return result;
        }),
        catchError(this.handleError('osReinstall', null))
      );
  }

  /**
   * Returns logs for VPS
   * @param vpsId number
   * @param page number
   * @param pageSize number
   * @param searchDate string
   * @param searchTask string
   */
  public getLogs(vpsId: number, page: number, pageSize: number = 10, searchDate: string = '', searchTask: string = ''):
    Observable<{ pager: Pager, logs: VpsLog[] }> {
    const data = {
      action: 'logs',
      vid: vpsId,
      cache: true
    };
    const pager = new Pager();
    return this.http.post<HttpResponse<VpsLog[] | any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          const logs = new Array<VpsLog>();
          let receivedLogs = res.body as Array<VpsLog>;
          // Filter by searchTerm
          if (searchTask !== '') {
            receivedLogs = receivedLogs.filter(i => i.action_text.toLowerCase().indexOf(searchTask) >= 0);
          }
          // Filter by searchDate
          if (searchDate !== '') {
            receivedLogs = receivedLogs.filter(i => i.time.indexOf(searchDate) >= 0);
          }
          pager.currentPage = page;
          pager.pageSize = pageSize;
          const totalItems = receivedLogs.length;
          pager.totalPages = Math.ceil(totalItems / pager.pageSize);
          pager.pages = [...Array(pager.totalPages).keys()].map(i => (i + 1));
          let counter = 0;

          for (const log of receivedLogs) {
            if (logs.length >= pageSize) {
              continue;
            }
            if (counter >= ((pageSize * page) - pageSize) && counter < (pageSize * page)) {
              logs.push(log);
            }
            counter++;
          }
          return {
            pager,
            logs
          };
        }),
        catchError(this.handleError('getLogs', {pager, logs: []}))
      );
  }

  /**
   * Enables vpsid rescue mode.
   * @param vpsId number - virtual server id
   * @param password string - password for rescue mode
   */
  public enableRescueMode(vpsId: number, password: string): Observable<{ result: boolean, message: string }> {
    const data = {
      action: 'rescue',
      vid: vpsId,
      password,
      cache: false,
      invalidateKeys: ['vps', 'vs-list']
    };
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          const message = res.body.done !== undefined && res.body.done !== null && res.body.done.msg !== undefined ? res.body.done.msg : 'No message';
          return {
            result: res.body.done !== undefined && res.body.done !== null && res.body.done,
            message
          };
        }),
        catchError(this.handleError('enableRescueMode', {result: false, message: 'Unknown error'}))
      );
  }

  /**
   * Disables rescue mode
   * @param vpsId number - virtual server id
   */
  public disableRescueMode(vpsId: number): Observable<{ result: boolean, message: string }> {
    const data = {
      action: 'disable-rescue',
      vid: vpsId,
      cache: false,
      invalidateKeys: ['vs-list', 'vps']
    };
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          const message = res.body.done !== undefined && res.body.done !== null && res.body.done.msg !== undefined ? res.body.done.msg : 'No message';
          return {
            result: res.body.done !== undefined && res.body.done !== null && res.body.done,
            message
          };
        }),
        catchError(this.handleError('disableRescueMode', {result: false, message: 'Unknown error'}))
      );
  }

  /**
   * Returns vps status
   * @param vpsId number
   * @param loadFromCache boolean
   */
  public getVpsStatus(vpsId: number, loadFromCache = false): Observable<VpsStatus> {
    const data = {
      action: 'vps-status',
      vpsid: vpsId,
      loadFromCache,
      saveCache: !loadFromCache
    };
    return this.http.post<HttpResponse<VpsStatus | any>>(`${this.config.apiEndpoint}/user/virtualizor-request`, data, httpOptions)
      .pipe(
        map((res) => {
          return res.body;
        }),
        catchError(this.handleError('getVpsStatus', null))
      );
  }

  /**
   * Returns clients server products (with pid of 3, and 21, 25, 26, 27, 28, 29, 30 & 50 for easy dcim)
   * @param filterData Can contain search parameters such as page
   * @param pageSize Determines the size of page size for display
   * @param loadFromCache loads data from API database, if record exists
   */
  public getClientsServerProducts(filterData: {}, pageSize: number | 10, loadFromCache: boolean = false):
    Observable<{ pager: Pager, servers: Product[] }> {
    const pager = new Pager();
    const keyPage = 'page';
    const keyOrderBy = 'orderby';
    const keyOrder = 'order';
    const keyServerId = 'serverid';
    const keyStatus = 'status';
    const keyName = 'name';
    const allowedOrdering = [
      'server',
      'status'
    ];
    pager.currentPage = filterData.hasOwnProperty(keyPage) ? parseInt(filterData[keyPage], 10) : 1;
    pager.pageSize = pageSize;
    const serverid = filterData.hasOwnProperty(keyServerId) ? filterData[keyServerId] : null;
    const startIndex = (pager.currentPage === 1) ? 0 : (pageSize * (pager.currentPage - 1));
    const orderby = filterData.hasOwnProperty(keyOrderBy) && allowedOrdering.includes(filterData[keyOrderBy]) ?
      filterData[keyOrderBy] : null;
    const order = filterData.hasOwnProperty(keyOrder) ? filterData[keyOrder] : 'asc';
    const serverStatus = filterData.hasOwnProperty(keyStatus) && filterData[keyStatus] !== 'all' ? filterData[keyStatus] : null;
    const serverName = filterData.hasOwnProperty(keyName) && filterData[keyName] !== '' ? filterData[keyName] : null;
    const data = {
      limitstart: startIndex,
      limitnum: pageSize,
      serviceid: null,
      cache: true,
      loadFromCache,
      saveCache: !loadFromCache
    };

    if (serverid) {
      data.serviceid = serverid;
    }

    return this.productService.getClientProducts(data, httpOptions)
      .pipe(
        map((servers) => {
          if (servers !== null) {
            // Sort
            if (orderby) {
              if (order !== 'asc') {
                servers.sort((a, b) => a[orderby] > b[orderby] ? -1 : a[orderby] < b[orderby] ? 1 : 0);
              } else {
                servers.sort((a, b) => a[orderby] < b[orderby] ? -1 : a[orderby] > b[orderby] ? 1 : 0);
              }
            }

            const products = new Array<Product>();
            let index = 0;
            let total = 0;

            for (const product of servers as Array<Product>) {
              if (['vps_standard'].indexOf(product.group_tag) !== -1 ||
                ['server_mdk_xl', 'server_mdk_l'].indexOf(product.group_tag) !== -1) {
                if ( (!serverName || (serverName && product.domain.includes(serverName))) &&
                  ((serverStatus && product.status.toLowerCase() === serverStatus)
                    || serverStatus === 'all' || serverStatus === null)) {
                  total++;
                  if ((products.length < pageSize) && (index >= startIndex)) {
                    products.push(product);
                  }
                }
              }
              index++;
            }
            pager.totalPages = Math.ceil(total / pageSize);
            pager.pages = [...Array(Math.ceil(total / pageSize)).keys()].map(i => (i + 1));
            return {
              pager,
              servers: products
            };
          }
          return {
            pager,
            servers: []
          };
        }),
        catchError(this.handleError('getClientsServerProducts', null))
      );
  }

  /**
   * Update server product
   * @param serviceId number
   * @param fields key->value
   */
  public updateServerProduct(serviceId: number, fields: {}): Observable<{ success: boolean, message: string }> {
    return this.productService.updateProduct(serviceId, fields)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }
}
