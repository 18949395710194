import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {APP_CONFIG, AppConfig} from '../app-config.module';
import {HandleError, HttpErrorHandler} from '../_services';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class IpService {
  private readonly handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.handleError = httpErrorHandler.createHandleError('IpService');
  }

  public unblockIp(ipaddress: string): Observable<any> {
    const data = {
      action: 'UnblockUser',
      servertype: 'cpanel',
      ipaddress
    };

    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          return res.body;
        }),
        catchError(this.handleError('unblockIp', null))
      );
  }
}
