export class CartOrderItem {
  cartId: number;
  pid: number;
  gid: number;
  name: string;
  quantity: number;
  unitPrice: number;
  unitPriceDiscount: number;
  totalPrice: number;
  totalPriceDiscount: number;
  description: string;
  parentItemId: number;
  onePerParent: boolean;
  contactId: number;
  pricingPeriodId: number;
  billingCycle: string;
  billingCycleTranslated: string;
  rootPassword: string;
  config: any[];
  customfields: any[];
  dateAdded: number;
  errors: string[];
  canEdit: boolean;
  domain: string;
  hostname: string;
  setupPriceSingle: number;
  setupPrice: number;
  tax: boolean;
}
