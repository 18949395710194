import {Component, Input} from '@angular/core';
import {Email} from '../email';

@Component({
    selector: 'app-email-details',
    templateUrl: './email-details.component.html'
})
export class EmailDetailsComponent {
  @Input() email: Email;
}
