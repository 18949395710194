<div class="bg-grey mb-l">
  <ul class="container wizard-progress flex flex-between flex-top">
    <li [ngClass]="{ 'step-active': step === 1, 'step-done': step > 1 }">
      <a [routerLink]="['/cart/product']" [queryParams]="{gid: groupId, pid: productId, cid: cartId}"  class="step flex flex-column"
         [ngClass]="{'btn-active': step === 1}" *ngIf="step === 2; else spanStep">
        <svg-icon src="assets/svg/cPanel-white.svg" class="icon icon-40 icon-cpanel step-num"></svg-icon>
        <span class="step-name">1.&nbsp;<span i18n>Postavke</span></span>
      </a>
      <ng-template #spanStep>
        <span class="step flex flex-column" [ngClass]="{'btn-active': step === 1}">
          <svg-icon src="assets/svg/cPanel-white.svg" class="icon icon-40 icon-cpanel step-num"></svg-icon>
          <span class="step-name">1.&nbsp;<span i18n>Postavke</span></span>
        </span>
      </ng-template>
    </li>
    <li [ngClass]="{ 'step-active': step == 2, 'step-done': step > 2 }">
      <span class="step flex flex-column" [ngClass]="{'btn-active': step === 2}">
        <svg-icon src="assets/svg/recommendation-gray.svg" class="icon icon-40 icon-recommendation step-num"></svg-icon>
        <span class="step-name">2.&nbsp;<span i18n>Dodatne opcije</span></span>
      </span>
    </li>
    <li [ngClass]="{ 'step-active': step === 3, 'step-done': step > 3 }">
      <span class="step flex flex-column" [ngClass]="{'btn-active': step === 3}">
        <svg-icon src="assets/svg/user-data-gray.svg" class="icon icon-40 icon-user-data step-num"></svg-icon>
        <span class="step-name">3.&nbsp;<span i18n>Korisnički podaci</span></span>
      </span>
    </li>
    <li [ngClass]="{ 'step-active': step === 4, 'step-done': step > 4 }">
      <span class="step flex flex-column" [ngClass]="{'btn-active': step === 4}">
        <svg-icon src="assets/svg/done-order-gray.svg" class="icon icon-40 icon-done-order step-num"></svg-icon>
        <span class="step-name">4.&nbsp;<span i18n>Pregled narudžbe</span></span>
      </span>
    </li>
    <li [ngClass]="{ 'step-active': step === 5, 'step-done': step > 5 }">
      <span class="step flex flex-column" [ngClass]="{'btn-active': step === 5}">
        <svg-icon src="assets/svg/payment-gray.svg" class="icon icon-40 icon-payment step-num"></svg-icon>
        <span class="step-name">5.&nbsp;<span i18n>Plaćanje</span></span>
      </span>
    </li>
  </ul>
</div>
