import {Directive, HostListener, ElementRef, HostBinding} from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: 'a[appExternalUrl]',
})
export class ExternalUrlDirective {
  constructor(private el: ElementRef, private router: Router) {}

  @HostListener('click', ['$event'])
  clicked(event: Event) {
    const url = this.el.nativeElement.href;
    const target = this.el.nativeElement.target;
    if (!url) {
      return;
    }

    this.router.navigate(['/externalRedirect', { externalUrl: url, target }], {
      skipLocationChange: true,
    });

    event.preventDefault();
  }
}
