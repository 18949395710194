import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {APP_CONFIG, AppConfig} from '../app-config.module';
import {AuthenticationService, HandleError, HttpErrorHandler} from '../_services';
import {Order} from './order';
import {Promotion} from './promotion';
import {CartOrderItem} from './cart-order-item';
import {ProductDomainItem} from './product-domain-item';
import {CartService} from './cart.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  public static orderKey = 'order';
  private orderSource = new BehaviorSubject<Order>(null);
  order$: Observable<Order>;
  private readonly handleError: HandleError;

  constructor(
    private http: HttpClient,
    private cartService: CartService,
    httpErrorHandler: HttpErrorHandler,
    private auth: AuthenticationService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.handleError = httpErrorHandler.createHandleError('OrderService');

    const order: Order = JSON.parse(this.auth.getStorage(OrderService.orderKey));
    this.orderSource = new BehaviorSubject<Order>(order);
    this.order$ = this.orderSource.asObservable();
  }

  public saveOrder(order: Order) {
    this.auth.addStorage(OrderService.orderKey, JSON.stringify(order));
    this.orderSource.next(order);
  }

  public getPaymentMethods() {
    const data = {
      action: 'GetPaymentMethods'
    };

    return this.http.post<HttpResponse<Promotion | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          return res.body;
        }),
        catchError(this.handleError('getPaymentMethods', null))
      );
  }

  public createOrder(order: Order, items: CartOrderItem[], domains: ProductDomainItem[]): Observable<Order> {
    const data = {
      ...order, ...{
        action: 'AddOrder',
        promocode: order.coupon,
        items,
        domains
      }
    };

    return this.http.post<HttpResponse<Order>>
    (`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          order.invoiceid = (res.body.invoiceid !== undefined) ? res.body.invoiceid : null;
          if (order.invoiceid) {
            this.cartService.clearAllProducts();
            this.auth.removeStorage(OrderService.orderKey);
            this.orderSource.next(null);
          }
          return order;
        }),
        catchError(this.handleError('createOrder', null))
      );
  }

  public getPromotion(code: string) {
    const data = {
      action: 'GetPromotions',
      code
    };

    return this.http.post<HttpResponse<Promotion | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          const tmpPromotion: any = res.body.promotions !== undefined ? res.body.promotions[0] : null;
          if (!tmpPromotion) {
            return null;
          }
          const promotion = new Promotion();
          const appliesTo = [];
          const appsTo = (tmpPromotion.appliesto !== '') ? tmpPromotion.appliesto.split(',') : [];
          if (appsTo.length > 0) {
            appsTo.forEach(x => {
              const valX = isNaN(x) ? x.replace('D.', '') : parseInt(x, 10);
              appliesTo.push(valX);
            })
          }
          promotion.appliesTo = appliesTo;
          promotion.applyOnce = tmpPromotion.applyonce === 1;
          promotion.code = tmpPromotion.code;
          promotion.cycles = tmpPromotion.cycles !== '' ? tmpPromotion.cycles.toUpperCase().split(',') : [];
          promotion.existingClient = tmpPromotion.existingclient === 1;
          promotion.id = isNaN(tmpPromotion.id) ? null : parseInt(tmpPromotion.id, 10);
          promotion.lifetimePromo = tmpPromotion.lifetimePromo === 1;
          promotion.maxUses = parseInt(tmpPromotion.maxuses, 10);
          promotion.newSignups = parseInt(tmpPromotion.newsignups, 10);
          promotion.notes = tmpPromotion.notes;
          promotion.oncePerClient = tmpPromotion.onceperclient === 1;
          promotion.expirationDate = (tmpPromotion.expirationdate !== '0000-00-00' && tmpPromotion.expirationdate !== null) ?
            new Date(tmpPromotion.expirationdate) : null;
          promotion.recurFor = tmpPromotion.recurFor;
          promotion.recurring = tmpPromotion.recurring === 1;
          const requires = [];
          const reqs = (tmpPromotion.requires !== '') ? tmpPromotion.requires.split(',') : [];
          if (reqs.length > 0) {
            reqs.forEach(x => {
              const valX = isNaN(x) ? x.replace('D.', '') : parseInt(x, 10);
              requires.push(valX);
            })
          }
          promotion.requires = requires;
          promotion.requiresExisting = tmpPromotion.requiresexisting;
          promotion.startDate = tmpPromotion.startdate !== '0000-00-00' ? tmpPromotion.startdate : null;
          promotion.type = tmpPromotion.type;
          promotion.upgradeConfig = tmpPromotion.upgradeconfig;
          promotion.upgrades = parseInt(tmpPromotion.upgrades, 10);
          promotion.value = parseFloat(tmpPromotion.value);
          return promotion;
        }),
        catchError(this.handleError('getPromotions', null))
      );
  }
}
