import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Subscription} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';

import {AnnouncementsService} from '../_services/announcements.service';
import {Announcement} from '../_models/announcement';
import {Pager} from '../support/tickets/pager';

@Component({
  selector: 'app-announcements',
  templateUrl: 'announcements.component.html'
})
export class AnnouncementsComponent implements OnInit, OnDestroy  {
  @Input() pageSize = 10;
  @Input() template = 'list';
  @Input() announcements: Announcement[];
  @Input() loading = false;
  private subscription: Subscription = new Subscription();
  pager: Pager;
  currentAnnouncement: Announcement;
  title;

  constructor(
    public announcementService: AnnouncementsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.template === 'list') {
      this.loadAnnouncements();
    }
  }

  loadAnnouncements() {
    this.loading = true;

    const announcementSub = this.route.queryParams.pipe(
      switchMap(params => this.announcementService.getAnnouncements(params, this.pageSize)),
    )
      .subscribe((res) => {
        this.loading = false;
        this.announcements = res.announcements;
        if (this.template === 'div') {
          this.announcements.forEach(ann => ann.expanded = false);
        }
        this.pager = res.pager;
      });
    this.subscription.add(announcementSub);
  }

  showAnnouncement(announcement: Announcement) {
    this.currentAnnouncement = announcement;
    this.title = announcement.title;
  }

  clearCurrentAnnouncement() {
    this.currentAnnouncement = null;
    this.title = null;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
