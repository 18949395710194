<div class="bg-white bd-bottom p-3">
  <h3 class="color-dark-blue" i18n>Novi kontakt</h3>
</div>

<div class="p-3">
  <form [formGroup]="addContactForm" (ngSubmit)="onSubmit()">
    <app-contact-details [source]="source" [submitted]="submitted" (newPass)="generateRootPassword()" (passRequired)="togglePassRequired($event)" [user]="newContact" [edit]="true" [parentForm]="addContactForm"></app-contact-details>

    <hr>
    <div class="d-flex justify-content-between">
      <a *ngIf="source !== 'cart'" routerLink="../../contacts" class="btn btn-outline-dark" i18n>Odustani</a>
      <button class="btn btn-green rounded-light" i18n>Kreiraj</button>
    </div>
  </form>
</div>
