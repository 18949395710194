import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {CountryISO} from 'ngx-intl-tel-input';

import {AuthenticationService} from '../../_services';
import {Client} from '../../_models';
@Component({
  selector: 'app-profile-details',
  templateUrl: 'profile-details.component.html'
})
export class ProfileDetailsComponent implements OnInit {
  @Input() user: Client;
  @Input() title: string;
  @Input() submitted: boolean;
  @Input() parentForm: FormGroup;
  @Input() edit = false;
  @Input() register = false;

  // TooltipLabel = TooltipLabel;
  preferredCountries: CountryISO[] = [CountryISO.Croatia, CountryISO.Germany];
  currentLanguage = null;
  currentCountry = null;
  currentCountryPhone = null;
  countries = [];
  personType = 'private';
  languages = [
    {id: 1, name: 'Hrvatski', code: 'croatian'},
    {id: 2, name: 'English', code: 'english'}
  ];

  constructor(
    protected authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.user = this.authenticationService.currentUserValue;
    this.parentForm = (this.parentForm === undefined) ? this.formBuilder.group({
      phonenumber: [null, Validators.required],
      telephoneNumber: [{value: null, disabled: !this.edit}, Validators.required],
      marketingoptin: null
    }) : this.parentForm;
    this.personType = this.user !== undefined && this.user !== null ? this.user.persontype : this.personType;
  }

  ngOnInit() {
    let phoneNumber = null;
    let defaultLanguage = 'croatian';
    let currentCountry = 'HR';
    let currentCountryPhone = 'HR';

    if (this.user) {
      if (this.user.subaccount) {
        return this.router.navigate(['/profile/contacts/' + this.user.id]);
      }
      const userLanguage = (typeof this.user.language === 'undefined') ? defaultLanguage : this.user.language;
      const userLang = this.languages.find(lang => lang.code === userLanguage);
      defaultLanguage = (userLang) ? userLang.code : defaultLanguage;
      currentCountry = (this.user.country !== undefined) ? this.user.country : currentCountry;
      currentCountryPhone = (this.user.countrycode !== undefined) ? this.user.countrycode : currentCountryPhone;
      phoneNumber = (this.user.phonenumber !== '') ? this.user.phonenumberformatted.split('.') : '';
      this.parentForm.patchValue({phonenumber: phoneNumber[1]});
      this.parentForm.patchValue({telephoneNumber: phoneNumber[1]});
      this.parentForm.patchValue({marketingoptin: this.user.marketingoptin});
    }
    this.currentLanguage = this.languages.find(language => language.code === defaultLanguage);

    for (const [key, value] of Object.entries(CountryISO)) {
      this.countries.push({
        code: value.toUpperCase(),
        name: key
      });
    }

    this.currentCountry = this.countries.find(country => country.code === currentCountry);
    this.currentCountryPhone = this.countries.find(country => country.code === currentCountryPhone);
    this.parentForm.patchValue({country: currentCountry});
    this.parentForm.patchValue({language: defaultLanguage});
  }

  get f() {
    return this.parentForm.controls;
  }

  setClientPersonType(type: string) {
    if (this.user) {
      if (type === 'private') {
        this.user.companyname = null;
        this.parentForm.patchValue({companyname: null});
      }
      this.user.persontype = type;
      this.personType = type;
    } else {
      this.parentForm.patchValue({personType: type});
      this.personType = type;
    }
  }

  telInvalidPhone() {
    const defaultClass = (this.edit) ? 'form-control' : 'form-control-plaintext';
    if (!this.submitted) {
      return defaultClass;
    } else {
      return (this.f.telephoneNumber.errors === null) ? defaultClass : `${defaultClass} is-invalid`;
    }
  }
}
