<ng-container>
  <div *ngFor="let tag of linkedTags" class="panel card rounded-light product-list mb-3">
    <a routerLink="../../product" [queryParams]="{gid: getGroupId(tag), returnOption: cid}" class="panel-title"><span class="btn d-block text-left bg-grey">{{getGroupName(tag)}}</span></a>
  </div>

  <app-loader *ngIf="loading.groups"></app-loader>

 <div class="container-fluid">
   <div class="row">
     <a routerLink="/cart/user" class="btn btn-green rounded-light col-lg-3 offset-lg-9">
       <span *ngIf="hasAdditional" i18n>Nastavi</span>
       <span *ngIf="!hasAdditional" i18n>Nastavi bez dodataka</span>
     </a>
   </div>
 </div>
</ng-container>
