export class ProductGroup {
  id: number;
  name: string;
  tag: string;
  intro: string;
  hidden: boolean;
  order: number;
  created: string;
  updated: string;
  translations: any;
  linkedTags: string[];
}
