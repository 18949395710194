<div class="container-fluid">
  <div class="row pt-1 pt-md-5 bg-background">
    <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 box p-2 p-md-3 p-lg-4">
      <h5 class="font-weight-bold mb-3 bd-bottom pb-3" i18n>Resetiraj lozinku</h5>
      <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
        <div class="form-group mb-3">
          <label for="email" i18n>E-mail</label>
          <input id="email" type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback w-100">
            <div *ngIf="f.email.errors.required" i18n>E-mail je obavezan</div>
            <div *ngIf="f.email.errors.email" i18n>E-mail je neispravnog formata</div>
          </div>
        </div>

        <input type="hidden" formControlName="recaptcha" class="form-control" />

        <div *ngIf="submitted && f.recaptcha.errors" class="invalid-feedback w-100">
          <div *ngIf="f.recaptcha.errors.required" i18n>Recaptcha invalid</div>
        </div>

        <p class="text-center mb-5"><button [disabled]="loading" type="submit" class="btn btn-blue rounded-light" i18n>Resetiraj lozinku</button></p>

        <div class="form-group m-0">
          <app-loader *ngIf="loading"></app-loader>
          <div class="d-flex justify-content-center">
            <a routerLink="/register" class="btn btn-link mr-3" i18n>Registriraj se</a>
            <a routerLink="/login" class="btn btn-link" i18n>Prijavi se</a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
