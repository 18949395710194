export class Promotion {
  id: number;
  code: string;
  type: string;
  recurring: boolean;
  value: number;
  cycles: any[];
  appliesTo: any[];
  requires: any[];
  requiresExisting: boolean;
  startDate: string;
  expirationDate: Date;
  maxUses: number;
  uses: number;
  lifetimePromo: boolean;
  applyOnce: boolean;
  newSignups: number;
  existingClient: boolean;
  oncePerClient: boolean;
  recurFor: boolean;
  upgrades: number;
  upgradeConfig: any;
  notes: string;
}
