<div [formGroup]="parentForm" *ngIf="!loading.departments">
    <div class="form-group">
        <div class="row">
            <div class="col-12 col-md-6 mb-3 mb-md-0">
                <label for="name" i18n>Ime</label>
                <input id="name" type="text" formControlName="name" class="form-control"/>
            </div>
            <div class="col-12 col-md-6">
                <label for="email" i18n>Email</label>
                <input id="email" type="text" formControlName="email" class="form-control"/>
            </div>
        </div>
    </div>

    <div class="form-group" *ngIf="isNewTicket">
        <label for="subject" i18n>Naslov</label>
        <input id="subject" type="text" formControlName="subject" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }"/>
        <div *ngIf="submitted && f.subject.errors" class="invalid-feedback w-100">
            <div *ngIf="f.subject.errors.required" i18n>Naslov je obavezan</div>
            <div *ngIf="f.subject.errors.minlength" i18n>Naslov minimalno 5 znakova</div>
        </div>
    </div>

    <div class="form-group" *ngIf="isNewTicket">
        <div class="row">
            <div class="col">
                <label for="departments" i18n>Odjel</label>
                <div *ngIf="departments">
                    <ng-select [items]="departments"
                               formControlName="deptid"
                               bindValue="id"
                               bindLabel="name"
                               id="departments"
                               placeholder="Odaberi odjel"
                               i18n-placeholder="Odaberi odjel">
                      <ng-template ng-label-tmp let-item="item">
                        {{getName(item)}}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                        {{getName(item)}}
                      </ng-template>
                    </ng-select>
                </div>
                <div *ngIf="submitted && f.deptid.errors" class="invalid-feedback w-100">
                    <div *ngIf="f.deptid.errors.required" i18n>Odjel je obavezan</div>
                </div>
            </div>

            <div class="col">
                <label i18n>Vezana usluga</label>
                <div *ngIf="services">
                    <ng-select [items]="services"
                               formControlName="serviceid"
                               bindValue="id"
                               bindLabel="name"
                               placeholder="Odaberi uslugu">
                    </ng-select>
                </div>
              <app-loader *ngIf="loading.services"></app-loader>
            </div>

            <div class="col">
                <label i18n>Hitnost</label>
                <div *ngIf="priorities">
                    <ng-select [items]="priorities"
                               formControlName="priority"
                               bindValue="value"
                               bindLabel="name"
                               placeholder="Odaberi hitnost">
                    </ng-select>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group">
        <div class="row">
            <div class="col">
                <label for="message" i18n>Poruka</label>
                <textarea id="message" aria-multiline="true" formControlName="message" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.message.errors }" rows="6">
                </textarea>
                <div *ngIf="submitted && f.message.errors" class="invalid-feedback w-100">
                    <div *ngIf="f.message.errors.required" i18n>Tekst je obavezan</div>
                    <div *ngIf="f.message.errors.minlength" i18n>Tekst od minimalno 10 znakova</div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group relative file-upload">
          <div ng2FileDrop
               [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
               (fileOver)="fileOverBase($event)"
               [uploader]="uploader"
               class="drop-zone bg-white p-3" i18n>
              Povuci datoteku za slanje
          </div>

          <input formControlName="document" type="file" class="upload-icon absolute" ng2FileSelect [uploader]="uploader" multiple/>
    </div>

  <div class="p-3" *ngIf="uploader?.queue.length">
    <div class="row bg-color2 p-2 d-none d-lg-flex">
      <div class="col-9" i18n>Ime datoteke</div>
      <div class="col-3" i18n>Akcija</div>
    </div>

    <div class="row bd-bottom p-2" *ngFor="let item of uploader.queue">
      <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Ime datoteke</div>
      <div class="col-8 mb-2 col-lg-9 mb-lg-0">
        {{ item._file.name }} ({{ (item._file.size / 1024 / 1024) | number: '1.2-2' }} MB)
      </div>

      <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Akcija</div>
      <div class="col-8 mb-2 col-lg-3 mb-lg-0">
        <button (click)="removeFile(item)" type="button" i18n class="btn rounded-light border-0 btn-danger btn-sm">Ukloni</button>
      </div>
    </div>
  </div>
</div>

<app-loader *ngIf="loading.departments"></app-loader>

<div *ngIf="!departments?.length && !loading.departments">
  <h2 i18n>Nema definiranih odjeljenja. Molimo, pokušaj ponovo malo kasnije.</h2>
</div>
