<div class="d-flex justify-content-between bd-bottom p-3">
  <h3 *ngIf="invoice" i18n>Uređivanje podataka o platitelju</h3>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <ng-container>
    <form [formGroup]="ownerDataForm" (ngSubmit)="onSubmit()" *ngIf="invoice">
      <div class="form-group pt-2 pb-3" *ngIf="contacts">
        <label for="choose_{{label}}" i18n>Postojeći ili novi kontakt</label>
        <ng-select [items]="contacts"
                   (change)="setContact($event)"
                   formControlName="contactid"
                   bindValue="id"
                   bindLabel="email"
                   [searchFn]="customSearchFn"
                   i18n-placeholder="Odaberi postojeći kontakt"
                   placeholder="Odaberi postojeći kontakt">
          <ng-template ng-label-tmp let-item="item">
            <span *ngIf="item.firstname">{{item.firstname + item.lastname }}&nbsp;-&nbsp;</span><span
            *ngIf="item.id === null" i18n>Novi kontakt</span><span *ngIf="item.id !== null"><span
            *ngIf="item.id !== null">(</span>{{item.email}}<span *ngIf="item.id !== null">)</span></span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <span *ngIf="item.firstname">{{item.firstname + item.lastname }}&nbsp;-&nbsp;</span><span
            *ngIf="item.id === null" i18n>Novi kontakt</span><span *ngIf="item.id !== null"><span
            *ngIf="item.id !== null">(</span>{{item.email}}<span *ngIf="item.id !== null">)</span></span>
          </ng-template>
        </ng-select>
      </div>

      <div class="bg-background p-2">
        <div class="form-group row">
          <div class="col-12 col-md-6 mb-2">
            <label for="firstname" i18n>Ime</label>
            <input [attr.disabled]="disabled ? '' : null" id="firstname" type="text" formControlName="firstname"
                   class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"/>
            <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback w-100">
              <div *ngIf="f.firstname.errors.required" i18n>Ime je obavezno</div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <label for="lastname" i18n>Prezime</label>
            <input [attr.disabled]="disabled ? '' : null" id="lastname" type="text" formControlName="lastname"
                   class="form-control"
                   [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"/>
            <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback w-100">
              <div *ngIf="f.lastname.errors.required" i18n>Prezime je obavezno</div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-12 col-md-6 mb-2">
            <label for="companyname" i18n>Naziv organizacije</label>
            <input [attr.disabled]="disabled ? '' : null" id="companyname" type="text" formControlName="companyname"
                   class="form-control" [ngClass]="{ 'is-invalid': submitted && f.companyname.errors }"/>
          </div>

          <div class="col-12 col-md-6">
            <label for="taxid" i18n>OIB</label>
            <input [attr.disabled]="disabled ? '' : null" id="taxid" type="text" formControlName="taxid"
                   class="form-control" [ngClass]="{ 'is-invalid': submitted && f.taxid.errors }"/>
            <div *ngIf="submitted && f.taxid.errors" class="invalid-feedback w-100">
              <div *ngIf="f.taxid.errors.dependent" i18n>OIB je obavezan ako je unešen Naziv organizacije</div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <label for="address1" i18n>Adresa</label>
            <input [attr.disabled]="disabled ? '' : null" id="address1" type="text" formControlName="address1"
                   class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address1.errors }"/>
            <div *ngIf="submitted && f.address1.errors" class="invalid-feedback w-100">
              <div *ngIf="f.address1.errors.required" i18n>Adresa je obavezno polje</div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <label for="address2" i18n>Adresa 2</label>
            <input [attr.disabled]="disabled ? '' : null" id="address2" type="text" formControlName="address2"
                   class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address2.errors }"/>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <label for="city" i18n>Grad</label>
            <input [attr.disabled]="disabled ? '' : null" id="city" type="text" formControlName="city"
                   class="form-control" [ngClass]="{ 'is-invalid': submitted && f.city.errors }"/>
            <div *ngIf="submitted && f.city.errors" class="invalid-feedback w-100">
              <div *ngIf="f.city.errors.required" i18n>Grad je obavezno polje</div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <label for="state" i18n>Županija</label>
            <input
              [attr.disabled]="disabled ? '' : null"
              id="state"
              type="text"
              formControlName="state"
              class="form-control"
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <label for="country" class="col-form-label" i18n>Država</label>
            <input *ngIf="disabled" type="text" class="form-control" [disabled]="true" [value]="currentCountry?.name"/>
            <div *ngIf="currentCountry && !disabled">
              <ng-select [items]="countries"
                         [attr.disabled]="disabled ? '' : null"
                         [disabled]="true"
                         [ngClass]="{ 'is-invalid': submitted && f.country.errors }"
                         formControlName="country"
                         bindValue="code"
                         bindLabel="name"
                         id="country"
                         i18n-placeholder="Odaberi zemlju"
                         placeholder="Odaberi zemlju">
              </ng-select>
              <div *ngIf="submitted && f.country.errors" class="invalid-feedback w-100">
                <div *ngIf="f.country.errors.required" i18n>Država je obavezno polje</div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <label for="telephoneNumber" class="col-form-label" i18n>Telefonski broj</label>
            <div>
              <ngx-intl-tel-input *ngIf="currentCountry"
                                  [cssClass]="telInvalidPhone()"
                                  [preferredCountries]="preferredCountries"
                                  [enableAutoCountrySelect]="true"
                                  [enablePlaceholder]="true"
                                  [searchCountryFlag]="true"
                                  [selectFirstCountry]="false"
                                  [selectedCountryISO]="currentCountryPhone.code"
                                  [maxLength]="'15'"
                                  [phoneValidation]="true"
                                  id="telephoneNumber"
                                  formControlName="telephoneNumber"></ngx-intl-tel-input>
            </div>
            <div *ngIf="submitted && f.telephoneNumber.errors" class="invalid-feedback d-block w-100">
              <div i18n>Broj telefona je neispravan</div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <label for="postcode" i18n>Poštanski broj</label>
            <input [attr.disabled]="disabled ? '' : null" id="postcode" type="text" formControlName="postcode"
                   class="form-control" [ngClass]="{ 'is-invalid': submitted && f.postcode.errors }"/>
            <div *ngIf="submitted && f.postcode.errors" class="invalid-feedback w-100">
              <div *ngIf="f.postcode.errors.required" i18n>Poštanski broj je obavezno polje</div>
              <div *ngIf="f.postcode.errors.minlength" i18n>Minimalno 2 broja</div>
              <div *ngIf="f.postcode.errors.invalidNumber" i18n>Samo brojevi</div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <label for="email" i18n>Email</label>
            <input [attr.disabled]="disabled ? '' : null" id="email" type="email" formControlName="email"
                   class="form-control"
                   [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback w-100">
              <div *ngIf="f.email.errors.email" i18n>Email je neispravan</div>
              <div *ngIf="f.email.errors.required" i18n>Email je obavezno polje</div>
            </div>
          </div>
        </div>
      </div>

      <hr>
      <div class="d-flex justify-content-between">
        <button class="btn btn-primary rounded-light" type="button" (click)="modalRef.hide()" *ngIf="!loading.form" i18n>Zatvori</button>
        <button class="btn rounded-light btn-green" *ngIf="!loading.form" i18n>Promijeni</button>
        <app-loader *ngIf="loading.form"></app-loader>
      </div>
    </form>
  </ng-container>
</div>
