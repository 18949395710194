import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';

import {CartOrderItem} from './cart-order-item';
import {AuthenticationService} from '../_services';
import {Product} from '../_models/product';
import {ProductGroup} from '../_models/product-group';
import {Currency} from '../_models/currency';
import {ProductDomainItem} from './product-domain-item';
import {Promotion} from './promotion';
import {Client} from '../_models';
import {ProductService} from '../_services/product.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public static promotionKey = 'promo';
  public static affKey = 'AFF';
  public static orderItemsKey = 'cartItems';
  public static domainItemsKey = 'cartDomains';

  private promotion: Promotion;
  private promotionSource = new BehaviorSubject<Promotion>(null);
  promotion$: Observable<Promotion>;

  private orderItemSource: BehaviorSubject<CartOrderItem[]> = new BehaviorSubject<CartOrderItem[]>([]);
  orderItems$: Observable<CartOrderItem[]>;

  private domainItemSource: BehaviorSubject<ProductDomainItem[]> = new BehaviorSubject<ProductDomainItem[]>([]);
  domainItems$: Observable<ProductDomainItem[]>;

  userId: number;

  constructor(
    private auth: AuthenticationService,
    private productService: ProductService
  ) {
    this.orderItems$ = this.orderItemSource.asObservable();
    this.domainItems$ = this.domainItemSource.asObservable();

    if (!this.transferStorageToUser(this.auth.currentUserValue)) {
      this.loadItems();
    }
  }

  public loadItems() {
    let items: CartOrderItem[] = JSON.parse(this.auth.getStorage(CartService.orderItemsKey));
    if (items !== null && items.length > 0) {
      items = this.clearOldItems(items);
    }
    this.orderItemSource.next(items);

    let domains: ProductDomainItem[] = JSON.parse(this.auth.getStorage(CartService.domainItemsKey));
    if (domains !== null && domains.length > 0) {
      domains = this.clearUnconnectedDomains(items, domains);
    }
    this.domainItemSource.next(domains);
    this.loadPromotion();
  }

  transferStorageToUser(user: Client) {
    if (user) {
      const affUnknown = JSON.parse(this.auth.getStorage(CartService.affKey));
      this.auth.removeStorage(CartService.affKey);
      CartService.affKey = `${CartService.affKey.split('_')[0]}_${user.id}`;
      const affUser = JSON.parse(this.auth.getStorage(CartService.affKey));
      const aff = (affUser) ? affUser : affUnknown;
      if (aff) {
        this.auth.addStorage(CartService.affKey, JSON.stringify(aff));
      }

      let ordersUnknown = JSON.parse(this.auth.getStorage(CartService.orderItemsKey));
      ordersUnknown = (ordersUnknown !== null) ? ordersUnknown : [];
      this.auth.removeStorage(CartService.orderItemsKey);
      CartService.orderItemsKey = `${CartService.orderItemsKey.split('_')[0]}_${user.id}`;
      let ordersUser = JSON.parse(this.auth.getStorage(CartService.orderItemsKey));
      ordersUser = (ordersUser !== null) ? ordersUser : [];
      const orderItems = [...ordersUser, ...ordersUnknown];
      if (orderItems !== null && orderItems.length > 0) {
        this.auth.addStorage(CartService.orderItemsKey, JSON.stringify(orderItems));
      }

      let domainsUnknown = JSON.parse(this.auth.getStorage(CartService.domainItemsKey));
      domainsUnknown = (domainsUnknown !== null) ? domainsUnknown : [];
      this.auth.removeStorage(CartService.domainItemsKey);
      CartService.domainItemsKey = `${CartService.domainItemsKey.split('_')[0]}_${user.id}`;
      let domainsUser = JSON.parse(this.auth.getStorage(CartService.domainItemsKey));
      domainsUser = (domainsUser !== null) ? domainsUser : [];
      const domainItems = [...domainsUser, ...domainsUnknown];
      if (domainItems !== null && domainItems.length > 0) {
        this.auth.addStorage(CartService.domainItemsKey, JSON.stringify(domainItems));
      }
      this.loadItems();
      return true;
    }
    return false;
  }

  loadPromotion() {
    const promotion: Promotion = JSON.parse(this.auth.getStorage(CartService.promotionKey, 'session'));
    this.promotionSource = new BehaviorSubject<Promotion>(promotion);
    this.promotion$ = this.promotionSource.asObservable();
    if (!promotion) {
      this.promotionSource.next(undefined);
      return;
    }
    const now = new Date();
    const expired = (promotion.expirationDate !== null && promotion.expirationDate < now);
    if (expired) {
      this.auth.removeStorage('promo', 'session');
      this.promotionSource.next(undefined);
      return;
    }

    return this.calculatePromotion();
  }

  getPromotionCode() {
    if (this.promotion) {
      return this.promotion.code;
    }
    return null;
  }

  applyPromotion(promotion: Promotion) {
    this.auth.addStorage('promo', JSON.stringify(promotion), 'session');
    return this.loadPromotion();
  }

  getCartCurrency(): Observable<Currency> | null {
    return this.auth.currency;
  }

  getAff(): number | null {
    const aff = JSON.parse(this.auth.getStorage(CartService.affKey));
    return aff !== undefined && aff !== null ? parseInt(aff.id, 10) : null;
  }

  getCartItemByCartId(cartId: number): CartOrderItem {
    const items = (this.orderItemSource.value === null) ? [] : this.orderItemSource.value;
    return items.find(x => x.cartId === cartId);
  }

  // todo calculate prices depending on promotion
  calculatePromotion(skipListUpdate: boolean = false) {
    const promotion = this.promotionSource.value;
    if (!promotion) {
      return;
    }
    let promoApplicable = false;
    const requiredProductIds: any[] = [];
    const orderItems = this.orderItemSource.value;

    let requiresValid = true;
    if (promotion.requires !== null && promotion.requires.length > 0) {
      promotion.requires.forEach(requiredProductId => {
        if (!this.productIdInCart(requiredProductId)) {
          requiredProductIds.push(requiredProductId);
          requiresValid = false;
        }
      });
    }
    if (!requiresValid) {
      promoApplicable = false;
    }

    if (promotion.appliesTo.length > 0 && orderItems && orderItems.length > 0) {
      orderItems.forEach(x => {
        if (requiresValid && promotion.appliesTo.indexOf(x.pid) >= 0
          && (promotion.cycles.length === 0 || promotion.cycles.indexOf(x.billingCycle.toString().toUpperCase()) >= 0)) {
          promoApplicable = true;
          if (promotion.type === 'Percentage') {
            x.unitPriceDiscount = x.unitPrice - (x.unitPrice * (promotion.value / 100));
          } else if (promotion.type === 'Fixed Amount') {
            x.unitPriceDiscount = x.unitPrice - promotion.value;
          }
          x.totalPriceDiscount = x.unitPriceDiscount * x.quantity;
        } else if (!requiresValid) {
          delete x.unitPriceDiscount;
          delete x.totalPriceDiscount;
        }
      });
    }

    const domainItems = this.domainItemSource.value;
    if (promotion.appliesTo.length > 0 && domainItems && domainItems.length > 0) {
      domainItems.forEach(x => {
        if (requiresValid && promotion.appliesTo.indexOf(x.extension) >= 0) {
          promoApplicable = true;
          if (promotion.type === 'Percentage') {
            x.priceDiscount = x.price - (x.price * (promotion.value / 100));
          } else if (promotion.type === 'Fixed Amount') {
            x.priceDiscount = x.price - promotion.value;
          }
        } else if (!requiresValid) {
          delete x.priceDiscount;
        }
      });
    }

    if (!skipListUpdate) {
      this.orderItemSource.next(orderItems);
      this.domainItemSource.next(domainItems);
    }

    return {
      applicable: promoApplicable,
      requiredProductIds,
      orderItems,
      domainItems
    };
  }

  clearUnconnectedDomains(items: CartOrderItem[], tmpDomains: ProductDomainItem[], updateSubscription: boolean = false) {
    const domains: ProductDomainItem[] = [];
    tmpDomains.forEach(domain => {
      delete domain.priceDiscount;
      if (domain.name !== undefined) {
        if (domain.cartProductId === undefined) {
          domains.push(domain);
        } else if (items !== null) {
          const cartItem = items.find(item => item.cartId === domain.cartProductId);
          if (cartItem === undefined || cartItem.domain !== domain.name) {
            delete domain.cartProductId;
          }
          domains.push(domain);
        }
      }
    });

    this.auth.addStorage(CartService.domainItemsKey, JSON.stringify(domains));
    if (updateSubscription) {
      this.domainItemSource.next(domains);
    }
    return domains;
  }

  clearOldItems(items: CartOrderItem[], updateSubscription: boolean = false) {
    const timeNow = Date.now();
    items.forEach(x => {
      delete x.unitPriceDiscount;
      delete x.totalPriceDiscount;
      // if more than half an hour clear password
      if (x.rootPassword !== null && timeNow > (x.dateAdded + 1800 * 1000)) {
        x.rootPassword = '';
        x.errors = [];
        x.errors.push('rootPassword');
      }
    });
    this.auth.addStorage(CartService.orderItemsKey, JSON.stringify(items));
    if (updateSubscription) {
      this.orderItemSource.next(items);
    }

    return items;
  }

  clearAllProducts() {
    // this.auth.removeStorage(CartService.affKey);
    this.auth.removeStorage(CartService.promotionKey, 'session');
    this.auth.removeStorage(CartService.orderItemsKey);
    this.auth.removeStorage(CartService.domainItemsKey);
    this.orderItemSource.next([]);
    this.domainItemSource.next([]);
  }

  /**
   * Generate CartOrderItem object from Product object.
   */
  generateCartItem(product: Product, productGroup: ProductGroup, name: string = null, description: string = null, formValue: any = null,
                   parentItemId: number = null, onePerParent: boolean = false, ): CartOrderItem {
    const item = new CartOrderItem();
    item.cartId = (typeof formValue.cartId !== 'undefined') ? formValue.cartId : null;
    item.pid = product.pid;
    item.gid = productGroup.id;
    item.name = name;
    item.canEdit = true;
    item.quantity = formValue.quantity;
    const unitPrice = (typeof formValue.setupPrice !== 'undefined') ?
      (formValue.totalPrice + formValue.setupPrice).toFixed(2) : formValue.totalPrice;
    item.unitPrice = parseFloat(unitPrice);
    item.description = description;
    item.rootPassword = (typeof formValue.rootPassword !== 'undefined') ? formValue.rootPassword : null;
    item.hostname = formValue.hostname !== undefined ? formValue.hostname : null;
    item.config = (typeof formValue.config !== 'undefined') ? formValue.config : null;
    item.customfields = (typeof formValue.customfields !== 'undefined') ? formValue.customfields : null;
    item.pricingPeriodId = formValue.pricingPeriod;
    if (formValue.setupPrice !== undefined) {
      item.setupPrice = formValue.setupPrice * formValue.quantity;
      item.setupPriceSingle = formValue.setupPrice
    }
    const prodPrice = product.prices.find(p => p.id === formValue.pricingPeriod);
    if (prodPrice) {
      item.billingCycle = prodPrice.name;
      item.billingCycleTranslated = prodPrice.nameTranslated;
    }

    item.onePerParent = onePerParent;
    item.parentItemId = parentItemId;
    item.tax = product.tax === 1;

    return item;
  }

  getTotalCart(getDiscount: boolean = false): number {
    let total = 0;
    let totalDiscount = 0;
    if (this.orderItemSource.value !== null && this.orderItemSource.value.length > 0) {
      for (const orderItem of this.orderItemSource.value) {
        total += (orderItem.totalPriceDiscount !== undefined) ?
          parseFloat(String(orderItem.totalPriceDiscount)) : parseFloat(String(orderItem.totalPrice));
        totalDiscount +=  parseFloat(String(orderItem.totalPrice));
      }
    }
    if (this.domainItemSource.value !== null && this.domainItemSource.value.length > 0) {
      for (const domainItem of this.domainItemSource.value) {
        total += (domainItem.priceDiscount !== undefined) ?
          parseFloat(String(domainItem.priceDiscount)) :parseFloat(String(domainItem.price));
        totalDiscount += parseFloat(String(domainItem.price));
      }
    }

    return (getDiscount) ? totalDiscount : total;
  }

  /**
   * Returns total tax (1) for user and his items
   * @param client Client
   */
   getTotalCartTax(client?: Client): number {
    let totalTax = 0;

    // If the tax1_value is undefined, calculation is done with default Croatian
    // tax rate of 25%, otherwise, the tax1_value is used for calculation.
    const taxValue = (client?.tax1_value !== undefined) ? client.tax1_value : 25;
    const taxExempt = (client?.taxexempt !== undefined) ? client.taxexempt : false;

    if (!taxExempt) {
      if (this.orderItemSource.value !== null && this.orderItemSource.value.length > 0) {
        for (const orderItem of this.orderItemSource.value) {
          if (orderItem.tax) {
            totalTax += (orderItem.totalPriceDiscount !== undefined) ?
              parseFloat(String(orderItem.totalPriceDiscount)) : parseFloat(String(orderItem.totalPrice));
          }
        }
      }
      if (this.domainItemSource.value !== null && this.domainItemSource.value.length > 0) {
        for (const domainItem of this.domainItemSource.value) {
          totalTax += (domainItem.priceDiscount !== undefined) ?
            parseFloat(String(domainItem.priceDiscount)) :parseFloat(String(domainItem.price));
        }
      }

      // Calculate tax
      totalTax *= (parseFloat(String(taxValue)) / 100);
    }

    return totalTax;
  }

  /**
   * Adds one order item to the items list.
   * @param orderItem CartOrderItem object
   * @param skipListUpdate This option when true skips list update, only save in session
   */
  addOrderItem(orderItem: CartOrderItem, skipListUpdate: boolean = false): CartOrderItem {
    let items = (this.orderItemSource.value === null) ? [] : this.orderItemSource.value;
    const existingProduct = items.find(x => x.cartId === orderItem.cartId);
    if (existingProduct) {
      const existingIndex = items.indexOf(existingProduct);
      items.splice(existingIndex, 1);
    }
    const totalPrice = (orderItem.unitPrice * orderItem.quantity).toFixed(2);
    orderItem.totalPrice = parseFloat(totalPrice);
    orderItem.cartId = (typeof orderItem.cartId !== 'undefined' && orderItem.cartId !== null) ? orderItem.cartId : items.length;
    if (orderItem.setupPriceSingle !== undefined) {
      orderItem.setupPrice = orderItem.setupPriceSingle * orderItem.quantity;
    }
    items.push(orderItem);

    items.forEach((x) => {
      x.dateAdded = Date.now();
      if (orderItem !== x && orderItem.domain === x.domain) {
        const it1Group = this.productService.getGroupByGroupId(orderItem.gid);
        const it1Set = this.productService.getProductSetByTag(it1Group.tag);
        const it2Group = this.productService.getGroupByGroupId(x.gid);
        const it2Set = this.productService.getProductSetByTag(it2Group.tag);
        x.domain = (it1Set !== it2Set) ? x.domain : '';
      }
    });
    items.sort((a,b) => (a.cartId > b.cartId) ? 1 : ((b.cartId > a.cartId) ? -1 : 0));
    const newItems = this.calculatePromotion(skipListUpdate);
    items = (newItems && newItems.orderItems !== undefined) ? newItems.orderItems: items;
    this.auth.addStorage(CartService.orderItemsKey, JSON.stringify(items));
    this.orderItemSource.next(items);

    return orderItem;
  }

  addDomain(domain: ProductDomainItem): ProductDomainItem {
    const domains = (this.domainItemSource.value === null) ? [] : this.domainItemSource.value;
    const existingDomain = this.domainInCart(domain);
    if (existingDomain !== undefined) {
      const existingIndex = domains.indexOf(existingDomain);
      domains.splice(existingIndex, 1);
    }
    domains.forEach(x => {
      x.dateAdded = Date.now();
    });
    const cartDomain = {...domain};
    delete cartDomain.whois;
    delete cartDomain.status;
    delete cartDomain.added;
    delete cartDomain.prices;
    const newDomains = [...domains, ...[cartDomain]];
    this.auth.addStorage(CartService.domainItemsKey, JSON.stringify(newDomains));
    this.domainItemSource.next(newDomains);

    return domain;
  }

  domainInCart(domain: ProductDomainItem): ProductDomainItem | undefined {
    if (domain === undefined) {
      return undefined;
    }
    const domains = (this.domainItemSource.value === null) ? [] : this.domainItemSource.value;
    return domains.find(x => x.name === domain.name);
  }

  /**
   * Removes one order item from list and store new list in session storage, and return updated list.
   * @param item CartOrderItem of order item
   * @param skipListUpdate This option when true skips list update, only save in session
   */
  removeOrderItem(item: CartOrderItem, skipListUpdate: boolean = false): void {
    if (this.orderItemSource.value === null || this.orderItemSource.value.length === 0) {
      return;
    }
    if (item.domain !== undefined && item.domain !== null && item.domain.length > 0) {
      const domain = this.domainItemSource.value ? this.domainItemSource.value.find(x => x.name === item.domain) : null;
      if (domain) {
        delete domain.cartProductId;
        this.addDomain(domain);
      }
    }
    if (item.totalPrice === undefined) {
      item.totalPrice = item.unitPrice * item.quantity;
    }
    const index: number = this.orderItemSource.value.indexOf(item);
    if (index >= 0) {
      this.orderItemSource.value.splice(index, 1);
    }
    let cartId = 0;
    this.orderItemSource.value.forEach(x => {
      x.cartId = cartId;
      cartId++;
    });
    this.auth.addStorage(CartService.orderItemsKey, JSON.stringify(this.orderItemSource.value));
    if (!skipListUpdate) {
      this.orderItemSource.next(this.orderItemSource.value);
    }
  }

  getCartItemCount(bucket: string = null) {
    let quantity = 0;
    if (bucket === 'orderItems') {
      if (this.orderItemSource.value.length > 0) {
        this.orderItemSource.value.forEach(item => {
          quantity += item.quantity;
        });
      }
    } else {
      if (this.domainItemSource.value.length > 0) {
        quantity = this.domainItemSource.value.filter(x => x.cartProductId === undefined).length;
      }
    }

    return quantity;
  }

  removeDomain(domain: ProductDomainItem, skipListUpdate: boolean = false): void {
    if (this.domainItemSource.value === null || this.domainItemSource.value.length === 0) {
      return;
    }
    const existingDomain = this.domainInCart(domain);
    if (existingDomain !== null) {
      const index: number = this.domainItemSource.value.indexOf(existingDomain);
      if (index >= 0) {
        this.domainItemSource.value.splice(index, 1);
      }
    }
    const newDomains = [...this.domainItemSource.value];
    this.auth.addStorage(CartService.domainItemsKey, JSON.stringify(newDomains));
    if (!skipListUpdate) {
      this.domainItemSource.next(newDomains);
    }
  }

  productIdInCart(pid : number | string) {
    let inCart = false;
    if (typeof pid === 'number') {
      if (this.orderItemSource.value !== null && this.orderItemSource.value.length > 0) {
        this.orderItemSource.value.forEach(product => {
          if (product.pid === pid) {
            inCart = true;
          }
        });
      }
    } else {
      if (this.domainItemSource.value !== null && this.domainItemSource.value.length > 0) {
        this.domainItemSource.value.forEach(domain => {
          if (domain.name.indexOf(pid as string) >= 0) {
            inCart = true;
          }
        });
      }
    }

    return inCart;
  }

  /**
   * Updates item in list and session
   * @param item CartOrderItem
   * @param skipRemovalListUpdate This option when true skips list update when removing from list, only save in session
   * @param skipAddingLIstUpdate This option when true skips list update when adding to list, only save in session
   */
  updateOrderItem(item: CartOrderItem, skipRemovalListUpdate: boolean = false, skipAddingLIstUpdate: boolean = false): void {
    this.removeOrderItem(item, skipRemovalListUpdate);
    this.addOrderItem(item, skipAddingLIstUpdate);
  }

  updateDomainItem(domain: ProductDomainItem): void {
    this.removeDomain(domain);
    this.addDomain(domain);
  }
}
