import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard, CanDeactivateGuard, RoleGuard} from '../_guards';

import {ProfileComponent} from './profile.component';
import {ProfilePasswordComponent} from './password';
import {ProfileDetailsComponent} from './details';
import {ProfileEditComponent} from './edit';
import {CardListComponent} from './cards/card-list';
import {CardNewComponent} from './cards/new';
import {ProfileCreditComponent} from './credit';
import {ContactNewComponent} from './contacts/new';
import {ContactEditComponent} from './contacts/edit';
import {ContactListComponent} from './contacts/list';
import {ProfileAffiliateComponent} from './affiliate';

const profileRoutes: Routes = [
  {
    path: '',
    component: ProfileComponent,
    children: [
      {path: '', redirectTo: '/profile/details', pathMatch: 'full'},
      {path: 'details', component: ProfileDetailsComponent, data: {contentbg: 'bg-grey'}},
      {path: 'edit', component: ProfileEditComponent, canDeactivate: [CanDeactivateGuard], pathMatch: 'full'},
      {path: 'change-password', component: ProfilePasswordComponent, pathMatch: 'full'},
      {path: 'contacts', component: ContactListComponent, pathMatch: 'full'},
      {path: 'contact/new', component: ContactNewComponent, pathMatch: 'full'},
      {path: 'contacts/:id', component: ContactEditComponent, pathMatch: 'full'},
      {path: 'card/new', component: CardNewComponent, pathMatch: 'full'},
      {path: 'cards', component: CardListComponent, pathMatch: 'full'},
      {path: 'credit', component: ProfileCreditComponent, pathMatch: 'full'},
      {path: 'affiliate', component: ProfileAffiliateComponent, data: {contentbg: 'bg-grey'}, pathMatch: 'full'}
    ],
    canActivate: [AuthGuard, RoleGuard],
    data: {animation: 'profile'}
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(profileRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProfileRoutingModule {
}
