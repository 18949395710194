import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { OpenReplayService } from "../_services/open-replay.service";

@Injectable({ providedIn: "root" })
export class OpenReplayInterceptor implements HttpInterceptor {
  constructor(private replaySessionService: OpenReplayService) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      filter((event: any) => event instanceof HttpResponse),
      map((response: HttpResponse<any>) => {
        this.replaySessionService.sendEventToReplaySession(`${request.url}`, {
          request,
          response,
        });

        return response;
      }),
      map((event: HttpEvent<any>) => {
        return event;
      })
    );
  }
}
