import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {interval, Subscription} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {Ticket} from '../ticket';
import {TicketService} from '../ticket.service';
import {DepartmentService} from '../department.service';
import {Department} from '../department';
import {TicketCounts} from '../ticket-counts';
import {Pager} from '../pager';

@Component({
  selector: 'app-ticket-list',
  templateUrl: 'ticket-list.component.html'
})
export class TicketListComponent implements OnInit, OnDestroy {
  @Input() pageSize = 10;
  @Input() template = 'list';
  @Input() tickets: Ticket[];
  @Input() departments: Department[];
  @Input() ticketCounts: TicketCounts;
  @Input() outsideLoading = false;
  private subscription: Subscription = new Subscription();
  ticketsTimer = interval(30000);
  loading = {
    counts: false,
    tickets: false,
    departments: false
  };
  loadingCount = {
    tickets: 0
  };
  pager: Pager;
  order = '';
  orderBy = '';
  status = 'all';
  statuses = [];
  modalRef: BsModalRef;
  selectedItem: Ticket;

  constructor(
    private service: TicketService,
    private router: Router,
    private route: ActivatedRoute,
    private departmentService: DepartmentService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    if (this.template !== 'last') {
      this.loadTicketCountsAndList();
      const loadStuffSub = this.ticketsTimer.subscribe(val => {
        this.loadTicketCountsAndList();
      });
      this.subscription.add(loadStuffSub);

// Get departments list
      this.subscription.add(
          this.departmentService.getDepartments()
              .pipe(take(1))
              .subscribe(
                  departments => {
                    this.departments = departments;
                    this.loading.departments = false;
                  }
              )
      );
    }
  }

  loadTicketCountsAndList() {
    this.loading.counts = true;
    this.loading.departments = true;
// Get ticket counts (needed for quick view and paging), and list
    this.subscription.add(
      this.service.getTicketCounts({status: this.status})
        .subscribe((tickCounts: TicketCounts) => {
          this.loading.counts = false;
          this.ticketCounts = tickCounts;
          this.getStatusType();
          this.getTickets();
        })
    );
  }

  getDepartment(id: number): Department {
    if (this.departments === undefined) {
      return null;
    }
    return this.departments.find(department => department.id === id);
  }

  getName(department: Department): string {
    return this.departmentService.getName(department);
  }

  getStatusType() {
    this.statuses = Object.keys(this.ticketCounts.status).map(key => ({type: key, value: this.ticketCounts.status[key]}));
  }

  getTickets(resetPage: boolean = false) {
    // Get tickets list
    const ticketSubs = this.route.queryParams.pipe(
      switchMap(params => {
        this.status = (typeof params.status !== 'undefined' && !resetPage) ? params.status : this.status;
        this.loading.tickets = true;
        const newParams = {
          page: (typeof params.page !== 'undefined' && !resetPage) ? params.page : 1,
          status: this.status,
          orderby: this.orderBy,
          order: this.order
        };
        return this.service.getList(newParams, this.ticketCounts, this.pageSize, this.loadingCount.tickets === 0);
      })
    ).subscribe(res => {
      this.loading.tickets = false;
      this.loadingCount.tickets += 1;
      this.pager = res.pager;
      this.tickets = res.tickets;
    });
    this.subscription.add(ticketSubs);
  }

  sortTickets(sortElement: string) {
    this.orderBy = sortElement;
    if (this.orderBy === sortElement) {
      this.order = (this.order === 'asc') ? 'desc' : 'asc';
    }
    this.activateFilter();
  }

  showFilterIcon(element: string, direction: string) {
    if (this.orderBy === element) {
      return direction === this.order;
    }
    return true;
  }

  closeTicket(template: TemplateRef<any>, viewItem: Ticket) {
    this.selectedItem = viewItem;
    this.modalRef = this.modalService.show(template);
  }

  confirm(ticket: Ticket): void {
    this.modalRef.hide();
    ticket.status = 'Closed';
    this.loading.tickets = true;
    const updateTicketSub = this.service.update(ticket).subscribe(res => {
      this.loading.tickets = false;
      this.getTickets();
    });
    this.subscription.add(updateTicketSub);
  }

  decline(): void {
    this.modalRef.hide();
  }

  changeStatus(value: string) {
    this.status = value;
    this.activateFilter();
  }

  activateFilter() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          page: 1,
          status: this.status,
          orderby: this.orderBy,
          order: this.order
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
