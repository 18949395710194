import {Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {
  faCcAmex,faCcDinersClub,faCcDiscover,faCcMastercard,faCcPaypal,faCcVisa,faCcJcb
} from '@fortawesome/free-brands-svg-icons';
import {faCreditCard} from '@fortawesome/free-solid-svg-icons';

import {AlertService, AuthenticationService} from '../../../_services';
import {PaymentService} from '../../../_services/payment.service';
import {Client} from '../../../_models';
import {PayMethod} from '../../../_models/pay-method';
import {Invoice} from '../../../accounting/bills/invoice';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html'
})
export class CardListComponent implements OnDestroy {
  @Input() parentModalRef: BsModalRef;
  @Input() isModal = false;
  @Input() invoice: Invoice;
  @Output() toggleModal = new EventEmitter<boolean>();
  @Output() selectedCard = new EventEmitter<PayMethod>();
  @Output() newCardPaid = new EventEmitter<boolean>();
  @ViewChild('newCardModal') newCardModal: TemplateRef<any>;

  private subscription: Subscription = new Subscription();
  faCcVisa = faCcVisa;
  faCcMastercard = faCcMastercard;
  faCcPaypal = faCcPaypal;
  faCcAmex = faCcAmex;
  faCcJcb = faCcJcb;
  faCreditCard = faCreditCard;
  faCcDinersClub = faCcDinersClub;
  faCcDiscover = faCcDiscover;
  loading = false;
  user: Client;
  paymentCards: PayMethod[];
  modalRef: BsModalRef;
  selectedPayMethod: PayMethod;
  newCardIsModal = true;

  constructor(
    private alert: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private service: PaymentService,
    private auth: AuthenticationService,
    private modalService: BsModalService
  ) {
    this.auth.currentUser
      .subscribe((user) => {
        if (user) {
          this.user = user;
          this.loadPayMethods();
        }
      });
  }

  deletePayMethod(paymentCard: PayMethod, template: TemplateRef<any>) {
    this.selectedPayMethod = paymentCard;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  confirm() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = undefined;
    }
    this.subscription.add(
      this.service.deletePayMethod(this.selectedPayMethod.id)
        .subscribe((res) => {
          if (res) {
            this.loadPayMethods();
            this.alert.success(`Successfully deleted card: ${this.selectedPayMethod.description}`);
            this.selectedPayMethod = null;
          }
        })
    );
  }

  decline() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = undefined;
    }
    this.toggleModal.emit(false);
    location.reload();
  }

  invoicePaid() {
    this.newCardPaid.emit(true);
  }

  private loadPayMethods() {
    this.loading = true;
    this.route.queryParams.pipe(
      switchMap(params => this.service.getUserCardsList(params, this.user.userid))
    ).subscribe(res => {
      this.loading = false;
      if (res !== null) {
        this.paymentCards = res.paymethods;
      }
    });
  }

  selectCard(paymentCard: PayMethod) {
    this.selectedCard.emit(paymentCard);
    this.parentModalRef.hide();
  }

  openModal(template: TemplateRef<any>, newCardIsModal: boolean = true, modalClass: string = 'modal-lg') {
    this.newCardIsModal = newCardIsModal;
    this.modalRef = this.modalService.show(template, {class: modalClass, ignoreBackdropClick: true});
    this.toggleModal.emit(true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
