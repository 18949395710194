<div class="container-fluid" *ngIf="filter; else fullVersion">
  <div class="row d-flex align-items-baseline mb-3" *ngFor="let orderItem of orderItems; let last = last">
    <div class="col-12 col-lg-8 mb-3 mb-lg-0">
      <div>
        <h6 class="fwb mb-2">{{orderItem.name}}</h6>
        <p *ngIf="orderItem.description"><span class="font-weight-bold" i18n>Opis</span>:&nbsp;{{orderItem.description}}</p>
        <div class="alert alert-warning" *ngIf="orderItem.errors !== undefined && orderItem.errors.length > 0">
          <p *ngFor="let err of orderItem.errors">
            <span *ngIf="err === 'rootPassword'" i18n>Root lozinka istekla, postavite ponovno</span>
            <span *ngIf="err === 'noDomainChosen'" i18n>Domena nije odabrana. Molimo ponovno konfiguriraj proizvod.</span>
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-4 col-lg-2 mb-3 mb-sm-0 text-lg-center">
      <div class="input-group">
        <div class="input-group-prepend down" (click)="removeQuantity(orderItem)">
          <div class="input-group-text">-</div>
        </div>
        <input class="form-control" type="text" min="1" value="{{orderItem.quantity}}" (change)="setQuantity(orderItem, $event.target.value)">
        <div class="input-group-append up" (click)="addQuantity(orderItem)">
          <div class="input-group-text">+</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-4 col-lg-1 mb-3 mb-sm-0 text-lg-right">{{orderItem.totalPrice | currency: cartCurrency?.code : 'symbol-narrow'}}</div>
    <a class="col-12 col-sm-4 col-lg-1 btn" routerLink="../product" [queryParams]="{pid: orderItem.pid, gid: orderItem.gid, cid: orderItem.cartId}" *ngIf="orderItem.canEdit">
      <fa-icon tooltip="Konfiguriraj proizvod" i18n-tooltip="Konfiguriraj proizvod" [icon]="faEdit"></fa-icon>
    </a>
    <hr class="d-lg-none" *ngIf="!last">
  </div>
</div>

<ng-template #fullVersion>
  <form [formGroup]="orderForm" (ngSubmit)="createOrder()">
    <div class="card border-0 mb-3">
      <div class="card-header bg-color1"><h5 class="color-white"><svg-icon src="assets/svg/shop-white.svg" class="icon icon-20 icon-shop mr-2"></svg-icon><span i18n>Košarica</span></h5></div>
      <div class="container-fluid" *ngIf="domainItems?.length > 0 || orderItems?.length > 0; else emptyOrders">
        <div class="row bd-bottom pt-2 pb-2 d-none d-lg-flex">
          <div class="col-sm-5"><h6 class="font-weight-bold cart-header-title" i18n>Proizvod</h6></div>
          <div class="col-sm-1 text-center"><h6 class="font-weight-bold cart-header-title" i18n>Cijena</h6></div>
          <div class="col-sm-2 text-center"><h6 class="font-weight-bold cart-header-title" i18n>Količina</h6></div>
          <div class="col-sm-1 text-center"><h6 class="font-weight-bold cart-header-title" i18n>Uredi</h6></div>
          <div class="col-sm-1 text-center"><h6 class="font-weight-bold cart-header-title" i18n>Ukloni</h6></div>
          <div class="col-sm-2 text-right"><h6 class="font-weight-bold cart-header-title" i18n>Ukupno</h6></div>
        </div>

        <ng-container *ngFor="let orderItem of orderItems; let last = last">
          <div class="row pt-2 pb-2 bd-bottom align-items-baseline" [ngClass]="{'bd-bottom': !last || domainItems?.length > 0}">
            <div class="col-12 col-lg-5 mb-2 mb-lg-0">
              <div class="row mb-2">
                <div class="col-4 mb-2 font-weight-bold d-lg-none"><h6 class="font-weight-bold" i18n>Proizvod</h6></div>
                <div class="col-8 col-lg-12">
                  <h6>{{orderItem.name}}</h6>
                  <div class="alert alert-warning mt-2 mb-0" *ngIf="orderItem.errors !== undefined && orderItem.errors.length > 0">
                    <p *ngFor="let err of orderItem.errors">
                      <span *ngIf="err === 'rootPassword'" i18n>Root lozinka istekla, postavite ponovno</span>
                      <span *ngIf="err === 'noDomainChosen'" i18n>Domena nije odabrana. Molimo ponovno konfiguriraj proizvod.</span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-4 col-lg-3 mb-2 mb-lg-0 font-weight-bold" *ngIf="orderItem?.billingCycle" i18n>Period naplate:</div>
                <div class="col-8 col-lg-9 mb-2 mb-lg-0" *ngIf="orderItem?.billingCycle">{{orderItem.billingCycleTranslated}}</div>

                <div class="col-4 col-lg-3 mb-2 mb-lg-0 font-weight-bold" *ngIf="orderItem?.domain" i18n>Domena:</div>
                <div class="col-8 col-lg-9 mb-2 mb-lg-0" *ngIf="orderItem?.domain">{{orderItem.domain}}</div>

                <div class="col-4 col-lg-3 mb-2 mb-lg-0 font-weight-bold" *ngIf="orderItem?.description" i18n>Opis:</div>
                <div class="col-8 col-lg-9 mb-2 mb-lg-0" *ngIf="orderItem?.description">{{orderItem.description}}</div>
              </div>
            </div>

            <div class="col-4 mb-2 font-weight-bold d-lg-none"><h6 class="font-weight-bold" i18n>Cijena</h6></div>
            <div class="col-8 mb-2 col-lg-1 mb-lg-0 text-lg-right">
              <p *ngIf="orderItem.setupPriceSingle === undefined">
                {{orderItem.unitPrice | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk"><br>({{(orderItem.unitPrice*7.53450) | number: '1.2-2'}} HRK)</span>
              </p>
              <p *ngIf="orderItem.setupPriceSingle !== undefined">
                {{orderItem.unitPrice - orderItem.setupPriceSingle | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk"><br>({{((orderItem.unitPrice - orderItem.setupPriceSingle)*7.53450) | number: '1.2-2'}} HRK)</span>
              </p>
            </div>

            <div class="col-4 mb-2 font-weight-bold d-lg-none mb-3"><h6 class="font-weight-bold" i18n>Količina</h6></div>
            <div class="col-8 mb-2 col-lg-2 mb-lg-0 text-lg-center">
              <div class="input-group">
                <div class="input-group-prepend down" (click)="removeQuantity(orderItem)">
                  <div class="input-group-text">-</div>
                </div>
                <input class="form-control" type="text" min="1" value="{{orderItem.quantity}}" (change)="setQuantity(orderItem, $event.target.value)">
                <div class="input-group-append up" (click)="addQuantity(orderItem)">
                  <div class="input-group-text">+</div>
                </div>
              </div>
            </div>

            <div class="col-4 mb-2 font-weight-bold d-lg-none mb-3"><h6 class="font-weight-bold" i18n>Uredi</h6></div>
            <div class="col-8 mb-2 col-lg-1 mb-lg-0 text-lg-center">
              <a class="btn" routerLink="../product" [queryParams]="{pid: orderItem.pid, gid: orderItem.gid, cid: orderItem.cartId}" *ngIf="orderItem.canEdit">
                <fa-icon tooltip="Konfiguriraj proizvod" i18n-tooltip="Konfiguriraj proizvod" [icon]="faEdit"></fa-icon>
              </a>
            </div>

            <div class="col-4 mb-2 font-weight-bold d-lg-none mb-3"><h6 class="font-weight-bold" i18n>Ukloni</h6></div>
            <div class="col-8 mb-2 col-lg-1 mb-lg-0 text-lg-center">
              <fa-icon (click)="removeOrderItem(orderItem)" class="btn" tooltip="Ukloni proizvod" i18n-tooltip="Ukloni proizvod" [icon]="faTrashAlt"></fa-icon>
            </div>

            <div class="col-4 mb-2 font-weight-bold d-lg-none"><h6 class="font-weight-bold" i18n>Ukupno</h6></div>
            <div class="col-8 mb-2 col-lg-2 mb-lg-0 text-lg-right">
              <ng-container *ngIf="orderItem.totalPriceDiscount !== undefined">
                <span class="text-danger" *ngIf="orderItem.setupPrice !== undefined">
                  <del>
                    {{orderItem.totalPrice - orderItem.setupPrice | currency: cartCurrency?.code : 'symbol-narrow'}}
                    <span *ngIf="cartCurrency.code === 'EUR' && displayHrk"><br>({{((orderItem.totalPrice - orderItem.setupPrice)*7.53450) | number: '1.2-2'}} HRK)</span>
                  </del>
                </span>
                <span class="text-danger" *ngIf="orderItem.setupPrice === undefined">
                  <del>
                    {{orderItem.totalPrice | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
                    <span *ngIf="cartCurrency.code === 'EUR' && displayHrk"><br>({{(orderItem.totalPrice*7.53450) | number: '1.2-2'}} HRK)</span>
                  </del>
                </span>
                <p>
                  {{orderItem.totalPriceDiscount | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk"><br>({{(orderItem.totalPriceDiscount*7.53450) | number: '1.2-2'}} HRK)</span>
                </p>
              </ng-container>
              <ng-container *ngIf="orderItem.totalPriceDiscount === undefined">
                <span *ngIf="orderItem.setupPrice !== undefined">
                  {{orderItem.totalPrice - orderItem.setupPrice | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk"><br>({{((orderItem.totalPrice - orderItem.setupPrice)*7.53450) | number: '1.2-2'}} HRK)</span>
                </span>
                <span *ngIf="orderItem.setupPrice === undefined">
                  {{orderItem.totalPrice | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk"><br>({{((orderItem.totalPrice)*7.53450) | number: '1.2-2'}} HRK)</span>
                </span>
              </ng-container>
            </div>
          </div>

          <div class="row pt-2 pb-2 bd-bottom" [ngClass]="{'bd-bottom': !last || domainItems?.length > 0}" *ngIf="orderItem.setupPrice !== undefined && orderItem.setupPrice > 0">
            <div class="col-4 mb-2 font-weight-bold d-lg-none"><h6 class="font-weight-bold" i18n>Proizvod</h6></div>
            <div class="col-8 mb-2 col-lg-5 mb-lg-0 font-weight-bold"><span i18n>Jednokratna naknada za</span>&nbsp;{{orderItem.name}}</div>

            <div class="col-4 mb-2 font-weight-bold d-lg-none"><h6 class="font-weight-bold" i18n>Cijena</h6></div>
            <div class="col-8 mb-2 col-lg-1 mb-lg-0 text-lg-right">
              {{orderItem.setupPriceSingle | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
              <span *ngIf="cartCurrency.code === 'EUR' && displayHrk"><br>({{(orderItem.setupPriceSingle*7.53450) | number: '1.2-2'}} HRK)</span>
            </div>

            <div class="col-4 mb-2 font-weight-bold d-lg-none"><h6 class="font-weight-bold" i18n>Količina</h6></div>
            <div class="col-8 mb-2 col-lg-2 mb-lg-0 text-lg-center">{{orderItem.quantity}}</div>

            <div class="col-sm-1 text-center d-none d-lg-flex">-</div>
            <div class="col-sm-1 text-center d-none d-lg-flex">-</div>

            <div class="col-4 mb-2 font-weight-bold d-lg-none"><h6 class="font-weight-bold" i18n>Ukupno</h6></div>
            <div class="col-8 mb-2 col-lg-2 mb-lg-0 text-lg-right">
              {{orderItem.setupPrice | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
              <span *ngIf="cartCurrency.code === 'EUR' && displayHrk"><br>({{(orderItem.setupPrice*7.53450) | number: '1.2-2'}} HRK)</span></div>
          </div>
        </ng-container>

        <div class="row pt-2 pb-2 align-items-baseline" [ngClass]="{'bd-bottom': !last}" *ngFor="let domain of domainItems; let last = last">
          <div class="col-12 col-lg-5 mb-2 mb-lg-0">
            <div class="row mb-2">
              <div class="col-4 mb-2 font-weight-bold d-lg-none"><h6 class="font-weight-bold" i18n>Proizvod</h6></div>
              <h6 class="col-8 col-lg-12 mb-2 mb-lg-0">{{domain.name}}</h6>
            </div>

            <div class="row">
              <div class="col-4 col-lg-3 mb-2 mb-lg-0 font-weight-bold" *ngIf="domain.domainType" i18n>Vrsta:</div>
              <div class="col-8 col-lg-9 mb-2 mb-lg-0" *ngIf="domain.domainType && domain.domainType === 'register'" i18n>Registracija</div>
              <div class="col-8 col-lg-9 mb-2 mb-lg-0" *ngIf="domain.domainType && domain.domainType === 'transfer'" i18n>Prijenos</div>
              
              <div class="col-4 col-lg-3 mb-2 mb-lg-0 font-weight-bold" *ngIf="domain.pricingPeriodId" i18n>Period:</div>
              <div class="col-8 col-lg-9 mb-2 mb-lg-0" *ngIf="domain.pricingPeriodId">{{domain?.pricingPeriodId}}&nbsp;<span i18n>godina</span></div>

              <div class="col-4 col-lg-3 mb-2 mb-lg-0 font-weight-bold" *ngIf="domain.useIdProtect" i18n>ID protect</div>
              <div class="col-8 col-lg-9 mb-2 mb-lg-0" *ngIf="domain.useIdProtect">Da</div>

              <ng-container *ngIf="domain.additionalFields">
                <ng-container *ngFor="let addField of domain.additionalFields; let index = index">
                  <div class="col-4 col-lg-3 mb-2 mb-lg-0 font-weight-bold">{{addField.name}}:&nbsp;</div>
                  <div class="col-8 col-lg-9 mb-2 mb-lg-0">{{domain.additionalFields[index].value}}</div>
                </ng-container>
              </ng-container>
            </div>
          </div>

          <div class="col-4 mb-2 font-weight-bold d-lg-none"><h6 class="font-weight-bold" i18n>Cijena</h6></div>
          <div class="col-8 mb-2 col-lg-1 mb-lg-0 text-lg-right">
            <div *ngIf="domain.useIdProtect; else withoutProtect">
              <p>
                {{domain.price - domain.idprotect.price | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk"><br>({{((domain.price - domain.idprotect.price)*7.53450) | number: '1.2-2'}} HRK)</span>
              </p>
              <p>
                {{domain.idprotect.price | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk"><br>({{(domain.idprotect.price*7.53450) | number: '1.2-2'}} HRK)</span>
              </p>
            </div>
            <ng-template #withoutProtect>
              {{domain.price | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
              <span *ngIf="cartCurrency.code === 'EUR' && displayHrk"><br>({{(domain.price*7.53450) | number: '1.2-2'}} HRK)</span>
            </ng-template>
          </div>

          <div class="col-8 mb-2 col-lg-2 mb-lg-0 text-center d-none d-lg-flex">-</div>

          <div class="col-4 mb-2 font-weight-bold d-lg-none"><h6 class="font-weight-bold" i18n>Uredi</h6></div>
          <div class="col-8 mb-2 col-lg-1 mb-lg-0 text-lg-center">
            <!--            todo finish editing domain-->
            <a class="btn" routerLink="../product" [queryParams]="{
            groupSet: 'domain',
            domain: domain.domain,
            domainExtension: domain.extension
            }">
              <fa-icon tooltip="Konfiguriraj domenu" i18n-tooltip="Konfiguriraj domenu" [icon]="faEdit"></fa-icon>
            </a>
          </div>

          <div class="col-4 mb-2 font-weight-bold d-lg-none"><h6 class="font-weight-bold" i18n>Ukloni</h6></div>
          <div class="col-8 mb-2 col-lg-1 mb-lg-0 text-lg-center">
            <fa-icon (click)="removeDomain(domain)" class="btn" tooltip="Ukloni domenu" i18n-tooltip="Ukloni domenu" [icon]="faTrashAlt"></fa-icon>
          </div>

          <div class="col-4 mb-2 font-weight-bold d-lg-none"><h6 class="font-weight-bold" i18n>Ukupno</h6></div>
          <div class="col-8 mb-2 col-lg-2 mb-lg-0 text-lg-right">
            <ng-container *ngIf="domain.priceDiscount !== undefined">
              <p class="text-danger">
                <del>
                  {{domain.price | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk"><br>({{(domain.price*7.53450) | number: '1.2-2'}} HRK)</span>
                </del>
              </p>
              <p>
                {{domain.priceDiscount | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk"><br>({{(domain.priceDiscount*7.53450) | number: '1.2-2'}} HRK)</span>
              </p>
            </ng-container>
            <ng-container *ngIf="domain.priceDiscount === undefined">
              <p>
                {{domain.price | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk"><br>({{(domain.price*7.53450) | number: '1.2-2'}} HRK)</span>
              </p>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-template #emptyOrders>
        <div class="m-0 p-2" i18n>Nema proizvoda u košarici</div>
      </ng-template>
    </div>

    <div class="card col-12 col-sm-6 border-0 mb-3 p-0 bg-white" *ngIf="domainItems?.length > 0 || orderItems?.length > 0">
      <div class="card-header bg-color1"><h5 class="color-white" i18n>Promotivni kupon:</h5></div>
      <div class="form-row p-3">
        <div class="col-sm-9 mb-3 mb-sm-0">
          <input id="coupon" type="text" class="form-control" formControlName="coupon" [ngClass]="{ 'is-invalid': submitted && f.coupon.errors }">
        </div>
        <div class="col-sm-3" *ngIf="f.coupon.value && f.coupon.value !== '' && f.coupon.dirty">
          <button class="btn rounded-light btn-info" type="button" (click)="checkCoupon()"><span i18n>Provjeri</span><fa-icon *ngIf="loading.code" [icon]="faSpinner" [spin]="true"></fa-icon></button>
        </div>
      </div>
    </div>

    <div class="row mb-3" *ngIf="domainItems?.length > 0 || orderItems?.length > 0">
      <div class="col-sm-2 fsmaller"></div>
      <div class="col-sm-8 text-right"><h6 class="font-weight-bold" i18n>Osnovica:</h6></div>
      <div class="col-sm-2 text-right">
        <ng-container *ngIf="f.totalDiscount.value !== f.total.value">
          <p class="text-danger">
            <del>
              {{f.totalDiscount.value | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
              <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(f.totalDiscount.value*7.53450) | number: '1.2-2'}} HRK)</span>
            </del>
          </p>
          <p>
            {{f.total.value | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
            <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(f.total.value*7.53450) | number: '1.2-2'}} HRK)</span>
          </p>
        </ng-container>
        <ng-container *ngIf="f.totalDiscount.value === f.total.value">
          <p>
            {{f.total.value | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
            <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(f.total.value*7.53450) | number: '1.2-2'}} HRK)</span>
          </p>
        </ng-container>
      </div>
    </div>
    <div class="row mb-3" *ngIf="domainItems?.length > 0 || orderItems?.length > 0">
      <div class="col-sm-2 fsmaller">&nbsp;</div>
      <div class="col-sm-8 text-right"><h6 class="font-weight-bold"><span i18n>Porez</span>&nbsp;({{user?.tax1_name ?? 'PDV'}} @ {{user?.tax1_value ?? 25}}%)</h6></div>
      <div class="col-sm-2 text-right">
        <p>
          {{f.totalTax.value | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
          <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(f.totalTax.value*7.53450) | number: '1.2-2'}} HRK)</span>
        </p>
      </div>
    </div>

    <!-- Default tax value hint -->
    <div class="row mb-3">
      <small *ngIf="!user || !user.tax1_name || user.tax1_value === undefined" class="text-right w-full" i18n>
        *Nakon što se prijaviš, moći ćeš vidjeti točan iznos poreza koji će biti obračunat na tvoju narudžbu.
      </small>
    </div>

    <div class="row mb-3" *ngIf="domainItems?.length > 0 || orderItems?.length > 0">
      <div class="col-sm-2 fsmaller">&nbsp;</div>
      <div class="col-sm-8 text-right"><h6 class="font-weight-bold" i18n>Sveukupno za platiti</h6></div>
      <div class="col-sm-2 text-right">
        <p>
          {{f.totalWithTax.value | currency: cartCurrency?.code : 'symbol-narrow'}}&nbsp;
          <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(f.totalWithTax.value*7.53450) | number: '1.2-2'}} HRK)</span>
        </p>
      </div>
    </div>

    <hr>
    <label class="form-check-label"><span i18n>Molimo te da prije dovršetka narudžbe pažljivo pročitaš naše</span>&nbsp;<a [href]="getTosLink()" target="_blank" i18n>Uvjete korištenja usluga</a>&nbsp;<span i18n>i obveze koje iz njih proizlaze.</span></label>
    <label for="tos" class="form-check-label mb-3"><span i18n>Ukoliko imaš pitanja vezanih uz same Uvjete, molimo te da se</span>&nbsp;<a [href]="getSupportLink()" target="_blank" i18n>obratiš našoj podršci</a>&nbsp;<span i18n>koja će ti odgovoriti na sva pitanja koja te zanimaju u vezi Uvjeta korištenja usluga.</span></label>
    <div class="form-check mb-4 mt-3">
      <input id="tos" type="checkbox" formControlName="tos" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.tos.errors }" />
      <label for="tos" class="form-check-label"><span i18n>Prihvaćam da ispunjenje ugovora započne odmah pri aktivaciji usluge i slažem se sa svime što navode</span>&nbsp;<a [href]="getTosLink()" target="_blank" i18n>Uvjeti korištenja usluga</a></label>
      <div *ngIf="submitted && f.tos.errors" class="invalid-feedback w-100">
        <div *ngIf="f.tos.errors.required" i18n>Potrebno je složiti se s Uvjetima korištenja usluga</div>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-3">
      <a routerLink="../" class="btn bg-color2 width-13" i18n>Dodaj novi proizvod +</a>
      <button class="btn btn-primary width-13" type="submit" *ngIf="user && (orderItems?.length > 0 || domainItems?.length > 0)" i18n>Odaberi način plaćanja</button>
      <button class="btn btn-primary width-13" type="submit" *ngIf="!user && (orderItems?.length > 0 || domainItems?.length > 0)" i18n>Prijavi se</button>
    </div>
  </form>
</ng-template>
