import { LOCALE_ID, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { registerLocaleData } from '@angular/common';
import localeHr from '@angular/common/locales/hr';
registerLocaleData(localeHr);

import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgSelectModule} from '@ng-select/ng-select';
import {ToastrModule} from 'ngx-toastr';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {PasswordStrengthMeterModule} from 'angular-password-strength-meter';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {CollapseModule} from 'ngx-bootstrap/collapse';

import {AppComponent} from './app.component';
import {AlertComponent} from './_components';
import {RegisterComponent} from './register';
import {ServicesComponent} from './services';
import {MessagesComponent} from './messages';
import {LoaderModule} from './loader/loader.module';
import {AuthenticationService, HttpErrorHandler, MessageService, PrintService} from './_services';

import {httpInterceptorProviders} from './http-interceptors';
import {APP_DI_CONFIG, AppConfigModule} from './app-config.module';
import {ExternalUrlModule, externalUrlProvider} from './shared/external-url';

import {PasswordComponent} from './password';
import {PasswordNewComponent} from './password-new';
import {LoginModule} from './login';
import {HomeComponent} from './home';
import {AnnouncementsComponent} from './announcements';
import {DiscountComponent} from './discount';
import {NotFoundComponent} from './_components/not-found.component';
import {UnblockIpComponent} from './unblock-ip';
import {PrintLayoutComponent} from './print-layout';
import {NetworkComponent} from './network';
import {NetworkServerComponent} from './network/server';
import {ProductCancelModule} from './shared/product-cancel';
import {ProductChangePasswordModule} from './shared/change-password/product-change-password.module';
import {AppRoutingModule} from './app.routing.module';
import {CartModule} from './cart';
import {SupportModule} from './support';
import {AccountingModule} from './accounting';
import {ActivateComponent} from './activate';
import {ProfileModule} from './profile';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    RecaptchaV3Module,
    ExternalUrlModule,
    FormsModule,
    HttpClientModule,
    NgSelectModule,
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    PasswordStrengthMeterModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    AppConfigModule,
    FontAwesomeModule,
    LoaderModule,
    TooltipModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      preventDuplicates: true,
      positionClass: 'toast-top-right'
    }),
    CollapseModule,
    AccordionModule.forRoot(),
    LoginModule,
    AccountingModule,
    ProductCancelModule,
    ProductChangePasswordModule,
    CartModule,
    SupportModule,
    ProfileModule,
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    NotFoundComponent,
    PrintLayoutComponent,
    RegisterComponent,
    ServicesComponent,
    AnnouncementsComponent,
    DiscountComponent,
    MessagesComponent,
    PasswordComponent,
    PasswordNewComponent,
    HomeComponent,
    UnblockIpComponent,
    NetworkComponent,
    NetworkServerComponent,
    ActivateComponent
  ],
  providers: [
    AuthenticationService,
    HttpErrorHandler,
    MessageService,
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl');
        const target = (typeof route.paramMap.get('target') !== 'undefined' && route.paramMap.get('target') !== '') ? route.paramMap.get('target') : '_self';
        const rel = route.paramMap.get('rel');
        const newWindow = window.open();
        newWindow.opener = null;
        // @ts-ignore
        newWindow.location = externalUrl;
      },
    },
    httpInterceptorProviders,
    PrintService,
    {provide: LOCALE_ID, useValue: APP_DI_CONFIG.defaultLocale},
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: APP_DI_CONFIG.recaptchaKey}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
