<ng-container *ngIf="template === 'list'">
  <div class="d-flex justify-content-between flex-wrap align-items-center bd-bottom p-3 bg-white">
    <h3 class="col-12 col-lg-6 font-weight-bold mb-2 text-color-2 mb-3 mb-md-0" i18n>Pregled mojih upita</h3>

    <div class="col-12 col-lg-6 d-flex flex-wrap justify-content-between align-items-center">
      <div class="col-sm-6 d-flex align-items-center flex-wrap flex-lg-nowrap mr-3 mb-3 mb-lg-0">
        <span class="mr-3" i18n>Filter:</span>
        <select class="rounded-light" (change)="changeStatus($event.target.value)" *ngIf="!loading.counts && ticketCounts">
          <option value="all" [selected]="status === 'all'" i18n>Svi upiti</option>
          <option value="{{tmpStatus.type}}" *ngFor="let tmpStatus of statuses" [selected]="status === tmpStatus.type"><span class="mr-sm">{{tmpStatus.value.title}}</span>({{tmpStatus.value.count}})</option>
        </select>
        <app-loader *ngIf="loading.counts"></app-loader>
      </div>

      <div class="col-12 col-sm-6 col-lg-auto">
        <a routerLink="/support/ticket/new" class="btn bg-color2 rounded-light" i18n>Novi upit</a>
      </div>
    </div>
  </div>

  <div class="p-3">
    <div class="container-fluid">
      <div class="row bg-color2 p-2 d-none d-lg-flex" *ngIf="tickets?.length">
        <div class="col-2 d-flex justify-content-between" (click)="sortTickets('deptid')">
          <span i18n>Odjel</span>
          <div class="d-flex flex-column">
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white icon-reverse mb-1" *ngIf="showFilterIcon('deptid','asc')"></svg-icon>
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white" *ngIf="showFilterIcon( 'deptid','desc')"></svg-icon>
          </div>
        </div>
        <div class="col-5 d-flex justify-content-between" (click)="sortTickets('subject')">
          <span i18n>Naslov</span>
          <div class="d-flex flex-column">
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white icon-reverse mb-1" *ngIf="showFilterIcon('subject','asc')"></svg-icon>
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white" *ngIf="showFilterIcon( 'subject','desc')"></svg-icon>
          </div>
        </div>
        <div class="col-2 d-flex justify-content-between" (click)="sortTickets('status')">
          <span i18n>Status</span>
          <div class="d-flex flex-column">
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white icon-reverse mb-1" *ngIf="showFilterIcon('status','asc')"></svg-icon>
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white" *ngIf="showFilterIcon( 'status','desc')"></svg-icon>
          </div>
        </div>
        <div class="col-2 d-flex justify-content-between" (click)="sortTickets('date')">
          <span i18n>Zadnja aktivnost</span>
          <div class="d-flex flex-column">
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white icon-reverse mb-1" *ngIf="showFilterIcon('date','asc')"></svg-icon>
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white" *ngIf="showFilterIcon( 'date','desc')"></svg-icon>
          </div>
        </div>
        <div class="col-1" i18n></div>
      </div>


    <ng-container *ngIf="tickets?.length">
      <div class="row bg-white bd-bottom p-2" [class.mb-4]="last" *ngFor="let ticket of tickets; let last = last;">
        <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Odjel</div>
        <div class="col-8 mb-2 col-lg-2 mb-lg-0"><span *ngIf="departments"
                                                       [ngClass]="ticket?.clientunread === 1 ? 'font-weight-bold' : ''">{{ getName(getDepartment(ticket.deptid)) }}</span></div>

        <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Naslov</div>
        <div class="col-8 mb-2 col-lg-5 mb-lg-0">
          <a routerLink="../tickets/{{ticket.tid}}"
             [queryParams]="{c: ticket.c}"
             [ngClass]="ticket?.clientunread === 1 ? 'font-weight-bold' : ''"
             class="d-flex flex-column">
            <span>#{{ ticket.tid }}</span>
            <span class="fsmaller text-dark">{{ ticket.subject | slice: 0: 60 }} <span *ngIf="ticket.subject.length > 60">...</span> </span>
          </a>
        </div>

        <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Status</div>
        <div class="col-8 mb-2 col-lg-2 mb-lg-0">{{ ticket.status }}</div>

        <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Zadnja aktivnost</div>
        <div class="col-8 mb-2 col-lg-2 mb-lg-0">{{ ticket.lastreply | date:'short' }}</div>

        <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Zatvori upit</div>
        <div class="col-8 mb-2 col-lg-1 mb-lg-0">
          <button type="button" class="btn btn-primary rounded-light" tooltip="Zatvori upit" i18n-tooltip="Zatvori upit"
                  *ngIf="ticket.status !== 'Closed'" (click)="closeTicket(closeTicketModal, ticket)" i18n>Zatvori</button>
        </div>
      </div>
    </ng-container>

      <div *ngIf="!tickets?.length && !loading.tickets && !loading.departments" i18n>Nemaš upita prema korisničkoj službi</div>
      <app-pager [pager]="pager" *ngIf="!loading.tickets"></app-pager>
      <app-loader *ngIf="loading.tickets"></app-loader>
    </div>
  </div>

  <ng-template #closeTicketModal>
    <div class="modal-header">
      <h3 i18n>Zatvaranje upita</h3>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p i18n>Sigurno želiš zatvoriti upit?</p>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button type="button" class="btn rounded-light" aria-label="Close" (click)="decline()" i18n>Odustani</button>
      <button type="button" class="btn rounded-light btn-green" aria-label="Close" (click)="confirm(selectedItem)" i18n>Da</button>
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="template === 'last'">
  <div *ngIf="tickets?.length">
    <div *ngFor="let ticket of tickets">
      <a routerLink="/support/tickets/{{ticket.tid}}" [queryParams]="{c: ticket.c}" class="block mb-m"><p>#{{ ticket.tid }}</p><div class="fsmaller text-dark">{{ ticket.subject | slice: 0: 60 }} <span *ngIf="ticket.subject.length > 60">...</span> </div></a>
      <div class="d-flex justify-content-between">
        <span i18n>Status:{{ ticket.status }}</span>
        <span>{{ ticket.lastreply | date:'short' }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="!tickets?.length" i18n>Nema nedavnih upita. Ukoliko trebaš pomoć, odgovor potraži u bazi znanja ili nam pošalji upit.</div>
  <app-loader *ngIf="outsideLoading"></app-loader>
</ng-container>
