import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {APP_CONFIG, AppConfig} from '../app-config.module';
import {HandleError, HttpErrorHandler} from './http-error-handler.service';
import {Client} from '../_models';
import {Affiliate} from '../_models/affiliate';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.handleError = httpErrorHandler.createHandleError('ClientService');
  }

  authenticateUser(email: string, password: string): Observable<HttpResponse<Client | any>> {
    // uncomment this on real data
    return this.http.post(`${this.config.apiEndpoint}/user/authenticate`, {
      email, password
    }, httpOptions)
      .pipe(
        catchError(this.handleError('authenticateUser', null))
      );
  }

  /**
   * Returns currently logged in user info
   */
  getCurrentUserInfo(): Observable<HttpResponse<Client | any>> {
    return this.http.get(`${this.config.apiEndpoint}/user/user-info`, httpOptions)
      .pipe(
        catchError(this.handleError('GetUserInfo', null))
      );
  }

  /**
   * Updates current user language and returns refreshed user info
   * @param language string
   */
  updateUserLanguage(language: string): Observable<Client | any> {
    const data = {lang: language};
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/set-default-interface-language`, data, httpOptions)
      .pipe(
        catchError(this.handleError('updateUserLanguage', null))
      );
  }


  /**
   * Sends auth request with nonce to API.
   * @param nonce string
   */
  authenticateUserByNonce(nonce: string): Observable<HttpResponse<Client | any>> {
    return this.http.post(`${this.config.apiEndpoint}/user/authenticate`, {
      nonce
    }, httpOptions)
      .pipe(
        catchError(this.handleError('authenticateUser', null))
      );
  }

  activateUser(nonce: string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<{} | any>>(`${this.config.apiEndpoint}/user/activate`, {
      nonce
    }, httpOptions)
      .pipe(
        map(res => {
          return (res && res.body !== undefined) ? res.body : null;
        }),
        catchError(this.handleError('activateUser', null))
      );
  }

  authenticateGoogleUser(nonce: string, code: string): Observable<HttpResponse<Client | any>> {
    return this.http.post(`${this.config.apiEndpoint}/user/authenticate/google`, {
      nonce, code
    }, httpOptions)
      .pipe(
        catchError(this.handleError('authenticateGoogleUser', null))
      );
  }

  getAffiliates(userId: number): Observable<Affiliate> {
    const data = {
      action: 'GetAffiliateDetails',
      cache: true
    };
    return this.http.post<HttpResponse<{} | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          const affiliates = res.body.data;
          if (typeof affiliates.affiliatelinkscode !== 'undefined') {
            const codes: string[] = [];
            affiliates.affiliatelinkscode.forEach(link => {
              codes.push(link.replace('___AFFILIATELINK___', `${this.config.appRoot}/home?aff=${affiliates.affiliateid}`));
            });
            affiliates.affiliatelinkscode = codes;
          }
          if (typeof affiliates.referrals !== 'undefined') {
            affiliates.referrals.forEach(referral => {
              referral.date = new Date(referral.datets * 1000);
            });
          }

          return affiliates;
        }),
        catchError(this.handleError('getAffiliates', null))
      );
  }

  activateAffiliate(): Observable<HttpResponse<any>> {
    const data = {
      action: 'AffiliateActivate'
    };

    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          return res.body;
        }),
        catchError(this.handleError('activateAffiliate', null))
      );
  }

  logoutUser(): Observable<HttpResponse<boolean>> {
    return this.http.post(`${this.config.apiEndpoint}/user/logout`, null, httpOptions)
      .pipe(
        catchError(this.handleError('logout', null))
      );
  }

  register(user: Client): Observable<{ status: boolean, message: string }> {
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/register`, user, httpOptions)
      .pipe(
        map((res) => {
          return res.body;
        }),
        catchError(this.handleError('register', null))
      );
  }

  update(user: Client): Observable<HttpResponse<boolean>> {
    user.action = 'UpdateClient';

    let phone = null;
    const tmpPhone = user.telephoneNumber.internationalNumber.split(' ');
    if (tmpPhone.length > 1) {
      phone = tmpPhone[0] + '.';
      tmpPhone.splice(0, 1);
      tmpPhone.forEach(num => {
        phone += num;
      });
    }
    user.phonenumber = phone;

    return this.http.post<Client>(`${this.config.apiEndpoint}/user/request`, user, httpOptions)
      .pipe(
        catchError(this.handleError('update', null))
      );
  }

  passReset(data: any): Observable<HttpResponse<any>> {
    return this.http.post(`${this.config.apiEndpoint}/user/password-reset`, data, httpOptions)
      .pipe(
        catchError(this.handleError('passReset', null))
      );
  }
}
