import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {HandleError, HttpErrorHandler} from '../../_services';
import {Email} from './email';
import {Pager} from '../tickets/pager';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.handleError = httpErrorHandler.createHandleError('EmailService');
  }

  getList(filterData: {}, pageSize: number | 10): Observable<{
    pager: Pager;
    emails: Email[];
  }> {
    const keyPage = 'page';
    const page = filterData.hasOwnProperty(keyPage) ? parseInt(filterData[keyPage], 10) : 1;

    const startIndex = (page === 1) ? 0 : (pageSize * (page - 1));

    const data = {
      action: 'GetEmails',
      limitstart: startIndex,
      limitnum: pageSize
    };
    return this.http.post<HttpResponse<Email[] | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          return (res.body.emails.email !== undefined) ? {
            pager: {
              pages: [...Array(Math.ceil(res.body.totalresults / pageSize)).keys()].map(i => (i + 1)),
              currentPage: page,
              totalPages: Math.ceil(res.body.totalresults / pageSize),
              pageSize
            },
            emails: res.body.emails.email
          } : {
            pager: {
              pages: [],
              currentPage: page,
              totalPages: 0,
              pageSize
            },
            emails: []
          };
        }),
        catchError(this.handleError('getList', {
          pager: {
            pages: [],
            currentPage: page,
            totalPages: 0,
            pageSize
          }, emails: []
        }))
      );
  }
}
