import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {of, Subscription} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';

import {CartService} from '../../cart.service';
import {AlertService} from '../../../_services';
import {ProductService} from '../../../_services/product.service';
import {CartOrderItem} from '../../cart-order-item';
import {ProductGroup} from '../../../_models/product-group';

@Component({
  selector: 'app-cart-step-options',
  templateUrl: './step-options.component.html'
})
export class StepOptionsComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();
  productGroups: ProductGroup[];
  linkedTags: string[] = [];
  loading = {
    groups: false
  };
  cid: number;
  hasAdditional = false;

  constructor(
    private cartService: CartService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected alert: AlertService,
    private productService: ProductService
  ) {
    const routerQidSub = this.route.params
      .pipe(switchMap(params => {
        this.cid = (typeof params.cartId !== 'undefined') ? parseInt(params.cartId, 10) : null;
        if (this.cid === null) {
          this.alert.error($localize`Odaberi stavku za dodatne opcije`);
          return this.router.navigate(['/cart']);
        }
        return of(this.cartService.getCartItemByCartId(this.cid));
      }))
      .subscribe((cartItem: CartOrderItem) => {
        if (!cartItem) {
          this.alert.error($localize`Odaberi stavku za dodatne opcije`);
          return this.router.navigate(['/cart']);
        }
        this.loading.groups = true;
        const groupSub = this.productService.getProductGroups()
          .pipe(take(1))
          .subscribe(groups => {
            this.loading.groups = false;
            this.productGroups = groups;
            const group = this.productService.getGroupByGroupId(cartItem.gid);
            this.linkedTags = group.linkedTags;
          });
        this.subscription.add(groupSub);
      });
    this.subscription.add(routerQidSub);
  }

  getGroupName(tag: string) {
    return this.productService.getGroupNameByTag(tag);
  }

  getGroupId(tag: string) {
     const group = this.productService.getGroupByTag(tag);
     return group.id;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
