import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard, RoleGuard} from '../_guards';
import {SupportComponent} from './support.component';
import {TicketNewComponent} from './tickets/new';
import {TicketListComponent} from './tickets/list';
import {TicketViewComponent} from './tickets/view';
import {AnnouncementListComponent} from './announcements/list';
import {AnnouncementViewComponent} from './announcements/view';
import {ServerStatusComponent} from './server-status';
import {EmailListComponent} from './emails/list';

const supportRoutes: Routes = [
    {
        path: '',
        component: SupportComponent,
        children: [
            { path: '', redirectTo: '/support/tickets', pathMatch: 'full' },
            { path: 'emails', component: EmailListComponent },
            { path: 'ticket/new', component: TicketNewComponent, data: {contentbg : 'bg-grey'}},
            { path: 'tickets', component: TicketListComponent },
            { path: 'tickets/:id',  pathMatch: 'full', component: TicketViewComponent },
            { path: 'announcements', component: AnnouncementListComponent },
            { path: 'announcement/:id', component: AnnouncementViewComponent },
            { path: 'server-status', component: ServerStatusComponent }
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: { animation: 'support', contentbg: 'bg-grey'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(supportRoutes)],
    exports: [RouterModule]
})
export class SupportRoutingModule { }
