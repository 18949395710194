import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpCacheService {

  private requests: any = { };

  constructor() { }

  put(url: string, response: HttpResponse<any>): void {
    this.requests[url] = response;
  }

  get(url: string): HttpResponse<any> | undefined {
    return this.requests[url];
  }

  /**
   * invalidate all cache if no params provided
   * @param url - delete only specific action cache
   * @param keysToInvalidate - array of indexOf keys to delete + action key
   */
  invalidateCache(url: string = null, keysToInvalidate: Array<string> = []): void {
    if (url !== null) {
      let requestKeystoDelete = [];
      const requestKeys = Object.getOwnPropertyNames(this.requests);
      keysToInvalidate.push(url);

      keysToInvalidate.forEach(kToDelete => {
        const found = requestKeys.filter(key => key.indexOf(kToDelete) >= 0);
        if (found) {
          requestKeystoDelete = requestKeystoDelete.concat(found);
        }
      });

      for (const tkey in this.requests) {
        if (this.requests.hasOwnProperty(tkey) && requestKeystoDelete.indexOf(tkey) >= 0) {
          delete this.requests[tkey];
        }
      }
    } else {
      this.requests = { };
    }
  }
}
