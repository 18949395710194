<div class="d-flex justify-content-between bd-bottom p-3 bg-white">
  <app-loader *ngIf="loading"></app-loader>
  <div class="d-flex align-items-center mb-3 mb-md-0" *ngIf="ticket">
    <a routerLink="/support/tickets" tooltip="Na popis svih ticketa" i18n-tooltip="Na popis svih ticketa" class="mr-3" *ngIf="!printService.isPrinting">&#8592;</a>
    <div>
      <h5 class="color-dark-blue" i18n>{{ticket.subject}}</h5>
      <p class="color-dark-blue"><span i18n>Odjel:</span> {{ticket.deptname[user.language]}} <span *ngIf="replyAdmin">({{replyAdmin}})</span></p>
    </div>
  </div>

  <div>
    <button class="btn btn-bordered rounded-light btn-color2 btn-sm" *ngIf="ticket && !printService.isPrinting" (click)="printTicket()" i18n>Ispis</button>
  </div>
</div>

<div class="p-3 bg-grey">
  <div class="bg-white rounded-light shadow" *ngIf="ticket" [ngClass]="{'hidden-reply': hiddenReplies && !printService.isPrinting}">
    <app-ticket-reply *ngFor="let reply of ticket.replies; let index = index; let first = first; let last = last;"
                      [first]="first"
                      [reply]="reply"
                      [hiddenNumber]="getHiddenNb()"
                      [last]="last"
                      [print]="printService.isPrinting"
                      [(hide)]="!hiddenReplies">
    </app-ticket-reply>
    <div class="text-center p-3" *ngIf="!reply && !printService.isPrinting">
      <button class="btn rounded-light btn-bordered mr-4" (click)="reply = !reply"><svg-icon src="assets/svg/replay.svg" class="icon icon-15 mr-3"></svg-icon><span i18n>Odgovori</span></button>
      <button class="btn btn-primary rounded-light" (click)="closeTicket(closeTicketModal)" *ngIf="ticket.status !== 'Closed'"><svg-icon src="assets/svg/kvacica.svg" class="icon icon-15 mr-3"></svg-icon><span i18n>Zatvori upit</span></button>
    </div>

    <ng-container *ngIf="reply && !printService.isPrinting">
      <hr>
      <app-ticket-new [(reply)]="reply" [ticket]="ticket"></app-ticket-new>
    </ng-container>
  </div>

  <app-loader *ngIf="loading"></app-loader>
</div>

<ng-template #closeTicketModal>
  <div class="modal-body">
    <h3 i18n>Zatvaranje upita</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="declineClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p i18n>Sigurno želiš zatvoriti upit?</p>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn rounded-light" aria-label="Close" (click)="declineClose()" i18n>Odustani</button>
    <button type="button" class="btn rounded-light btn-danger" aria-label="Close" (click)="confirmClose()" i18n>Da</button>
  </div>
</ng-template>
