import {Component} from '@angular/core';
import {Client} from '../_models';
import {AuthenticationService} from '../_services';
import {Router} from '@angular/router';

@Component({
  templateUrl: './accounting.component.html'
})
export class AccountingComponent {
  user: Client;

  constructor(
    private authenticationService: AuthenticationService,
    public router: Router
  ) {
    this.user = this.authenticationService.currentUserValue;
  }
}
