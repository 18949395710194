import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders
} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(
    @Inject(LOCALE_ID) protected localeId: string
  ) {
  }

  private addLanguageHeader(headers: HttpHeaders): HttpHeaders {
    headers = headers.append('language', this.localeId);
    return headers;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //return next.handle(request);
    const apiRequest = request.clone({
      headers: this.addLanguageHeader(request.headers)
    });
    return next.handle(apiRequest);
  }
}
