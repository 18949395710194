<div class="d-flex justify-content-between align-items-center bd-bottom p-3 bg-white flex-wrap">
  <div class="mb-3 mb-sm-0">
    <h3 class="font-weight-bold mb-2 mb-lg-0 text-color-2" i18n *ngIf="register">Registracija korisnika</h3>
    <h3 class="font-weight-bold mb-2 mb-lg-0 text-color-2" i18n *ngIf="!register && title">Uređivanje korisnika {{title}}</h3>
    <h3 class="font-weight-bold mb-2 mb-lg-0 text-color-2" i18n *ngIf="!register && !title">Detalji korisnika: {{user.firstname}} {{user.lastname}}</h3>
  </div>
  <div>
    <a routerLink="../edit" class="btn rounded-light btn-outline-dark mr-3" *ngIf="!edit && !register" i18n>Uredi</a>
  </div>
</div>

<div [formGroup]="parentForm" class="p-3">
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label for="firstname" i18n>Ime</label>
      <input *ngIf="!edit" type="text" class="form-control" [value]="user?.firstname" [disabled]="true" />
      <div *ngIf="edit" [ngClass]="{ 'col-sm-10': !edit}">
        <input *ngIf="edit" id="firstname" type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
        <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback w-100">
          <div *ngIf="f.firstname.errors.required" i18n>Ime je obavezno</div>
        </div>
      </div>
    </div>
    <div class="form-group col-sm-6">
      <label for="lastname" i18n>Prezime</label>
      <input *ngIf="!edit" type="text" class="form-control" [value]="user?.lastname" [disabled]="true" />
      <div *ngIf="edit" [ngClass]="{ 'col-sm-10': !edit}">
        <input id="lastname" type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" />
        <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback w-100">
          <div *ngIf="f.lastname.errors.required" i18n>Prezime je obavezno</div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-form-label mr-4 col-sm-1" i18n>Telefonski broj</label>
    <ngx-intl-tel-input *ngIf="currentCountry"
                        [cssClass]="telInvalidPhone()"
                        [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="currentCountryPhone.code"
                        [maxLength]="'15'"
                        [phoneValidation]="false"
                        formControlName="telephoneNumber"></ngx-intl-tel-input>
    <div *ngIf="submitted && f.telephoneNumber.errors" class="invalid-feedback w-100">
      <div *ngIf="f.telephoneNumber.errors.required" i18n>Tel is required</div>
    </div>
  </div>

  <hr>
  <div class="form-group row">
    <div class="col">
      <div class="form-check">
        <input id="persontype_private" type="radio" name="person_type" [disabled]="!edit" [checked]="personType === 'private'" class="form-check-input" (click)="setClientPersonType('private')" />
        <label for="persontype_private" class="form-check-label" i18n>Fizička osoba</label>
      </div>
    </div>
    <div class="col">
      <div class="form-check">
        <input id="persontype_business" type="radio" name="person_type" [disabled]="!edit" [checked]="personType === 'business'" class="form-check-input" (click)="setClientPersonType('business')" />
        <label for="persontype_business" class="form-check-label" i18n>Pravna osoba</label>
      </div>
    </div>
  </div>
  <hr>

  <div class="form-group row">
    <div class="col-12 col-sm-6">
      <div class="form-group">
        <label for="tax_id" class="col-form-label" *ngIf="personType === 'business'" i18n>OIB tvrtke</label>
        <label for="tax_id" class="col-form-label" *ngIf="personType === 'private'" i18n>OIB</label>
        <input *ngIf="!edit" type="text" class="form-control" [value]="user?.tax_id" [disabled]="true" />
        <input *ngIf="edit" id="tax_id" type="text" formControlName="tax_id" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.tax_id.errors }" />
        <ng-container *ngIf="edit">
          <div *ngIf="submitted && f.tax_id.errors" class="invalid-feedback w-100">
            <div *ngIf="f.tax_id.errors.required" i18n>OIB je obavezno polje</div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-12 col-sm-6" *ngIf="personType === 'business'">
      <div class="form-group" [ngClass]="{ 'row': !edit}">
        <label for="companyname" class="col-form-label" i18n>Naziv tvrtke</label>
        <input *ngIf="!edit" type="text" class="form-control" [disabled]="true" [value]="user?.companyname" />
        <input *ngIf="edit" id="companyname" type="text" formControlName="companyname" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.companyname.errors }" />
        <ng-container *ngIf="edit">
          <div *ngIf="submitted && f.companyname.errors" class="invalid-feedback w-100">
            <div *ngIf="f.companyname.errors.required" i18n>Naziv tvrtke je obavezno polje</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label for="address1" class="col-form-label" i18n>Adresa</label>
    <input *ngIf="!edit" type="text" class="form-control" [disabled]="true" [value]="user?.address1" />
    <input *ngIf="edit" id="address1" type="text" formControlName="address1" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" />
    <ng-container *ngIf="edit">
      <div *ngIf="submitted && f.address1.errors" class="invalid-feedback w-100">
        <div *ngIf="f.address1.errors.required" i18n>Adresa je obavezno polje</div>
      </div>
    </ng-container>
  </div>

  <div class="form-group">
    <label for="address2" class="col-form-label" i18n>Adresa 2</label>
    <input *ngIf="!edit" type="text" class="form-control" [disabled]="true" [value]="user?.address2" />
    <input *ngIf="edit" id="address2" type="text" formControlName="address2" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" />
    <ng-container *ngIf="edit">
      <div *ngIf="submitted && f.address2.errors" class="invalid-feedback w-100">
        <div *ngIf="f.address2.errors.required" i18n>Adresa 2 je obavezno polje</div>
      </div>
    </ng-container>
  </div>

  <div class="row">
    <div class="form-group col-md-4">
      <label for="city" class="col-form-label" i18n>Grad</label>
      <input *ngIf="!edit" type="text" class="form-control" [disabled]="true" [value]="user?.city" />
      <input *ngIf="edit" id="city" type="text" formControlName="city" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
      <ng-container *ngIf="edit">
        <div *ngIf="submitted && f.city.errors" class="invalid-feedback w-100">
          <div *ngIf="f.city.errors.required" i18n>Grad je obavezno polje</div>
        </div>
      </ng-container>
    </div>
    <div class="form-group col-md-4">
      <label for="state" class="col-form-label" i18n>Županija</label>
      <input *ngIf="!edit" type="text" class="form-control" [disabled]="true" [value]="user?.state" />
      <input *ngIf="edit" id="state" type="text" formControlName="state" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.state.errors }" />
      <ng-container *ngIf="edit">
        <div *ngIf="submitted && f.state.errors" class="invalid-feedback w-100">
          <div *ngIf="f.state.errors.required" i18n>Županija je obavezno polje</div>
        </div>
      </ng-container>
    </div>
    <div class="form-group col-md-4">
      <label for="postcode" class="col-form-label" i18n>Poštanski broj</label>
      <input *ngIf="!edit" type="text" class="form-control" [disabled]="true" [value]="user?.postcode" />
      <input *ngIf="edit" id="postcode" type="text" formControlName="postcode" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.postcode.errors }" />
      <ng-container *ngIf="edit">
        <div *ngIf="submitted && f.postcode.errors" class="invalid-feedback w-100">
          <div *ngIf="f.postcode.errors.required" i18n>Poštanski broj je obavezno polje</div>
          <div *ngIf="f.postcode.errors.minlength" i18n>Minimalno 2 broja</div>
          <div *ngIf="f.postcode.errors.invalidNumber" i18n>Samo brojevi</div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-6">
      <label class="col-form-label" i18n>Država</label>
      <input *ngIf="!edit" type="text" class="form-control" [disabled]="true" [value]="currentCountry?.name" />
      <div *ngIf="currentCountry && edit">
        <ng-select [items]="countries"
                   formControlName="country"
                   bindValue="code"
                   bindLabel="name"
                   i18n-placeholder="Odaberi zemlju"
                   placeholder="Odaberi zemlju">
        </ng-select>
      </div>
    </div>

    <div class="form-group col-md-6">
      <label class="col-form-label" i18n>Jezik</label>
      <input *ngIf="!edit" type="text" class="form-control" [disabled]="true" [value]="currentLanguage?.name" />
      <ng-select *ngIf="edit" [items]="languages"
                 formControlName="language"
                 bindValue="code"
                 bindLabel="name"
                 placeholder="Select language">
      </ng-select>
      <ng-container *ngIf="edit">
        <div *ngIf="submitted && f.language.errors" class="invalid-feedback w-100">
          <div *ngIf="f.language.errors.required" i18n>Jezik je obavezno polje</div>
        </div>
      </ng-container>
    </div>
  </div>

  <hr>

  <div class="form-check">
    <input
      *ngIf="!edit"
      id="marketingoptin"
      type="checkbox"
      [disabled]="true"
      [checked]="user?.marketingoptin"
      class="form-check-input"
    />
    <input *ngIf="edit" id="marketingoptin" type="checkbox" [checked]="user?.marketingoptin" formControlName="marketingoptin" class="form-check-input" [disabled]="!edit" />
    <label for="marketingoptin" class="form-check-label" i18n>Dozvoljavam primanje MyDataKnox marketinških poruka</label>
  </div>
  
  <hr>

  <div class="form-check">
    <input
      *ngIf="!edit"
      id="twofa"
      type="checkbox"
      aria-describedby="twofa-desc"
      [disabled]="true"
      [checked]="user?.twofa"
      class="form-check-input"
    />
    <input
      *ngIf="edit"
      id="twofa"
      type="checkbox"
      aria-describedby="twofa-desc"
      formControlName="twofa"
      class="form-check-input"
      [ngClass]="{ 'is-invalid': submitted && f.twofa.errors }"
    />
    <label for="twofa" class="form-check-label" i18n>
      Google dvofaktorska autentikacija
    </label>
    <p id="twofa-desc" class="fsmaller">
      <span i18n class="block">
        Nakon uključivanja ove opcije, molimo Vas da se odjavite i ponovno prijavite kako biste
        povezali svoj kod s Google Autentifikatora.
      </span>
    </p>
  </div>

  <hr />

  <div class="form-group">
    <label for="email" i18n>E-mail:</label>
    <input *ngIf="!edit" type="text" class="form-control" [disabled]="true" [value]="user?.email" />
    <input *ngIf="edit" id="email" type="text" formControlName="email" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
    <ng-container *ngIf="edit">
      <div *ngIf="submitted && f.email.errors" class="invalid-feedback w-100">
        <div *ngIf="f.email.errors.required" i18n>E-mail je obavezno polje</div>
      </div>
    </ng-container>
  </div>
</div>
