import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {faEdit, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';

import {CartOrderItem} from '../../cart-order-item';
import {Currency} from '../../../_models/currency';
import {CartService} from '../../cart.service';
import {OrderService} from '../../order.service';
import {ProductDomainItem} from '../../product-domain-item';
import {Product} from '../../../_models/product';
import {Promotion} from '../../promotion';
import {ProductService} from '../../../_services/product.service';
import {AlertService, AuthenticationService} from '../../../_services';
import {Client} from '../../../_models';
import {APP_CONFIG, AppConfig} from "../../../app-config.module";

@Component({
  selector: 'app-step-overview',
  templateUrl: './step-overview.component.html'
})
export class StepOverviewComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() product: string;
  @Input() redirectWithoutUser = false;
  @Input() filter: { gId: number };

  faSpinner = faSpinner;
  faEdit = faEdit;
  faTrashAlt = faTrashAlt;

  user?: Client;
  orderForm: FormGroup;
  orderItems: CartOrderItem[];
  domainItems: ProductDomainItem[];
  cartCurrency: Currency;
  products: Product[] = [];
  promotion: Promotion;
  submitted = false;
  loading = {
    code: false
  };
  public displayHrk = false;

  constructor(
    private router: Router,
    private cartService: CartService,
    private orderService: OrderService,
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private alert: AlertService,
    private auth: AuthenticationService,
    @Inject(APP_CONFIG) public config: AppConfig
  ) {
    this.orderForm = this.formBuilder.group({
      currency: null,
      coupon: null,
      affId: null,
      total: null,
      totalDiscount: null,
      totalTax: 0.0,
      totalWithTax: 0.0,
      tos: [null, Validators.required]
    });
    this.subscription.add(this.cartService.getCartCurrency()
      .subscribe(cartCurrency => {
        this.cartCurrency = cartCurrency;
        this.orderForm.patchValue({currency: cartCurrency.code});
      }));

    this.orderForm.patchValue({affId: this.cartService.getAff()});
  }

  get f() {
    return this.orderForm.controls;
  }

  ngOnInit() {
    this.displayHrk = this.config.displayHrk;

    this.subscription.add(this.auth.currentUser
      .subscribe(user => {
        if (!user && this.redirectWithoutUser) {
          this.router.navigate(['/cart']);
        } else if (user) {
          this.user = user;
        }
      }));

    this.subscription.add(
      this.cartService.orderItems$
        .subscribe((orderItems) => {
          if (this.filter !== undefined) {
            orderItems = (this.filter.gId !== undefined && orderItems !== null) ?
              orderItems.filter(x => x.gid === this.filter.gId) : orderItems;
          }
          this.orderItems = orderItems;
        })
    );

    if (this.filter === undefined) {
      this.subscription.add(
        this.cartService.domainItems$
          .subscribe((domainItems) => {
            this.domainItems = domainItems;
          })
      );
      if ((!this.orderItems || this.orderItems.length === 0) && (!this.domainItems || this.domainItems.length === 0)) {
        return this.router.navigate(['/cart']);
      }
    }

    this.subscription.add(
      this.cartService.promotion$
        .subscribe(promotion => {
          if (promotion) {
            this.promotion = promotion;
            this.orderForm.patchValue({coupon: promotion.code});
          }
        })
    );

    this.subscription.add(
      this.productService.getProducts(null, null, null, this.cartCurrency.code)
        .subscribe(prods => {
          this.products = prods;
          this.checkIfProductHasRequiredDomain();
        })
    );

    this.updateCartPrice();
  }

  updateCartPrice() {
    this.cartService.loadPromotion();
    this.orderForm.patchValue({total: this.cartService.getTotalCart()});
    this.orderForm.patchValue({totalDiscount: this.cartService.getTotalCart(true)});
    this.orderForm.patchValue({totalTax: this.cartService.getTotalCartTax(this.user)});
    this.orderForm.patchValue({totalWithTax: Math.round(((this.cartService.getTotalCart() + this.cartService.getTotalCartTax(this.user)) + Number.EPSILON)*100)/100});
  }

  removeDomain(domain: ProductDomainItem) {
    this.cartService.removeDomain(domain);
    this.updateCartPrice();
  }

  checkCoupon() {
    this.loading.code = true;
    const promotionSub = this.orderService.getPromotion(this.orderForm.value.coupon)
      .subscribe(promotion => {
        this.loading.code = false;
        this.promotion = promotion;
        if (promotion) {
          const promoStatus: {
            applicable: boolean,
            requiredProductIds: any[]
          } = this.cartService.applyPromotion(promotion);
          if (!promoStatus.applicable) {
            this.alert.error($localize`Promocijski kod nije primjenjiv na trenutne usluge`);
          }
          if (promoStatus.requiredProductIds.length > 0) {
            const invalidProductDomains = promoStatus.requiredProductIds.filter(x => isNaN(x));
            let missingProductNames: any[] = [];
            this.subscription.add(
              this.productService.getProducts(null, null, null, this.cartCurrency.code)
                .subscribe(prods => {
                  const filteredProducts = (prods && prods.length > 0) ?
                    prods.filter(x =>  promoStatus.requiredProductIds.indexOf(x.pid) >= 0)
                    : [];
                  missingProductNames = [...filteredProducts, ...invalidProductDomains];
                  if (missingProductNames.length > 0) {
                    let message = 'Missing products:<br/>';
                    missingProductNames.forEach(x => {
                      message += (isNaN(x) && typeof x !== 'object') ? `Domena ${x}<br/>` : `${this.productService.getName(x)}<br/>`;
                    });
                    this.alert.info(message, true, null, {
                      enableHtml: true
                    });
                  }
                })
            );
          }
          this.orderForm.patchValue({coupon: promotion.code});
          this.updateCartPrice();
        }
      });
    this.subscription.add(promotionSub);
  }

  removeOrderItem(orderItem: CartOrderItem) {
    this.cartService.removeOrderItem(orderItem);
    this.updateCartPrice();
  }

  addQuantity(orderItem) {
    this.setQuantity(orderItem, ++orderItem.quantity);
  }

  removeQuantity(orderItem) {
    this.setQuantity(orderItem, --orderItem.quantity);
  }

  setQuantity(orderItem: CartOrderItem, quantity: number) {
    quantity = (quantity < 1) ? 1 : parseInt(String(quantity), 10);
    orderItem.quantity = quantity;
    this.cartService.addOrderItem(orderItem);
    this.updateCartPrice();
  }

  createOrder() {
    this.submitted = true;
    if (this.orderForm.invalid || !this.checkIfProductHasRequiredDomain()) {
      return false;
    }
    if (!this.user || this.user === undefined) {
      return this.router.navigate([`/cart/user`]);
    }
    this.orderService.saveOrder(this.orderForm.value);
    this.router.navigate([`/cart/payment`]);
  }

  getTosLink() {
    return `${this.config.tos}`;
  }

  getSupportLink() {
    return `${this.config.appRoot}/support/ticket/new`;
  }

  private checkIfProductHasRequiredDomain(): boolean {
    let isValid = true;

    this.orderItems?.forEach((orderItem) => {
      const product = this.products.find((x) => x.pid === orderItem.pid);

      // If showdomainoptions is 1, then the domain is required
      if (!product || (product.showdomainoptions === 1 && !orderItem.domain)) {
        isValid = false;
        orderItem.errors = ["noDomainChosen"];
      } else {
        orderItem.errors = [];
      }
    });

    return isValid;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
