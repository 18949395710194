import {Component, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {faEye, faEyeSlash} from '@fortawesome/free-regular-svg-icons';

import {AlertService, AuthenticationService} from '../_services';
import {APP_CONFIG, AppConfig} from '../app-config.module';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loginFAForm: FormGroup;
  private subscription: Subscription = new Subscription();
  loading = false;
  submitted = false;
  fAsubmitted = false;
  returnUrl: string;
  qrLink = null;
  nonce = null;
  shown = false;
  showUserData = false;

  faEye = faEye;
  faEyeSlash = faEyeSlash;

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    @Inject(APP_CONFIG) private config: AppConfig,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public authenticationService: AuthenticationService,
    private alertService: AlertService,
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      // Do not redirect if it is a cart component
      if (this.router.url.indexOf('/cart') <= -1) {
        this.router.navigate(['/']);
      }

      // If it's a cart, just show some standard data or proceed to next step
      this.showUserData = true;
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
      remember: ['']
    });

    this.loginFAForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.pattern('[0-9]{6}')]],
      remember: [''],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get fg() {
    return this.loginFAForm.controls;
  }

  viewInput() {
    this.shown = !this.shown;
  }

  submit2FA() {
    this.fAsubmitted = true;
    // stop here if form is invalid
    if (this.loginFAForm.invalid) {
      return;
    }

    this.loading = true;
    this.subscription.add(
      this.authenticationService.loginGoogle(this.nonce, this.fg.code.value)
        .pipe(first())
        .subscribe(
          user => {
            this.loading = false;
            if (user !== null) {
              if (user.nonce === undefined) {
                this.router.navigate([this.returnUrl]);
              } else {
                this.nonce = user.nonce;
                this.alertService.error(user.message);
              }
            } else {
              this.qrLink = null;
              this.nonce = null;
            }
          },
          error => {
            this.alertService.error(error);
            this.loading = false;
            this.qrLink = null;
            this.nonce = null;
          })
    );
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.subscription.add(
      this.authenticationService.login(this.f.email.value, this.f.password.value, this.f.remember.value)
        .pipe(first())
        .subscribe(
          user => {
            this.loading = false;
            if (user === null) {
              return;
            }
            user.language = user.language === undefined || user.language.toLowerCase() === 'croatian' ?
              'hr' :'en'; // TODO: set for alla available languages later on
            if (user.language !== this.localeId) {
              const urlToRedirect = `${this.config.appRoot}/${user.language}${this.returnUrl}`;
              window.location.href = urlToRedirect;
            }
            if (user && user.qrlink === undefined) {
              if (this.router.url.indexOf('/cart') <= -1) {
                this.router.navigate(['']);
              }
              this.showUserData = true;
              this.router.navigate([this.returnUrl]);
            } else if (user) {
              this.qrLink = user.qrlink;
              this.nonce = user.nonce;

              // If qrLink is already set, check the session storage in case user needs to re-scan QR code
              if (this.qrLink === 'token_already_set') {
                const sessionQrLink = this.authenticationService.getStorage(AuthenticationService.twoFaLinkKey, 'session');
                if (sessionQrLink !== null) {
                  this.qrLink = sessionQrLink;
                }
              }
            }
          },
          error => {
            this.alertService.error(error);
            this.loading = false;
          })
    );
  }

  public cancel2FA(): void {
    this.qrLink = null;
    this.nonce = null;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
