import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {Contact} from '../contact';

@Component({
  selector: 'app-contact-permissions',
  templateUrl: 'contact-permissions.component.html'
})
export class ContactPermissionsComponent implements OnInit{
  @Input() parentForm: FormGroup;
  @Input() permissionsForm: FormGroup;
  @Input() source: string;
  @Input() user: Contact;
  @Input() edit = false;

  selectAllInputs = false;

  constructor(
    private formBuilder: FormBuilder
  ) {}

  get f() {
    return this.permissionsForm.controls;
  }

  ngOnInit(): void {
    this.permissionsForm = this.formBuilder.group({
      profile: [(typeof this.user.permissions !== 'undefined' && this.user.permissions.indexOf('profile') >= 0)],
      contacts: [(typeof this.user.permissions !== 'undefined' && this.user.permissions.indexOf('contacts') >= 0)],
      products: [(typeof this.user.permissions !== 'undefined' && this.user.permissions.indexOf('products') >= 0)],
      manageproducts: [(typeof this.user.permissions !== 'undefined' && this.user.permissions.indexOf('manageproducts') >= 0)],
      productsso: [(typeof this.user.permissions !== 'undefined' && this.user.permissions.indexOf('productsso') >= 0)],
      domains: [(typeof this.user.permissions !== 'undefined' && this.user.permissions.indexOf('domains') >= 0)],
      managedomains: [(typeof this.user.permissions !== 'undefined' && this.user.permissions.indexOf('managedomains') >= 0)],
      invoices: [(typeof this.user.permissions !== 'undefined' && this.user.permissions.indexOf('invoices') >= 0)],
      quotes: [(typeof this.user.permissions !== 'undefined' && this.user.permissions.indexOf('quotes') >= 0)],
      tickets: [(typeof this.user.permissions !== 'undefined' && this.user.permissions.indexOf('tickets') >= 0)],
      affiliates: [(typeof this.user.permissions !== 'undefined' && this.user.permissions.indexOf('affiliates') >= 0)],
      emails: [(typeof this.user.permissions !== 'undefined' && this.user.permissions.indexOf('emails') >= 0)],
      orders: [(typeof this.user.permissions !== 'undefined' && this.user.permissions.indexOf('orders') >= 0)]
    });

    this.parentForm.addControl('permissions', this.permissionsForm);
  }

  selectAll() {
    this.selectAllInputs = !this.selectAllInputs;
    this.permissionsForm.patchValue({profile: this.selectAllInputs});
    this.permissionsForm.patchValue({contacts: this.selectAllInputs});
    this.permissionsForm.patchValue({products: this.selectAllInputs});
    this.permissionsForm.patchValue({manageproducts: this.selectAllInputs});
    this.permissionsForm.patchValue({productsso: this.selectAllInputs});
    this.permissionsForm.patchValue({domains: this.selectAllInputs});
    this.permissionsForm.patchValue({managedomains: this.selectAllInputs});
    this.permissionsForm.patchValue({invoices: this.selectAllInputs});
    this.permissionsForm.patchValue({quotes: this.selectAllInputs});
    this.permissionsForm.patchValue({tickets: this.selectAllInputs});
    this.permissionsForm.patchValue({affiliates: this.selectAllInputs});
    this.permissionsForm.patchValue({emails: this.selectAllInputs});
    this.permissionsForm.patchValue({orders: this.selectAllInputs});
  }
}
