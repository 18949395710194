import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {AngularSvgIconModule} from 'angular-svg-icon';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {LoginComponent} from './login.component';
import {LoaderModule} from '../loader/loader.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    LoginComponent
  ],
  exports: [
    LoginComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        LoaderModule,
        RouterModule,
        AngularSvgIconModule,
        TooltipModule,
        FontAwesomeModule
    ]
})
export class LoginModule { }
