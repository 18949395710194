import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {HandleError, HttpErrorHandler} from '../../_services';
import {Contact} from './contact';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.handleError = httpErrorHandler.createHandleError('ContactService');
  }

  /**
   * GET contacts from the server specify filters
   */
  getList(filterData: {}): Observable<Contact[]> {
    const data = {
      action: 'GetContacts'
    };
    return this.http.post<HttpResponse<Contact[] | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          const contacts = (res.body.contacts.contact !== undefined) ? res.body.contacts.contact : [];
          for (const contactsKey in contacts) {
            if (contacts.hasOwnProperty(contactsKey)) {
              contacts[contactsKey].permissions = (contacts[contactsKey].permissions.length > 0) ? contacts[contactsKey].permissions.split(',') : [];
            }
          }
          return contacts;
        }),
        catchError(this.handleError('getList', []))
      );
  }

  /**
   * Creates new contact and returns id of new record that must be greater than 0.
   * Returned value less than 0 means no contact has been created.
   * @param contact Contact data
   */
  add(data: any): Observable<number> {
    data.action = 'AddContact';
    if (!data.subaccount) {
      delete data.subaccount;
      delete data.permissions;
    } else {
      data.subaccount = 1;
    }
    let phone = null;
    if (data.telephoneNumber !== undefined && data.telephoneNumber.internationalNumber !== undefined) {
      const tmpPhone = data.telephoneNumber.internationalNumber.split(' ');
      if (tmpPhone.length > 1) {
        phone = tmpPhone[0] + '.';
        tmpPhone.splice(0, 1);
        tmpPhone.forEach(num => {
          phone += num;
        });
      }
    }
    data.phonenumber = phone;
    return this.http.post<Contact | any>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          return res.body.contactid !== undefined ? res.body.contactid : null;
        }),
        catchError(this.handleError('add', null))
      );
  }

  update(data: any): Observable<Contact> {
    data.action = 'UpdateContact';
    data.subaccount = (!data.subaccount) ? 'false' : data.subaccount;
    data.permissions = (data.permissions.length > 0) ? data.permissions : 'false';
    data.password2 = data.password2 === null ? undefined : data.password2;

    let phone = null;
    const tmpPhone = data.telephoneNumber !== null ? data.telephoneNumber.internationalNumber.split(' ') : null;
    if (tmpPhone && tmpPhone.length > 1) {
      phone = tmpPhone[0] + '.';
      tmpPhone.splice(0, 1);
      tmpPhone.forEach(num => {
        phone += num;
      });
    }
    data.phonenumber = phone;
    return this.http.post<Contact>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        catchError(this.handleError('update', null))
      );
  }

  /** DELETE: delete the hero from the server */
  delete(user: Contact | number): Observable<Contact> {
    const id = typeof user === 'number' ? user : user.id;
    const url = `${this.config.apiEndpoint}/user/request`;
    const data = {
      action: 'DeleteContact',
      contactid: id
    };

    return this.http.post<Contact>(url, data, httpOptions).pipe(
      catchError(this.handleError<Contact>('delete', null))
    );
  }

  getById(id: number): Observable<Contact> {
    return this.getList({}).pipe(
      map(user => user.find(tmpUser => tmpUser.id === id)),
      catchError(this.handleError('getById', null))
    );
  }
}
