import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {interval, Subscription} from 'rxjs';

import {ServersService} from '../../servers/servers.service';
import {NetworkServerStatus} from '../../_models/network-server-status';

@Component({
  selector: 'app-network-server',
  templateUrl: 'network-server.component.html',
})
export class NetworkServerComponent implements OnInit, OnDestroy {
  @Input() url: string;
  @Input() serverName: string;
  private subscription: Subscription = new Subscription()
  serverStatus: NetworkServerStatus;
  loading = {
    serverStatus: false
  };
  timer = interval(30000);

  constructor(
    private service: ServersService
  ) {}

  ngOnInit() {
    if (!this.url) {
      return false;
    }
    this.getTrafficData();
    // get fresh traffic data every 10 seconds
    const networkStatusSub = this.timer.subscribe(val => this.getTrafficData());
    this.subscription.add(networkStatusSub);
  }

  getTrafficData() {
    this.loading.serverStatus = true;
    const netSub = this.service.getNetworkServerStatus(this.url)
      .subscribe(res => {
        this.loading.serverStatus = false;
        if (res) {
          this.serverStatus = res
        }
      });
    this.subscription.add(netSub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
