<form [formGroup]="orderForm" (ngSubmit)="createOrder()" *ngIf="domainItems?.length > 0 || orderItems?.length > 0">
  <div class="col-sm-8 offset-2">
    <div class="card border-0 mb-3">
      <div class="bg-color2 card-header" i18n>Način plaćanja</div>

      <div class="p-3">
        <div *ngIf="submitted && f.paymentMethod.errors" class="alert alert-warning">
          <div *ngIf="f.paymentMethod.errors.required" i18n>Odaberi način plaćanja</div>
        </div>
        <app-loader *ngIf="loading.paymentMethods"></app-loader>

        <ng-container *ngIf="!loading.paymentMethods">
          <ng-container *ngIf="paymentMethods?.length > 0; else noPayment">
            <div class="form-check mb-3" *ngFor="let paymentMethod of paymentMethods; let index = index">
              <input type="radio" class="form-check-input" id="payment_credit_{{index}}" value="{{paymentMethod.module}}" formControlName="paymentMethod" />
              <label class="form-check-label" for="payment_credit_{{index}}">
                <ng-container [ngSwitch]="paymentMethod.module">
                  <span *ngSwitchCase="'monri'" i18n>Kreditna kartica</span>
                  <span *ngSwitchCase="'banktransfer'" i18n>Transakcijski račun</span>
                  <span *ngSwitchCase="'paypal'" i18n>Paypal</span>
                </ng-container>
              </label>

              <small id="paymentCredit{{index}}" class="form-text text-muted" *ngIf="paymentMethod.module !== 'paypal'">
                <ng-container [ngSwitch]="paymentMethod.module">
                  <span *ngSwitchCase="'monri'" i18n>American Express, Visa, MasterCard ili Diners kreditna kartica</span>
                  <span *ngSwitchCase="'banktransfer'" i18n>Plaćanje virmanom ili internet bankarstvom.<br>Po završetku nardužbe na tvoj mail će stići primjer uplatnice s detaljima za plaćanje.</span>
                </ng-container>
              </small>
            </div>
            <span *ngIf="client.credit > 0" i18n>Račun ili dio računa će se platiti od već postojećeg kredita</span>
          </ng-container>
          <ng-template #noPayment><div class="alert alert-danger" i18n>Sistemske postavke plaćanja nisu podešene</div></ng-template>
        </ng-container>
      </div>
    </div>

    <div class="d-flex justify-content-between mb-3">
      <app-loader *ngIf="loading.form"></app-loader>
      <a routerLink="../overview" class="btn bg-color2 width-13" type="submit" *ngIf="!loading.form" i18n>Nazad na pregled</a>
      <button class="btn btn-primary width-13" type="submit" *ngIf="!loading.form" i18n>Potvrdi narudžbu</button>
    </div>
  </div>
</form>
