<div *ngIf="announcement">
    <h2>{{announcement.title}}</h2>
    <br>
    <div [innerHTML]="announcement.announcement"></div>
    <br>
    <br>
    <h5>
        {{announcement.date | date:'d.M.yyyy H:mm'}}
    </h5>
    <button (click)="goBack()">go back</button>
    <br>
</div>
