import {Component, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';

import {WpService} from '../_services/wp.service';
import {DiscountPost} from '../_models/discount-post';

@Component({
  selector: 'app-discount',
  templateUrl: 'discount.component.html'
})
export class DiscountComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  discountPost: DiscountPost[];
  loading = false;

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    private service: WpService
  ) {
  }
  ngOnInit(): void {
    this.loading = true;
    const discountSub = this.service.getDiscountPost(this.localeId)
      .subscribe((res) => {
        this.loading = false;
        if (res) {
          this.discountPost = res;
        }
      });
    this.subscription.add(discountSub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
