import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {faEye, faEyeSlash} from '@fortawesome/free-regular-svg-icons';
import {OnExecuteData, ReCaptchaV3Service} from 'ng-recaptcha';

import {AlertService, AuthenticationService, ClientService} from '../_services';
import {SystemService} from '../_services/system.service';

@Component({
  templateUrl: 'password-new.component.html'
})
export class PasswordNewComponent implements OnInit, OnDestroy {
  newPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  shown = false;
  public recentToken = '';
  public readonly executionLog: OnExecuteData[] = [];
  passPattern = '^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\\w\\d\\s:])([^\\s]){8,64}$';

  private allExecutionsSubscription: Subscription;
  private singleExecutionSubscription: Subscription;
  nonce = null;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: ClientService,
    private alertService: AlertService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private readonly route: ActivatedRoute,
    private systemService: SystemService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.allExecutionsSubscription = this.recaptchaV3Service.onExecute
      .subscribe((data) => this.executionLog.push(data));
    this.allExecutionsSubscription.add(this.route.queryParamMap.subscribe(queryParams => {
      this.nonce = queryParams.get('nonce');
      if (!this.nonce) {
        this.alertService.error($localize`Nedostaje token`);
        this.router.navigate(['/']);
      }
    }));
    this.newPasswordForm = this.formBuilder.group({
      nonce: [this.nonce, Validators.required],
      password: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(64), Validators.pattern(this.passPattern)]],
      recaptcha: ['', Validators.required]
    });
  }

  generatePassword() {
    const newPass = this.systemService.genRandomPattern(this.passPattern);
    this.shown = true;
    this.newPasswordForm.patchValue({password: newPass});
  }

  // convenience getter for easy access to form fields
  get f() { return this.newPasswordForm.controls; }

  public executeAction(action: string): void {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    this.singleExecutionSubscription = this.recaptchaV3Service.execute(action)
      .subscribe((token) => {
        this.recentToken = token;

        this.newPasswordForm.patchValue({recaptcha : token});
        this.submitted = true;
        // stop here if form is invalid
        if (this.newPasswordForm.invalid) {
          return;
        }

        this.loading = true;

        this.userService.passReset(this.newPasswordForm.value)
          .pipe(first())
          .subscribe(
            res => {
              this.loading = false;
              if (res && res.body.status !== undefined) {
                  this.alertService.success(res.body.message, true);
                  this.router.navigate(['/']);
              }
            },
            error => {
              this.alertService.error(error);
              this.loading = false;
            });
      },
    error => {});
  }

  viewInput() {
    this.shown = !this.shown;
  }

  onSubmit() {
    this.executeAction('passwordreset');
  }

  public ngOnDestroy() {
    if (this.allExecutionsSubscription) {
      this.allExecutionsSubscription.unsubscribe();
    }
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }
}
