import {Component, Inject, LOCALE_ID} from '@angular/core';

import {APP_CONFIG, AppConfig} from '../app-config.module';

@Component({
  templateUrl: 'services.component.html'
})
export class ServicesComponent {
  serverUrl: string;
  webHostingUrl: string;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(LOCALE_ID) protected localeId: string
  ) {
    this.serverUrl = `${config.wpLink}/${localeId}/servers`;
    this.webHostingUrl = `${config.wpLink}/${localeId}/web-hosting`;
  }
}
