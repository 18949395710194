import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {QuicklinkStrategy} from 'ngx-quicklink';

import {AuthGuard, RoleGuard} from './_guards';

import {LoginComponent} from './login';
import {RegisterComponent} from './register';
import {ServicesComponent} from './services';
import {PasswordComponent} from './password';
import {PasswordNewComponent} from './password-new';
import {HomeComponent} from './home';
import {AnnouncementsComponent} from './announcements';
import {DiscountComponent} from './discount';
import {NotFoundComponent} from './_components/not-found.component';
import {externalUrlProvider} from './shared/external-url/';
import {UnblockIpComponent} from './unblock-ip';
import {PrintLayoutComponent} from './print-layout';
import {NetworkComponent} from './network';
import {QuicklinkSharedModule} from './quicklink-shared';
import {ActivateComponent} from './activate';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard, RoleGuard], data: { contentbg: 'bg-grey'} },
  { path: 'register', component: RegisterComponent },
  { path: 'unblock-ip', component: UnblockIpComponent, data: { contentbg: 'bg-grey'} },
  { path: 'reset-password', component: PasswordComponent },
  { path: 'new-password', component: PasswordNewComponent },
  { path: 'activate', component: ActivateComponent },
  { path: 'network', component: NetworkComponent },
  { path: 'discounts', component: DiscountComponent, data: { contentbg: 'bg-grey'} },
  { path: 'services', component: ServicesComponent, canActivate: [AuthGuard, RoleGuard], data: { animation: 'services'} },
  { path: 'announcements', component: AnnouncementsComponent, canActivate: [AuthGuard, RoleGuard],
    data: { animation: 'messages', contentbg: 'bg-grey'}},
  {  path: 'externalRedirect',  canActivate: [externalUrlProvider], component: NotFoundComponent },
  { path: 'print', outlet: 'print', component: PrintLayoutComponent},
  {
    path: 'profile',
    loadChildren: async () => (await import('./profile/profile.module')).ProfileModule,
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: 'domains',
    loadChildren: async () => (await import('./domains/domains.module')).DomainsModule,
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: 'hosting',
    loadChildren: async () => (await import('./web-hosting/web-hosting.module')).WebHostingModule,
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: 'dashboard',
    loadChildren: async () => (await import('./dashboard/dashboard.module')).DashboardModule,
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: 'servers',
    loadChildren: async () => (await import('./servers/servers.module')).ServersModule,
    canActivate: [AuthGuard, RoleGuard],
    data: {animation: 'servers'}
  },
  {
    path: 'support',
    loadChildren: async () => (await import('./support/support.module')).SupportModule,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'accounting',
    loadChildren: async () => (await import('./accounting/accounting.module')).AccountingModule,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'cart',
    loadChildren: async () => (await import('./cart/cart.module')).CartModule,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'ssl',
    loadChildren: async () => (await import('./ssl/ssl.module')).SslModule,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'dns',
    loadChildren: async () => (await import('./dns/dns.module')).DnsModule,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'hosted-exchange',
    loadChildren: async () => (await import('./hosted-exchange/hosted-exchange.module')).HostedExchangeModule,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'easy-dcim',
    loadChildren: async () => (await import('./easy-dcim/easy-dcim.module')).EasyDcimModule,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'backup',
    loadChildren: async () => (await import('./backup/backup.module')).BackupModule,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'licence',
    loadChildren: async () => (await import('./licence/licence.module')).LicenceModule,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'product-support',
    loadChildren: async () => (await import('./product-support/product-support.module')).ProductSupportModule,
    canActivate: [AuthGuard, RoleGuard],
  },
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [
    QuicklinkSharedModule,
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: QuicklinkStrategy
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
