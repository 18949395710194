<div class="col-4 mb-2 font-weight-bold d-lg-none">Poslužitelj</div>
<div class="col-8 mb-2 col-lg-4 mb-lg-0">{{serverName}}</div>

<div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>HTTP</div>
<div class="col-8 mb-2 col-lg-1 mb-lg-0">{{serverStatus?.apacheStatus}}</div>

<div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>FTP</div>
<div class="col-8 mb-2 col-lg-1 mb-lg-0">{{serverStatus?.ftpStatus}}</div>

<div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Mail</div>
<div class="col-8 mb-2 col-lg-1 mb-lg-0">{{serverStatus?.mailStatus}}</div>

<div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>CPU load</div>
<div class="col-8 mb-2 col-lg-1 mb-lg-0">{{serverStatus?.load}}</div>

<div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Iskorišteni RAM</div>
<div class="col-8 mb-2 col-lg-1 mb-lg-0">{{serverStatus?.memUsedPercent}}</div>

<div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Vrijeme rada</div>
<div class="col-8 mb-2 col-lg-3 mb-lg-0">{{serverStatus?.uptime}}</div>
