export class DomainAdditionalField {
  default: string;
  description: string;
  langVar: string;
  name: string;
  options: string[];
  size: number;
  type: string;
  value: string;
  required: boolean;
  errors: any;
}
