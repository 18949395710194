import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Announcement} from '../announcement';
import {ActivatedRoute} from '@angular/router';
import {AnnouncementService} from '../announcement.service';
import {Subscription} from 'rxjs';
import {Location} from '@angular/common';

@Component({
  selector: 'app-announcement-view',
  templateUrl: './announcement-view.component.html'
})
export class AnnouncementViewComponent implements OnInit, OnDestroy {
  @Input() announcement: Announcement;
  private subscription: Subscription = new Subscription();

  constructor(
      private route: ActivatedRoute,
      private service: AnnouncementService,
      private location: Location
  ) { }

  ngOnInit() {
    this.getAnnouncement();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getAnnouncement(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    const announcementSubs = this.service.getAnnouncement(id).subscribe(
        announcement => this.announcement = announcement
    );
    this.subscription.add(announcementSubs);
  }

  goBack(): void {
    this.location.back();
  }
}
