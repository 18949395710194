import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard, RoleGuard} from '../_guards';

import {AccountingComponent} from './accounting.component';
import {BillsListComponent} from './bills/list';
import {BillViewComponent} from './bills/view';

const accountingRoutes: Routes = [
  {
    path: '',
    component: AccountingComponent,
    children: [
      {path: '', redirectTo: '/accounting/bills', pathMatch: 'full'},
      {path: 'bills', component: BillsListComponent},
      {path: 'bill/:id', component: BillViewComponent, data: {contentbg: 'bg-grey'}}
    ],
    canActivate: [AuthGuard, RoleGuard],
    data: {animation: 'accounting'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(accountingRoutes)],
  exports: [RouterModule]
})
export class AccountingRoutingModule {}
