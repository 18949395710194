<div class="container-fluid h-100">
  <div class="row h-100" [class.isPrinting]="printService.isPrinting">
    <div class="col-sm-4 col-md-3 col-lg-2 main-menu bg-color2 text-left p-2"
         [ngStyle]="{'min-height' : menuHeight + 'px'}"
         *ngIf="currentUser?.id && !printService.isPrinting" [ngClass]="{ 'active': menuActive }">
      <div class="d-flex justify-content-around">
        <div class="logo text-center">
          <a routerLink="/">
            <img [src]="getLogoSrc()" class="custom-logo" alt="MyDataKnox" width="96" height="48">
          </a>
        </div>
        <div class="menu-toggle" (click)="toggleMenu()" [ngClass]="{ 'active-side': menuActive }">
          <div class="bar bar1"></div>
          <div class="bar bar2"></div>
          <div class="bar bar3"></div>
        </div>
      </div>
      <hr>
      <!-- nav -->
      <nav class="navbar p-0" *ngIf="currentUser?.id">
        <ul class="navbar-nav">
          <li>
            <a class="nav-item nav-link" routerLink="/" routerLinkActive="active" (click)="toggleMenu()" [routerLinkActiveOptions]="{exact: true}"><svg-icon src="assets/svg/home-white.svg" class="icon icon-15 mr-2"></svg-icon><span i18n>Početna</span></a>
          </li>
          <li>
            <a class="nav-item nav-link" routerLink="/dashboard" [queryParams]="{status: 'active'}" routerLinkActive="active" (click)="toggleMenu()"><svg-icon src="assets/svg/cpanel-pocetna.svg" class="icon icon-15 mr-2"></svg-icon><span i18n>Kontrolna ploča</span></a>
          </li>
          <li *ngIf="authService.isAllowed('products') || authService.isAllowed('domains')" routerLinkActive="active-parent" routerLinkActiveOptions="{exact: true}"
              [ngClass]="{'opened': userHasProduct([
'vps_standard', 'server_mdk_l', 'server_mdk_xl', 'dedicated', 'domain', 'hosting_standard', 'hosting_advanced', 'hosting_reseller', 'hosting_radio', 'ssl', 'licence_various', 'licence_cpanel',
'dns', 'email_office', 'backup_r1', 'support'
])}">
            <span class="nav-item nav-link"><svg-icon src="assets/svg/cpanel-moje-usluge.svg" class="icon icon-15 mr-2"></svg-icon><span i18n>Moje usluge i proizvodi</span></span>

            <app-loader *ngIf="loading.products"></app-loader>
            <ul *ngIf="!loading.products && (products?.length > 0 || domains.length > 0)">
              <li *ngIf="authService.isAllowed('products') && userHasProduct(['vps_standard', 'server_mdk_l', 'server_mdk_xl', 'dedicated'])">
                <a class="nav-item nav-link" routerLink="/servers" [queryParams]="{status: 'active'}" routerLinkActive="active" (click)="toggleMenu()" i18n>Serveri</a>
              </li>
              <li *ngIf="authService.isAllowed('domains') && userHasProduct(['domain'])">
                <a class="nav-item nav-link" routerLink="/domains/list" [queryParams]="{status: 'active'}" routerLinkActive="active" (click)="toggleMenu()" i18n>Domene</a>
              </li>
              <li *ngIf="authService.isAllowed('products') && userHasProduct(['hosting_standard', 'hosting_advanced', 'hosting_reseller', 'hosting_radio'])">
                <a class="nav-item nav-link" routerLink="/hosting" [queryParams]="{status: 'active'}" routerLinkActive="active" (click)="toggleMenu()" i18n>Web hosting</a>
              </li>
              <li *ngIf="authService.isAllowed('products') && userHasProduct(['ssl'])">
                <a class="nav-item nav-link" routerLink="/ssl/list" [queryParams]="{status: 'active'}" routerLinkActive="active" (click)="toggleMenu()" i18n>SSL certifikati</a>
              </li>
              <li *ngIf="authService.isAllowed('products') && userHasProduct(['domain', 'vps_standard', 'server_mdk_l', 'server_mdk_xl', 'dedicated'])">
                <a class="nav-item nav-link" routerLink="/dns" routerLinkActive="active" (click)="toggleMenu()" i18n>DNS hosting</a>
              </li>
               <li *ngIf="authService.isAllowed('products') && userHasProduct(['email_office'])">
                <a class="nav-item nav-link" routerLink="/hosted-exchange" [queryParams]="{status: 'active'}" routerLinkActive="active" (click)="toggleMenu()" i18n>Hosted Exchange</a>
              </li>
              <li *ngIf="authService.isAllowed('products') && userHasProduct(['backup_r1'])">
                <a class="nav-item nav-link" routerLink="/backup/list" [queryParams]="{status: 'active'}" routerLinkActive="active" (click)="toggleMenu()" i18n>Backup</a>
              </li>
              <li *ngIf="authService.isAllowed('products') && userHasProduct(['licence_cpanel', 'licence_various'])">
                <a class="nav-item nav-link" routerLink="/licence/list" [queryParams]="{status: 'active'}" routerLinkActive="active" (click)="toggleMenu()" i18n>Licence</a>
              </li>
              <li *ngIf="authService.isAllowed('products') && userHasProduct(['support', 'premiumsupport'])">
                <a class="nav-item nav-link" routerLink="/product-support/list" [queryParams]="{status: 'active'}" routerLinkActive="active" (click)="toggleMenu()" i18n>Podrška i savjetovanje</a>
              </li>
            </ul>
          </li>
          <li *ngIf="authService.isAllowed('invoices')">
            <a class="nav-item nav-link" routerLink="/accounting" routerLinkActive="active" (click)="toggleMenu()"><svg-icon src="assets/svg/cpanel-racuni.svg" class="icon icon-15 mr-2"></svg-icon><span i18n>Računi</span></a>
          </li>
          <li>
            <a class="nav-item nav-link" routerLink="/announcements" routerLinkActive="active" (click)="toggleMenu()"><svg-icon src="assets/svg/cpanel-obavijest.svg" class="icon icon-15 mr-2"></svg-icon><span i18n>Obavijesti</span></a>
          </li>
          <li>
            <a class="nav-item nav-link" routerLink="/discounts" routerLinkActive="active" (click)="toggleMenu()"><svg-icon src="assets/svg/cpanel-akcije.svg" class="icon icon-15 mr-2"></svg-icon><span i18n>Akcije</span></a>
          </li>
          <li *ngIf="authService.isAllowed('orders')">
            <a class="nav-item nav-link" routerLink="/services" routerLinkActive="active" (click)="toggleMenu()"><svg-icon src="assets/svg/cpanel-kupi-novi.svg" class="icon icon-15 mr-2"></svg-icon><span i18n>Dodaj nove usluge</span></a>
          </li>
          <li>
            <a class="nav-item nav-link" routerLink="/network" routerLinkActive="active" (click)="toggleMenu()"><svg-icon src="assets/svg/cpanel-stanje-mreze.svg" class="icon icon-15 mr-2"></svg-icon><span i18n>Stanje mreže</span></a>
          </li>
          <li routerLinkActive="active-parent" [routerLinkActiveOptions]="{exact: true}"
              [ngClass]="{'active-parent' : this.router.url.indexOf('/profile') >= 0 }">
            <span type="button" class="nav-item nav-link" (click)="menuCollapse.profile = !menuCollapse.profile; menuCollapse.support = true;"
                    [attr.aria-expanded]="!menuCollapse.profile" aria-controls="collapseProfile">
              <svg-icon src="assets/svg/cpanel-moj-profil.svg" class="icon icon-15 mr-2"></svg-icon><span i18n>Moj korisnički profil</span>
            </span>

            <div id="collapseProfile" [collapse]="menuCollapse.profile">
              <ul class="disable-hover">
                <li>
                  <a class="nav-item nav-link" routerLink="/profile/details" routerLinkActive="active" (click)="toggleMenu('profile')" i18n>Moj korisnički profil</a>
                </li>
                <li>
                  <a class="nav-item nav-link" routerLink="/profile/edit" routerLinkActive="active" (click)="toggleMenu('profile')" i18n>Uredi profil</a>
                </li>
                <li>
                  <a class="nav-item nav-link" routerLink="/profile/change-password" routerLinkActive="active" (click)="toggleMenu('profile')" i18n>Promjena lozinke</a>
                </li>
                <li *ngIf="authService.isAllowed('invoices')">
                  <a class="nav-item nav-link" routerLink="/profile/cards" routerLinkActive="active" (click)="toggleMenu('profile')" i18n>Kartice</a>
                </li>
                <li *ngIf="authService.isAllowed('affiliates')">
                  <a class="nav-item nav-link" routerLink="/profile/affiliate" routerLinkActive="active" (click)="toggleMenu('profile')" i18n>Suradnički račun</a>
                </li>
                <li *ngIf="authService.isAllowed('contacts')">
                  <a class="nav-item nav-link" routerLink="/profile/contacts" routerLinkActive="active" (click)="toggleMenu('profile')" i18n>Kontakti / podračuni</a>
                </li>
                <li *ngIf="authService.isAllowed('invoices')">
                  <a class="nav-item nav-link" routerLink="/profile/credit" routerLinkActive="active" (click)="toggleMenu('profile')" i18n>Stanje kredita</a>
                </li>
              </ul>
            </div>
          </li>
          <li routerLinkActive="active-parent" [routerLinkActiveOptions]="{exact: true}">
            <span type="button" class="nav-item nav-link" (click)="menuCollapse.support = !menuCollapse.support; menuCollapse.profile = true;"
                  [attr.aria-expanded]="!menuCollapse.support" aria-controls="collapseSupport">
              <svg-icon src="assets/svg/cpanel-podrska.svg" class="icon icon-15 mr-2"></svg-icon><span i18n>Podrška</span>
            </span>

            <div id="collapseSupport" [collapse]="menuCollapse.support">
              <ul class="disable-hover">
                <li *ngIf="authService.isAllowed('tickets')">
                  <a class="nav-item nav-link" routerLink="/support/ticket/new" routerLinkActive="active" routerLinkActiveOptions="{exact: true}" (click)="toggleMenu('support')" i18n>Pošalji upit podršci</a>
                </li>
                <li *ngIf="authService.isAllowed('tickets')">
                  <a class="nav-item nav-link" routerLink="/support/tickets" routerLinkActive="active" routerLinkActiveOptions="{exact: true}" (click)="toggleMenu('support')" i18n>Pregled mojih upita</a>
                </li>
                <li *ngIf="authService.isAllowed('emails')">
                  <a class="nav-item nav-link" routerLink="/support/emails" routerLinkActive="active" routerLinkActiveOptions="{exact: true}" (click)="toggleMenu('support')" i18n>Pregled mojih email poruka</a>
                </li>
                <li>
                  <a class="nav-item nav-link" routerLink="/unblock-ip" routerLinkActive="active" routerLinkActiveOptions="{exact: true}" (click)="toggleMenu('support')" i18n>Odblokiraj moju IP adresu</a>
                </li>
                <li>
                  <a class="nav-item nav-link" [href]="wpFaq" title="Često postavljena pitanja" i18n-title="Često postavljena pitanja" appExternalUrl i18n>Često postavljena pitanja</a>
                </li>
              </ul>
            </div>

          </li>
        </ul>
      </nav>
    </div>

    <div class="main-content h-100" [ngClass]="{'col-md-9 col-lg-12': currentUser?.id}">
      <div class="site-content bg-grey">
        <div class="secondary-menu bg-color1" *ngIf="!printService.isPrinting" [ngClass]="{'us': currentUser?.id}">
          <div class="container d-flex justify-content-between p-3 relative">
            <div class="d-flex">
              <div class="menu-toggle" (click)="menuActive = !menuActive" [ngClass]="{ 'active': menuActive }" *ngIf="!menuActive && currentUser?.id">
                <div class="bar bar1"></div>
                <div class="bar bar2"></div>
                <div class="bar bar3"></div>
              </div>
              <div class="logo text-center" *ngIf="!menuActive">
                <a routerLink="/" routerLinkActive="active">
                  <img [src]="getLogoSrc()" class="custom-logo" alt="MyDataKnox" width="100" height="100">
                </a>
              </div>
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <div class="btn-group" dropdown>
                <button id="button-language" dropdownToggle type="button" class="btn dropdown-toggle border-0" aria-controls="dropdown-language">
                  <fa-icon class="mr-2" [icon]="faGlobe"></fa-icon><span class="d-none d-lg-inline mr-2">{{currentLanguage.label}}</span><span class="caret"></span>
                </button>
                <ul id="dropdown-language" *dropdownMenu class="dropdown-menu language-switcher text-center p0" role="menu" aria-labelledby="button-language">
                  <li role="menuitem" *ngFor="let language of languageList">
<!--                    <a class="dropdown-item" [ngClass]="{'active': language.code === getLocale()}"  href="/{{language.code + router.url}}">{{language.label}}</a>-->
                    <button class="dropdown-item" [ngClass]="{'active': language.code === getLocale()}" (click)="setLanguage(language)">{{language.label}}</button>
                  </li>
                </ul>
              </div>

              <a class="nav-item nav-link d-none d-lg-flex" routerLink="/support" routerLinkActive="active" *ngIf="currentUser?.id" i18n><svg-icon src="assets/svg/cpanel-podrska.svg" class="icon icon-15 mr-2"></svg-icon>Podrška</a>

              <div class="nav-item nav-link" *ngIf="currentUser?.id" dropdown>
                <button id="button-basic" dropdownToggle type="button" class="btn border-0 dropdown-toggle d-flex align-items-center" aria-controls="dropdown-animated">
                  <svg-icon src="assets/svg/cpanel-obavijest.svg" class="icon icon-15 mr-2"></svg-icon>
                  <fa-icon *ngIf="loading.notifications" [icon]="faSpinner" [spin]="true"></fa-icon>
                  <ng-container *ngIf="!loading.notifications">
                    <span class="badge bg-red">{{ticketCounts?.clientunread + invoices?.length}}</span> <span class="caret"></span>
                  </ng-container>
                </button>
                <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem" class="bd-bottom " *ngIf="ticketCounts?.clientunread > 0">
                    <a class="dropdown-item p-2 text-wrap" routerLink="/support/tickets" i18n>Imaš {{ticketCounts.clientunread}} nepročitanih poruka. Pročitaj ih</a>
                  </li>
                  <li role="menuitem" *ngIf="invoices?.length > 0">
                    <a class="dropdown-item p-2 text-wrap" routerLink="/accounting/bills" [queryParams]="{status: 'unpaid'}" i18n>Imaš {{invoices.length}} neplaćenih računa. Plati ih što prije kako ne bi bilo smetnji pri korištenju usluga.</a>
                  </li>
                </ul>
              </div>

              <div class="btn-group" *ngIf="currentUser?.id" dropdown>
                <button id="button-user" dropdownToggle type="button" class="btn border-0 dropdown-toggle d-flex align-items-center" aria-controls="dropdown-user">
                  <fa-icon class="mr-2" [icon]="faUser"></fa-icon><span class="d-none d-lg-inline mr-2">{{currentUser?.firstname}}&nbsp;{{currentUser?.lastname}}</span><span class="caret"></span>
                </button>
                <ul id="dropdown-user" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-user">
                  <h6 class="dropdown-header d-lg-none bd-bottom pb-2">{{currentUser?.firstname}}&nbsp;{{currentUser?.lastname}}</h6>
                  <li role="menuitem">
                    <button class="dropdown-item" routerLink="/profile/edit" routerLinkActive="active" i18n>Uredi profil</button>
                  </li>
                  <li role="menuitem">
                    <button class="dropdown-item" routerLink="/profile/change-password" routerLinkActive="active" i18n>Promjena lozinke</button>
                  </li>
                  <li role="menuitem" *ngIf="authService.isAllowed('invoices')">
                    <button class="dropdown-item" routerLink="/profile/cards" routerLinkActive="active" i18n>Kartice</button>
                  </li>
                  <li role="menuitem" *ngIf="authService.isAllowed('contacts')">
                    <button class="dropdown-item" routerLink="/profile/contacts" routerLinkActive="active" i18n>Kontakti / podračuni</button>
                  </li>
                  <li role="menuitem">
                    <button class="dropdown-item" type="button" (click)="logout()" i18n>Odjava</button>
                  </li>
                </ul>
              </div>
              <div class="menu-cart ml-3 dropdown" *ngIf="cartItems > 0 || cartDomains > 0" dropdown #dropdown="bs-dropdown" (mouseenter)="dropdown.show()" (mouseleave)="dropdown.hide()" (click)="goToCart();">
                <button class="btn btn-secondary dropdown-cart-button" dropdownToggle type="button" id="dropdownCartButton" aria-controls="dropdown-cart">
                  <svg-icon src="assets/svg/shop-white.svg" class="icon icon-20 icon-shop mr-2"></svg-icon>
                  <span class="badge badge-light">{{cartItems + cartDomains}}</span>
                </button>

                <ng-container *ngIf="cartCurrency?.code">
                  <div id="dropdown-cart" class="dropdown-cart dropdown-menu dropdown-menu-right bg-color2" *dropdownMenu role="menu" aria-labelledby="dropdownCartButton">
                    <app-cart-items [cartCurrency]="cartCurrency"></app-cart-items>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <!-- main app container -->
        <app-alert></app-alert>
        <div class="ml-lg-0">
          <router-outlet #routerOutlet="outlet"></router-outlet>
        </div>
      </div>
    </div>
    <router-outlet name="print"></router-outlet>
  </div>
</div>
