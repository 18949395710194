import {Component, ElementRef, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';

import {Observable, Subscription} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {APP_CONFIG, AppConfig} from '../../../../app-config.module';
import {DomainService} from '../../../../domains/domain.service';
import {DomainPricing} from '../../../../domains/domain-pricing';
import {ProductDomainItem} from '../../../product-domain-item';
import {Currency} from '../../../../_models/currency';
import {CartService} from '../../../cart.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-domain-new',
  templateUrl: './product-domain-new.component.html',
})
export class ProductDomainNewComponent implements OnInit, OnDestroy {
  @Input() cartCurrency: Currency;
  @Input() name: Observable<string>;
  @Input() domainPricing: DomainPricing;
  @Input() index: number;
  @Input() filter: string;
  @Input() autoAdd: string;
  @ViewChild('eppTemplate') eppModal: ElementRef;
  domainType: string;
  modalRef: BsModalRef;
  domain: ProductDomainItem;
  private subscription: Subscription = new Subscription();
  loading = false;
  submitted = false;
  domainFullName: string;
  public displayHrk = false;

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private domainService: DomainService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private cartService: CartService,
  ) {}

  ngOnInit() {
    this.displayHrk = this.config.displayHrk;

    if (!this.name) {
      return false;
    }
    this.subscription.add(
      this.name.subscribe(name => {
        this.domainFullName = `${name}.${this.domainPricing.name}`;
        if (this.autoAdd && this.domainFullName !== this.autoAdd) {
          return;
        }
        this.loading = true;
        this.subscription.add(
          this.domainService.getDomainWhois(this.domainFullName)
            .subscribe(whois => {
              this.loading = false;
              if (whois !== null && whois !== undefined) {
                const domain = new ProductDomainItem();
                domain.id = this.domainFullName;
                domain.extension = this.domainPricing.name;
                domain.name = this.domainFullName;
                domain.domain = name;
                const inCartControl = this.cartService.domainInCart(domain);
                domain.status = (whois !== null) ? whois.status : null;
                domain.whois = (whois !== null) ? whois.whois : null;
                domain.domainType = (whois.status === 'unavailable') ? 'transfer' : 'register';
                this.domainType = domain.domainType;
                if (inCartControl !== null && inCartControl !== undefined) {
                  domain.idprotect = inCartControl.useIdProtect;
                  domain.useIdProtect = (domain.idprotect.availilable === true && inCartControl.useIdProtect === true);
                  domain.pricingPeriodId = inCartControl.pricingPeriodId;
                } else {
                  const idProtect = this.domainPricing.addons.idprotect;
                  if (typeof idProtect.price !== 'undefined') {
                    idProtect.price = parseFloat(idProtect.price);
                  }
                  domain.idprotect = idProtect;
                  domain.useIdProtect = domain.idprotect.availilable === true;
                  domain.pricingPeriodId = this.domainPricing[this.domainType] !== undefined ?
                    parseInt(Object.keys(this.domainPricing[this.domainType])[0], 10) : undefined;
                }
                domain.additionalFields = this.domainPricing.additionalFields;
                if (domain.additionalFields !== undefined) {
                  domain.additionalFields.forEach((fld, ind) => {
                    fld.value = (inCartControl !== null && inCartControl !== undefined) ? inCartControl.additionalFields[ind].value :
                      ((fld.type === 'dropdown') ? fld.options[0] : fld.default);
                  });
                }

                domain.prices = this.setDomainPrices(domain);
                let defaultPrice;
                if (inCartControl !== null && inCartControl !== undefined) {
                  defaultPrice = domain.prices.find(x => x.id === inCartControl.pricingPeriodId);
                } else {
                  defaultPrice = domain.prices[0];
                }

                domain.price = defaultPrice !== undefined ? defaultPrice.value : undefined;
                domain.added = (inCartControl !== undefined);
                this.domain = domain;
                if (this.autoAdd) {
                  setTimeout(() => {
                    this.updateCart();
                  }, 300);
                  // if (domain.status === 'available') {
                    // setTimeout(() => {
                    //   this.updateCart();
                    // }, 300);
                  // } else {
                    // this.alert.error('Domain unavailable');
                  // }
                }
              }
            })
        );
      })
    );
  }

  setDomainPrices(domain: ProductDomainItem = null) {
    if (!this.domain && domain) {
      this.domain = domain;
    }
    const prices = new Array<{ id: number, value: number }>();
    for (const key in this.domainPricing[this.domainType]) {
      if (this.domainPricing[this.domainType].hasOwnProperty(key)) {
        let price = parseFloat(this.domainPricing[this.domainType][key]);
        if (this.domain.useIdProtect && this.domain.idprotect.availilable) {
          price += parseFloat(this.domain.idprotect.price) * parseInt(key, 10);
        }
        prices.push({id: +key, value: +price});
      }
    }
    const selectedPrice = prices.find(x => x.id === this.domain.pricingPeriodId);
    this.domain.price = selectedPrice !== undefined ? selectedPrice.value : undefined;
    this.domain.prices = prices;

    return prices;
  }

  toggleIdProtect() {
    this.setDomainPrices();
  }

  getPricing(selectedId: number): number {
    const price = this.domain.prices.find(x => x.id === selectedId);
    return price.value;
  }

  setMonths() {
    const selectedPrice = this.domain.prices.find(x => x.id === this.domain.pricingPeriodId);
    this.domain.price = selectedPrice.value;
    this.domain.months = (selectedPrice) ? parseInt(String(selectedPrice.id), 10) : 1;
    const inCartControl = this.cartService.domainInCart(this.domain);
    if (inCartControl) {
      this.cartService.removeDomain(this.domain);
      this.cartService.addDomain(this.domain);
    }
  }

  openWhois(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', animated: true});
  }

  openEpp(template) {
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', animated: true, ignoreBackdropClick: true});
  }

  get f() { return this.domain.additionalFields; }

  updateCart(e = null, confirmTransfer: boolean = false, deleteAction: boolean = false) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const inCartControl = this.cartService.domainInCart(this.domain);
    if (this.domain.added) {
      this.cartService.removeDomain(this.domain);
    }
    this.submitted = true;
    if (!deleteAction) {
      if (((this.domain.domainType === 'transfer' && this.domainPricing.eppCode)
        || this.domainPricing.additionalFields !== undefined) && !confirmTransfer) {
        this.openEpp(this.eppModal);
        return;
      } else {
        if (!this.domainService.validateDomainAdditionalFields(this.domain.additionalFields)) {
          return;
        }
        this.cartService.addDomain(this.domain);
        this.submitted = false;
        if (this.modalRef) {
          this.modalRef.hide();
        }
      }
    }
    this.domain.added = !inCartControl;
  }

  getFreeDomainLink(): string {
    return `${environment.freeDomainLink}`;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
