import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

import {AlertService, AuthenticationService, ClientService} from '../_services';

@Component({
  selector: 'app-activate',
  templateUrl: 'activate.component.html'
})
export class ActivateComponent implements OnDestroy {
  loading = false;
  error = false;
  faSpinner = faSpinner;
  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alert: AlertService,
    public authenticationService: AuthenticationService,
    public client: ClientService
  ) {
    if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id !== undefined) {
      this.router.navigate(['']);
    }

    this.subscription.add(
      this.route.queryParams
        .subscribe(params => {
          if (params.nonce === undefined) {
            this.router.navigate(['']);
          }
          this.loading = true;
          this.subscription.add(
            this.client.activateUser(params.nonce)
              .subscribe((res: any) => {
                this.loading = false;
                if (res && res.success) {
                  this.error = false;
                  this.alert.success(res.message, true, null, {
                    disableTimeOut: true
                  });
                } else {
                  this.error = true;
                }
                setTimeout(() => {
                  this.router.navigate(['']);
                }, 5000);
              })
          );
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
