import {NgModule} from '@angular/core';
import {ExternalUrlDirective} from './external-url.directive';

@NgModule({
  declarations: [
    ExternalUrlDirective
  ],
  exports: [ ExternalUrlDirective ]
})
export class ExternalUrlModule {}
