import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {CountryISO} from 'ngx-intl-tel-input';
import {faEye, faEyeSlash} from '@fortawesome/free-regular-svg-icons';

import {Contact} from '../contact';
import {AuthenticationService} from '../../../_services';

@Component({
  selector: 'app-contact-details',
  templateUrl: 'contact-details.component.html'
})
export class ContactDetailsComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() title: string;
  @Input() submitted: boolean;
  @Input() user: Contact;
  @Input() edit = false;
  @Input() source: string;
  @Input() isContactSameAsParent: boolean;
  @Output() newPass = new EventEmitter<boolean>();
  @Output() passRequired = new EventEmitter<boolean>();
  shown = false;
  shown1 = false;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  // TooltipLabel = TooltipLabel;
  preferredCountries: CountryISO[] = [CountryISO.Croatia, CountryISO.Germany];
  currentCountry = null;
  currentCountryPhone = null;
  countries = [];

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService
  ) {}

  generateRootPassword() {
    this.shown = true;
    this.newPass.emit(true);
  }

  ngOnInit(): void {
    this.parentForm = (this.parentForm === undefined) ? this.formBuilder.group({
      phonenumber: [null],
      telephoneNumber: [null, Validators.required]
    }) : this.parentForm;

    const defaultLanguage = 'croatian';
    let currentCountry = 'HR';
    let currentCountryPhone = 'HR';

    if (this.user) {
      this.user.persontype = (this.user.companyname !== '') ? 'business' : 'private';
      const phoneNumber = (this.user.phonenumber !== null && this.user.phonenumber !== '') ? this.user.phonenumber.split('.') : '';
      const phone = (phoneNumber.length > 0) ? phoneNumber[1] : '';
      const telPhone = (phoneNumber.length > 0) ? phoneNumber[0] + phoneNumber[1] : '';
      this.parentForm.patchValue({phonenumber: phone});
      this.parentForm.patchValue({telephoneNumber: telPhone});
      currentCountry = (this.user.country !== undefined) ? this.user.country.toUpperCase() : currentCountry;
      currentCountryPhone = currentCountry;
    }
    for (const [key, value] of Object.entries(CountryISO)) {
      this.countries.push({
        code: value.toUpperCase(),
        name: key
      });
    }
    this.currentCountry = this.countries.find(country => country.code === currentCountry);
    this.currentCountryPhone = this.countries.find(country => country.code === currentCountryPhone);

    this.parentForm.patchValue({country : currentCountry});
    this.parentForm.patchValue({language : defaultLanguage});
  }

  viewInput() {
    this.shown = !this.shown;
  }

  hasPermission(permission: string) {
    return (!this.authService.currentUserValue.subaccount || (this.authService.currentUserValue.permissions.indexOf(permission) >= 0));
  }

  get f() {
    return this.parentForm.controls;
  }

  toggleSubaccount() {
    this.passRequired.emit(this.f.subaccount.value);
  }

  telInvalidPhone() {
    const defaultClass = (this.edit) ? 'form-control' : 'form-control-plaintext';
    if (!this.submitted) {
      return defaultClass;
    } else {
      return (this.f.telephoneNumber.errors === null) ? defaultClass : `${defaultClass} is-invalid`;
    }
  }
}
