import {Component, OnDestroy} from '@angular/core';

import {Subscription} from 'rxjs';

import {ServersService} from '../servers/servers.service';
import {ServerStatusServer} from '../_models/server-status-server';
import {NewtworkIssue} from '../_models/newtwork-issue';

@Component({
  templateUrl: 'network.component.html',
})
export class NetworkComponent implements OnDestroy {
  private subscription: Subscription = new Subscription()
  servers: ServerStatusServer[] = [];
  // Reported','Investigating','In Progress','Outage','Scheduled','Resolved'
  issues: NewtworkIssue[] = [];
  loading = {
    issues: false,
    server: false
  };
  priorities: string[] = ['Critical', 'High', 'Medium', 'Low'];

  constructor(
    private service: ServersService
  ) {
    this.loading.server = true;
    const netSub = this.service.getNetworkServers()
      .subscribe((servers) => {
        this.loading.server = false;
        if (servers) {
          this.servers = servers;
        }
      });
    this.subscription.add(netSub);

    this.loading.issues = true;
    const issuesSub = this.service.getNetworkIssues()
      .subscribe((issues) => {
        this.loading.issues = false;
        if (issues) {
          issues.sort((a, b) => {
            const aPriorIndex = this.priorities.indexOf(a.priority);
            const bPriorIndex = this.priorities.indexOf(b.priority);
            return (aPriorIndex > bPriorIndex) ? 1 : ((bPriorIndex > aPriorIndex) ? -1 : 0)
          });
          this.issues = issues;
        }
      });
    this.subscription.add(issuesSub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
