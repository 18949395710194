import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

import {AlertService} from '../_services';

@Component({
  selector: 'app-alert',
  template: ''
})

export class AlertComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(
    private alertService: AlertService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.subscription = this.alertService.getMessage()
      .subscribe(message => {
      if (message !== undefined) {
        if (message.type === 'error') {
          this.toastr.error(message.text, message.title, message.config);
        } else if (message.type === 'info') {
          this.toastr.info(message.text, message.title, message.config);
        } else if (message.type === 'warning') {
          this.toastr.warning(message.text, message.title, message.config);
        } else {
          this.toastr.success(message.text, message.title, message.config);
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
