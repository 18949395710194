<!--OS SELECT-->
<div *ngIf="!productId && !os; else editProduct">
  <div class="d-flex align-items-center p-3 p-tb-sm bg-color1 mb-3">
    <a routerLink="{{returnToOptionCid === null ? '/cart' : '../options/' + returnToOptionCid}}" tooltip="Natrag na odabir proizvoda" i18n-tooltip="Natrag na odabir proizvoda"
       class="color-white btn btn-outline-light rounded-light mr-3"><</a>
    <h3 class="color-white" i18n>Dedicirani server</h3>
  </div>

  <div *ngIf="productGroup?.id" class="mb-3">
    <app-step-overview [filter]="{gId: productGroup.id}" [redirectWithoutUser]="false"></app-step-overview>
  </div>

  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center bg-color1" i18n>Dodaj novi dedicirani server</div>
    <div class="d-flex flex-wrap flex-md-nowrap align-items-center p-3" *ngIf="dserverProducts">
      <p class="font-weight-bold mb-3 mb-sm-0 mr-3" i18n>Odaberi operativni sustav</p>
      <a *ngFor="let osName of dserverOses" routerLink="/cart/product" [queryParams]="{gid: productGroup.id, os: osName}" class="btn btn-bordered rounded-light p-2 mr-3">
        <svg-icon src="assets/svg/{{osName}}.svg" class="icon icon-30"></svg-icon>&nbsp;<span class="capitalize">{{osName}}</span></a>
    </div>
  </div>
</div>

<!--PRODUCT LIST-->
<div *ngIf="os">
  <div class="d-flex align-items-center p-3 p-tb-sm bg-color1 mb-0">
    <a routerLink="./" [queryParams]="{gid: productGroup.id}" tooltip="Natrag na odabir operativnog sustava" i18n-tooltip="Natrag na odabir operativnog sustava"
       class="color-white btn btn-outline-light rounded-light mr-3"><</a>
    <h3 class="color-white">{{getGroupName()}}</h3>
  </div>

  <div class="container-fluid bg-white">
    <div class="row bd-bottom pt-2 pb-2" *ngFor="let dedicatedGroupProduct of dserverProducts"
         [ngClass]="dedicatedGroupProduct.added ? 'bg-color8' : ''">
      <div class="col-lg-8 mb-3 mb-lg-0">
        <p class="font-weight-bold">{{getProductTranslatedName(dedicatedGroupProduct)}}</p>
        <p class="fsmall">{{getDescription(dedicatedGroupProduct)}}</p>
      </div>

      <div class="col-sm-6 col-lg-2 mb-3 mb-sm-0">
        <p *ngIf="dedicatedGroupProduct.prices.length === 1; else dropdownPrices">
          <span
            class="font-weight-bold">{{dedicatedGroupProduct.prices[0].value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
          / {{dedicatedGroupProduct.prices[0].nameTranslated}}
          <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(dedicatedGroupProduct.prices[0].value*7.53450) | number: '1.2-2'}} HRK)</span>
        </p>
        <ng-template #dropdownPrices>
          <ng-select [items]="dedicatedGroupProduct.prices"
                     [searchable]="false"
                     [clearable]="false"
                     [(ngModel)]="dedicatedGroupProduct.pricingPeriodId"
                     class="w-100"
                     bindValue="id"
                     bindLabel="name"
                     i18n-placeholder="Odaberi period"
                     placeholder="Odaberi period">

            <ng-template ng-label-tmp let-item="item">
              {{getPricing(dedicatedGroupProduct.prices, dedicatedGroupProduct.pricingPeriodId, 'value') | currency: cartCurrency.code : 'symbol-narrow'}}&nbsp;/&nbsp;{{getPricing(dedicatedGroupProduct.prices, dedicatedGroupProduct.pricingPeriodId, 'nameTranslated')}}&nbsp;
              <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(getPricing(dedicatedGroupProduct.prices, dedicatedGroupProduct.pricingPeriodId, 'value')*7.53450) | number: '1.2-2'}} HRK)</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <span
                class="font-weight-bold">{{item.value | currency: cartCurrency.code : 'symbol-narrow'}}</span>&nbsp;/&nbsp;{{item.nameTranslated}}
              <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(item.value*7.53450) | number: '1.2-2'}} HRK)</span>
            </ng-template>
          </ng-select>
        </ng-template>
      </div>

      <div class="col-sm-6 col-lg-2">
        <button *ngIf="!dedicatedGroupProduct.stockcontrol || (dedicatedGroupProduct.stockcontrol && dedicatedGroupProduct.stocklevel > 0)"
                class="btn btn-bordered rounded-light btn-green btn-sm w-100 h-select"
                [ngClass]="dedicatedGroupProduct.added ? 'btn-active' : ''" (click)="setProduct(dedicatedGroupProduct)">
          <svg-icon src="assets/svg/shop-white.svg" class="icon icon-15 icon-shop mr-2"></svg-icon>
          <span *ngIf="!dedicatedGroupProduct.added; else removeCartItem;" i18n>Dodaj u košaricu</span>
          <ng-template #removeCartItem>
            <span i18n>Ukloni iz košarice</span>
          </ng-template>
        </button>

        <button *ngIf="dedicatedGroupProduct.stockcontrol && dedicatedGroupProduct.stocklevel <= 0"
                [disabled]="true"
                class="btn btn-bordered rounded-light btn-sm w-100 h-select">
          <span i18n>Trenutno nedostupno</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!--EDIT PRODUCT-->
<ng-template #editProduct>
  <ng-container *ngIf="product?.pid && dserverForm">
    <div class="d-flex align-items-center p-3 p-tb-sm bg-color1 mb-3">
      <a routerLink="./" [queryParams]="{gid: productGroup.id}" tooltip="Natrag na odabir operativnog sustava" i18n-tooltip="Natrag na odabir operativnog sustava"
         class="color-white btn btn-outline-light rounded-light mr-3"><</a>
      <h3 class="color-white">{{product.name}}</h3>
    </div>

    <form [formGroup]="dserverForm">
      <div class="container-fluid">
        <div class="row bg-grey pb-2 pt-2 align-items-center justify-content-end d-lg-flex">
          <div class="col-lg-4 mb-3 mb-lg-0"><svg-icon src="assets/svg/{{getProductOs(product)}}.svg" class="icon icon-30 mr-2"></svg-icon>{{getProductName()}}</div>
          <div class="col-lg-2 mb-3 mb-lg-0">
            <div class="row font-weight-bold align-items-center">
              <label class="col-12 w-100" i18n>Količina:</label>
              <div class="col-12 input-group">
                <div class="input-group-prepend down" (click)="removeQuantity()">
                  <div class="input-group-text">-</div>
                </div>
                <input type="text" formControlName="quantity" min="1" class="form-control text-center font-weight-bold" [ngClass]="{ 'is-invalid': submitted && f.quantity.errors }" />
                <div class="input-group-append up" (click)="addQuantity()">
                  <div class="input-group-text">+</div>
                </div>
                <div *ngIf="submitted && f.quantity.errors" class="invalid-feedback w-100">
                  <div *ngIf="f.quantity.errors.required" i18n>Količina obavezna</div>
                  <div *ngIf="f.quantity.errors.min" i18n>Minimalno 1</div>
                  <div *ngIf="f.quantity.errors.numeric" i18n>Minimalno 1</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 mb-3 mb-lg-0"></div>
          <div class="col-lg-3 mb-3 mb-lg-0" *ngIf="product.prices && f.pricingPeriod.value">
            <label for="pricing{{product.id}}" class="col-12 w-100 pl-0" i18n>Osnovna cijena:</label>
            <ng-select [items]="product.prices"
                       (change)="setPricingPeriod($event)"
                       [searchable]="false"
                       [clearable]="false"
                       formControlName="pricingPeriod"
                       id="pricing{{product.id}}"
                       class="w-auto"
                       bindValue="id"
                       bindLabel="nameTranslated"
                       i18n-placeholder="Odaberi period"
                       placeholder="Odaberi period">
              <ng-template ng-label-tmp let-item="item">
                {{getPricing(product.prices, product.pricingPeriodId, 'value') | currency: cartCurrency.code : 'symbol-narrow'}}&nbsp;
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(getPricing(product.prices, product.pricingPeriodId, 'value')*7.53450) | number: '1.2-2'}} HRK)&nbsp;</span>
                /&nbsp;{{getPricing(product.prices, product.pricingPeriodId, 'nameTranslated')}}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <span class="font-weight-bold">{{item.value | currency: cartCurrency.code : 'symbol-narrow'}}</span>&nbsp;/&nbsp;{{item.nameTranslated}}
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{item.value*7.53450 | number: '1.2-2'}} HRK)</span>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <ng-container *ngIf="c.controls.length > 0">
          <div class="row pt-2 pb-2 align-items-center" [ngClass]="{'mb-3': last}"
               *ngFor="let configOption of c.controls; let i = index; let last = last;">
            <div class="col-lg-6 mb-1 mb-lg-0"
                 [ngClass]="{'pt-3': configOption.value.ticks.stepsArray?.length > 1 || configOption.value.ticks.ticksArray?.length > 1}">
              <div class="row" [formGroup]="configOption">
                <input type="hidden" formControlName="id"/>
                <h6>
                  <span class="col-lg-12 col-sm-6">{{configOption.value.label}}</span>
                  <span class="col-sm-6 d-lg-none mb-3 font-weight-bold fsmaller" i18n>{{configOption.value.slider}}</span>
                </h6>
                <div class="col-12">
                  <ngx-slider *ngIf="configOption.value.ticks.stepsArray?.length > 1 || configOption.value.ticks.ticksArray?.length > 1" formControlName="value"
                              (userChangeEnd)="updateCfgOption($event, configOption)" [options]="configOption.value.ticks"></ngx-slider>
                </div>
              </div>
            </div>
            <div class="col-lg-3 mb-3 mb-lg-0 font-weight-bold d-none d-lg-flex">{{configOption.value.slider}}</div>
            <div class="col-lg-3">
              <div class="row m-0">
                <span class="col-lg-12 btn btn-bordered rounded-light btn-sm w-100 bg-white">{{configOption.value.price | currency: cartCurrency.code : 'symbol-narrow'}} <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(configOption.value.price*7.53450) | number: '1.2-2'}} HRK)</span></span>
              </div>
            </div>
            <hr class="col-12 d-lg-none" *ngIf="!last">
          </div>
        </ng-container>

        <div class="row mb-1 pt-2">
          <div class="col-12 col-md-6">
            <ng-container *ngIf="cu.controls.length > 0">
              <ng-container *ngFor="let customField of cu.controls; let i = index;">
                <div class="row mb-3" [formGroup]="customField">
                  <div class="col-10 mb-3 mb-lg-0">
                    <div class="form-group">
                      <input type="hidden" formControlName="id"/>
                      <div class="mb-2"><h6>{{customField.value.label}}</h6></div>
                      <div class="d-flex justify-content-between input-group" [ngClass]="{ 'is-invalid': submitted && customField.get('value').errors }">
                        <a type="button" class="btn col-3 text-center p-1" *ngFor="let os of customField.value.filters; let last;"
                           [ngClass]="{
                           'btn-bordered rounded-light btn-sm': customField.get('value').value.includes(os),
                           'mr-3': !last
                           }"
                           (click)="setCustomFieldValue(os, customField)">
                          <svg-icon src="{{getIconPath(os)}}" class="icon icon-50"></svg-icon>
                        </a>
                      </div>
                      <div *ngIf="submitted && customField.get('value').errors" class="invalid-feedback w-100">
                        <div *ngIf="customField.get('value').errors.required">{{customField.value.label}} <span i18n>je obavezno polje</span></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 text-center">
                    <ng-select [items]="customField.get('options').value"
                               [searchable]="false"
                               formControlName="value"
                               class="row m-0"
                               i18n-placeholder="Odaberi"
                               placeholder="Odaberi">
                    </ng-select>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>

          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <div class="row align-items-center">
              <div class="col-12 col-md-12 mb-3 mb-md-0 form-group">
                <label for="password" class="col-form-label"><h6 i18n>Odaberi root lozinku</h6></label>
                <div class="input-group">
                  <input id="password" type="password" autocomplete="off" formControlName="rootPassword" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.rootPassword.errors }" [type]="!shown ? 'password' : 'text'" />
                  <div class="input-group-append toggable">
                    <div class="input-group-text" (click)="viewInput()">
                      <fa-icon tooltip="Vidi lozinku" i18n-tooltip="Vidi lozinku" [icon]="faEyeSlash" *ngIf="!shown"></fa-icon>
                      <fa-icon tooltip="Sakrij lozinku" i18n-tooltip="Sakrij lozinku" [icon]="faEye" *ngIf="shown"></fa-icon>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.rootPassword.errors" class="invalid-feedback w-100">
                    <div *ngIf="f.rootPassword.errors.required" i18n>Lozinka obavezna</div>
                    <div *ngIf="f.rootPassword.errors.minlength" i18n>Lozinka mora sadržavati najmanje 8 znakova</div>
                    <div *ngIf="f.rootPassword.errors.maxlength">Lozinka mora sadržavati najviše 16 znakova</div>
                    <div *ngIf="f.rootPassword.errors.pattern">Lozinka mora sadržavati najmanje jedno veliko slovo, jedno malo slovo i jedan broj</div>
                  </div>
                </div>
                <password-strength-meter [password]="f.rootPassword.value"></password-strength-meter>
              </div>

              <div class="col-12 col-md-12">
                <button class="btn btn-outline-dark w-100" type="button" (click)="generateRootPassword()" i18n>Kreiraj novu lozinku</button>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3 pt-2">
          <div class="col-10 col-md-6 mb-3 mb-md-0">
            <div class="row form-group align-items-center">
              <label for="hostname" class="col-12"><h6 i18n>Hostname</h6></label>
              <div class="col-12 input-group">
                <input id="hostname" type="text" formControlName="hostname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.hostname.errors }"/>
                <div *ngIf="submitted && f.hostname.errors" class="invalid-feedback w-100">
                  <div *ngIf="f.hostname.errors.required" i18n>Hostname je obavezno polje</div>
                  <div *ngIf="f.hostname.errors.minlength" i18n>Hostname mora sadržavati najmanje 3 znaka</div>
                  <div *ngIf="f.hostname.errors.pattern" i18n>Neispravan format za hostname</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row bg-white pt-2 pb-2 align-items-center">
          <div class="col-12 col-sm-6 col-lg-9 mb-3 mb-sm-0">
            <h6 i18n>Cijena konfiguracije</h6>
          </div>
          <div class="col-12 col-sm-6 col-lg-3">
            <span class="btn btn-bordered rounded-light btn-sm w-100 font-weight-bold bg-white">{{f.totalPrice.value | currency: cartCurrency.code : 'symbol-narrow'}}<span *ngIf="cartCurrency.code === 'EUR' && displayHrk"> ({{(f.totalPrice.value*7.53450) | number: '1.2-2'}} HRK)</span></span>

          </div>
        </div>

        <div class="row bg-white pt-2 pb-2 align-items-center">
          <div class="col-12 col-sm-6 col-lg-9 mb-3 mb-sm-0">
            <h6 i18n>Jednokratni trošak instalacije</h6>
          </div>
          <div class="col-12 col-sm-6 col-lg-3">
            <span class="btn btn-bordered rounded-light btn-sm w-100 font-weight-bold bg-white">{{f.setupPrice.value * f.quantity.value | currency: cartCurrency.code : 'symbol-narrow'}}<span *ngIf="cartCurrency.code === 'EUR' && displayHrk"> ({{((f.setupPrice.value * f.quantity.value)*7.53450) | number: '1.2-2'}} HRK)</span></span>
          </div>
        </div>

        <div class="row pt-3 pb-3 bg-white align-items-center">
          <div class="col-12 col-sm-6 col-lg-9 mb-3 mb-sm-0">
            <h6 i18n>ZA PLATITI ODMAH</h6>
          </div>
          <div class="col-12 col-sm-6 col-lg-3">
            <span class="btn btn-bordered rounded-light btn-sm w-100 font-weight-bold bg-white">{{f.setupPrice.value * f.quantity.value + f.totalPrice.value | currency: cartCurrency.code : 'symbol-narrow'}}<span *ngIf="cartCurrency.code === 'EUR' && displayHrk"> ({{((f.setupPrice.value * f.quantity.value + f.totalPrice.value)*7.53450) | number: '1.2-2'}} HRK)</span></span>
          </div>
        </div>

        <div class="row pt-2 pb-2 bg-grey bd-bottom pb-2 align-items-center" [ngClass]="{'font-italic mb-4' : cartCurrency.code !== 'EUR'}">
          <div class="col-12">
            <span i18n>Nakon toga:</span><span> {{f.totalPrice.value | currency: cartCurrency.code : 'symbol-narrow'}}</span><span *ngIf="cartCurrency.code === 'EUR' && displayHrk"> ({{(f.totalPrice.value*7.53450) | number: '1.2-2'}} HRK)</span><span *ngIf="product.prices && f.pricingPeriod.value"> {{getPricingPeriodName()}}</span>
          </div>
        </div>
        <div class="row pt-4 pb-2 bg-grey align-items-center font-italic mb-4">
          <div class="col-12">
            <span i18n>Cijena ne uključuje PDV. </span>
          </div>
        </div>
      </div>
      <input type="hidden" formControlName="basePrice">
      <input type="hidden" formControlName="setupPrice">
      <input type="hidden" formControlName="cartId">

      <div class="row d-flex justify-content-between">
        <div class="col-12 col-sm-5 mb-3 mb-sm-0">
          <button class="btn bg-color1 w-100" type="submit" (click)="updateCart(true)" i18n>+ Dodaj novi server</button>
        </div>
        <div class="col-12 col-sm-5 mb-3 mb-sm-0">
          <button class="btn btn-primary w-100" type="submit" (click)="updateCart()" i18n>Nastavi</button>
        </div>
      </div>
    </form>
  </ng-container>
</ng-template>
