import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

import {AuthenticationService} from '../../../_services';
import {Client} from '../../../_models';
import {Contact, ContactService} from '../../../profile/contacts';

@Component({
  selector: 'app-cart-step-user',
  templateUrl: './step-user.component.html'
})
export class StepUserComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() skipRedirect: boolean;
  @Input() parentForm: FormGroup;
  @Input() submitted = false;
  billUserForm: FormGroup;
  user: Client;
  contacts: Contact[] = [];
  loading = {
    contact: false
  };

  constructor(
    private auth: AuthenticationService,
    private contactService: ContactService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.billUserForm = this.formBuilder.group({
      name: [null, [Validators.required, Validators.minLength(6)]],
      address: [null, [Validators.required, Validators.minLength(3)]],
      clientId: [null]
    });
    const userSub = this.auth.currentUser
      .subscribe((user) => {
        if (user) {
          this.router.navigate(['/cart/overview']);
          this.user = user;
          this.billUserForm.patchValue({name: `${user.firstname} ${user.lastname}`});
          this.billUserForm.patchValue({address: user.address1});
          this.loading.contact = true;
          const contSub = this.contactService.getList({})
            .pipe(take(1))
            .subscribe((res) => {
              this.loading.contact = false;
              this.contacts = this.contacts.concat(res);
              this.createTmpContact();
            });
          this.subscription.add(contSub);
        }
      });
    this.subscription.add(userSub)
  }

  ngOnInit() {
    if (this.parentForm) {
      this.parentForm.addControl('contact', this.billUserForm);
    }
  }

  get f() { return this.billUserForm.controls; }

  createTmpContact() {
    const newContact = new Contact();
    newContact.email = this.user.email;
    newContact.firstname = this.user.firstname;
    newContact.id = this.user.id;
    newContact.lastname = this.user.lastname;
    newContact.address1 = this.user.address1;

    this.contacts.push(newContact);
  }

  setBillContact(contact: Contact) {
    this.billUserForm.patchValue({name: `${contact.firstname} ${contact.lastname}`});
    this.billUserForm.patchValue({address: contact.address1});
  }

  customSearchFn(term: string, item: any) {
    const cont: Contact = item;
    term = term.toLocaleLowerCase();
    if (cont.id === null && cont.email === 'new') {
      return false;
    }
    return cont.firstname.toLocaleLowerCase().indexOf(term) > -1 ||
      cont.lastname.toLocaleLowerCase().indexOf(term) > -1 ||
      (`${cont.firstname} ${cont.lastname}`).toLocaleLowerCase().indexOf(term) > -1 ||
      cont.email.toLocaleLowerCase().indexOf(term) > -1 ;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
