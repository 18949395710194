<div class="bd-bottom p-3 bg-white">
  <h3 class="font-weight-bold mb-2 text-color-2" i18n>Odblokiraj moju IP adresu</h3>
</div>

<div class="p-3">
  <form [formGroup]="unblockForm" class="p-3" (ngSubmit)="unblockIp()">
    <div class="form-group mb-3">
      <label class="mb-3" for="ip" i18n>IP adresa za provjeru</label>
      <input id="ip" type="text" formControlName="ip" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.ip.errors }" />
      <div *ngIf="submitted && f.ip.errors" class="invalid-feedback w-100">
        <div *ngIf="f.ip.errors.required" i18n>IP adresa obavezno polje</div>
        <div *ngIf="f.ip.errors.pattern" i18n>Neispravan format IP adrese</div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <button class="btn rounded-light btn-color2" *ngIf="!loading" i18n>Provjeri IP i ukloni blokadu</button>
      <app-loader *ngIf="loading"></app-loader>
    </div>
  </form>
</div>
