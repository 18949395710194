<div class="bd-bottom p-3 bg-white">
  <h4 class="font-weight-bold text-color-2" i18n>Stanje mreže</h4>
</div>

<div class="p-3">
  <accordion class="mb-4" [isAnimated]="true" [closeOthers]="false" *ngIf="issues?.length">
    <ng-container *ngFor="let issue of issues">
      <accordion-group [isOpen]="true" panelClass="product-list mb-3 rounded-light" *ngIf="issue.status !== 'Resolved'">
        <div class="btn btn-link accordion-heading clearfix" accordion-heading>
          <span class="d-inline-block font-weight-bold p-2">{{issue.title}}</span>
        </div>
        <div>
          <div class="alert mb-2" role="alert"
               [ngClass]="{
               'alert-danger': issue.priority === 'Critical',
               'alert-warning': issue.priority === 'High',
               'alert-info': issue.priority === 'Medium',
               'alert-success': issue.priority === 'Low'
               }">
            <span class="font-weight-bold" i18n>Prioritet</span>:&nbsp;{{issue.priority}}
          </div>
          <p class="border-bottom p-2"><span class="font-weight-bold">Utječe na <span i18n *ngIf="issue.type === 'Server'">server</span><span i18n *ngIf="issue.type === 'System'">sistem</span></span>:&nbsp;{{issue.affecting}}</p>
          <div class="border-bottom p-2" [innerHTML]="issue.description"></div>
          <p class="border-bottom p-2"><span class="font-weight-bold" i18n>Status</span>:&nbsp;{{issue.status}}</p>
          <p class="border-bottom p-2"><span class="font-weight-bold" i18n>Razdoblje</span>:&nbsp;{{issue.startdate | date: 'short'}}&nbsp;-&nbsp;{{issue.enddate | date: 'short'}}</p>
          <p class="p-2"><span class="font-weight-bold" i18n>Posljednja izmjena</span>:&nbsp;{{issue.lastupdate | date: 'short'}}</p>
        </div>
      </accordion-group>
    </ng-container>
  </accordion>
  <div class="mb-3" *ngIf="loading.issues">
    <app-loader></app-loader>
  </div>

  <div class="container-fluid">
    <div class="row bg-color2 p-2 d-none d-lg-flex" *ngIf="servers?.length">
      <div class="col-4" i18n>Poslužitelj</div>
      <div class="col-1" i18n>HTTP</div>
      <div class="col-1" i18n>FTP</div>
      <div class="col-1" i18n>Mail</div>
      <div class="col-1" i18n>CPU opterećenje</div>
      <div class="col-1" i18n>Iskorišteni RAM</div>
      <div class="col-3" i18n>Vrijeme rada</div>
    </div>

    <ng-container *ngIf="servers?.length">
      <app-network-server class="row bd-bottom p-2" *ngFor="let server of servers" [serverName]="server.name" [url]="server.statusaddress"></app-network-server>
    </ng-container>

    <div class="alert alert-info" *ngIf="!loading.server && !servers?.length" i18n>Nemaš zakupljenih usluga</div>
  </div>
  <app-loader *ngIf="loading.server"></app-loader>
</div>
