import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {switchMap, take} from 'rxjs/operators';
import {Subscription} from 'rxjs';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {ContactService} from '../contact.service';
import {Contact} from '../contact';

@Component({
  templateUrl: 'contact-list.component.html'
})
export class ContactListComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  contacts: Contact[];
  loading = false;
  modalRef: BsModalRef;

  constructor(
    private service: ContactService,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    const contactsSubs = this.route.paramMap.pipe(
      switchMap(params => this.service.getList(params))
    ).subscribe((contacts: Contact[]) => {
      this.loading = false;
      this.contacts = contacts;
    });
    this.subscription.add(contactsSubs);
  }

  deleteModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  confirm(user: Contact): void {
    this.modalRef.hide();
    this.delete(user);
  }

  decline(): void {
    this.modalRef.hide();
  }

  delete(user: Contact): void {
    this.loading = true;
    const deleteContactSub = this.service.delete(user)
        .pipe(take(1))
        .subscribe(deletedUser => {
        this.loading = false;
        this.contacts = this.contacts.filter(u => u !== user);
      }
    );
    this.subscription.add(deleteContactSub);
  }

  searchContact(value: string) {  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
