<div class="bg-grey p-2 mb-3">
  <span i18n>Kupnjom domene ne osigurava se email i web hosting. Pogledaj sve opcije na jednom mjestu</span>
</div>
<div class="mb-5">
  <div *ngFor="let group of productGroups" class="panel card product-list">
    <a routerLink="../../product" [queryParams]="{gid: group.id}" class="panel-title">
      <span class="btn d-block text-left bg-grey">{{getGroupName(group.tag)}}</span></a>
  </div>
</div>

<app-loader *ngIf="loading.groups"></app-loader>

<div class="container-fluid">
  <div class="row">
    <a routerLink="/cart/user" class="btn btn-green rounded-light col-12 col-lg-4 offset-lg-8">
      <span *ngIf="hasAdditional" i18n>Nastavi</span>
      <span *ngIf="!hasAdditional" i18n>Nastavi bez dodataka</span>
    </a>
  </div>
</div>
