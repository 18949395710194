import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

import {Client} from '../../../_models';
import {TicketService} from '../ticket.service';
import {AlertService, AuthenticationService} from '../../../_services';
import {Ticket} from '../ticket';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ticket-new',
  templateUrl: 'ticket-new.component.html'
})
export class TicketNewComponent implements OnInit, OnDestroy {
  @Input() withdraw = false;
  @Input() reply = false;
  @Output() replyChange = new EventEmitter<boolean>();
  @Input() ticket: Ticket;
  webUrl = environment.faqLink;
  private subscription: Subscription = new Subscription();
  formData = new FormData();

  openTicketForm: FormGroup;
  submitting = false;
  submitted = false;
  currentUser: Client;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private service: TicketService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) {}

  get f() {
    return this.openTicketForm.controls;
  }

  cancel() {
    if (!this.reply) {
      return this.router.navigate(['/support/tickets']);
    }
    this.replyChange.emit(false);
  }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    const message = (this.withdraw) ? $localize`Molim isplatu dostupnog suradničkog iznosa` : '';
    const formFields: any = {
      message: [message, [Validators.required, Validators.minLength(10)]],
      name: [{value: `${this.currentUser.firstname} ${this.currentUser.lastname}`, disabled: true}],
      email: [{value: `${this.currentUser.email}`, disabled: true}],
      priority: ['Low'],
      serviceid: [0],
      domainid: [''],
      attachments: [null],
      document: [null]
    };
    if (this.reply) {
      formFields.ticketid = [this.ticket.id];
    } else {
      const title = (this.withdraw) ? $localize`Zahtjev za isplatu dostupnog suradničkog iznosa` : null;
      formFields.subject = [title, [Validators.required, Validators.minLength(5)]];
      const deptid = (this.withdraw) ? 2 : 1;
      formFields.deptid = [deptid, [Validators.required]];
    }
    if (this.currentUser.subaccount === 1) {
      formFields.contactid =  [this.currentUser.id];
      formFields.clientid =  [this.currentUser.userid];
    } else {
      formFields.clientid =  [this.currentUser.id];
    }
    this.openTicketForm = this.formBuilder.group(formFields);
    if (this.withdraw) {
      this.onSubmit();
    }
  }

  createNewTicket() {
    this.submitting = true;
    const openAction = this.service.open(this.formData)
      .pipe(take(1))
      .subscribe(tmpTicket => {
          this.submitting = false;
          if (tmpTicket) {
            this.alertService.success($localize`Upit podršci uspješno je otvoren`, true);
            this.router.navigate(['/support/tickets']);
          }
        },
        error => {
          this.alertService.error(error);
        });
    this.subscription.add(openAction);
  }

  replyTicket() {
    this.formData.append('ticketnum', `${this.ticket.id}`);
    this.formData.append('ticketid', `${this.ticket.ticketid}`);

    this.submitting = true;
    const newReplySub = this.service.reply(this.formData)
      .pipe(take(1))
      .subscribe(tmpTicket => {
          this.submitting = false;
          if (tmpTicket) {
            this.alertService.success($localize`Odgovor na upit uspješno je poslan`, true);
            this.router.navigate(['/support/tickets']);
          }
        },
        error => {
          this.alertService.error(error);
        });
    this.subscription.add(newReplySub);
  }

  onSubmit() {
    this.submitted = true;

    // Handle form errors
    if (this.openTicketForm.invalid) {
      return;
    }
    for (const key in this.openTicketForm.value) {
      if (this.openTicketForm.value.hasOwnProperty(key)) {
        this.formData.append(key, this.openTicketForm.value[key]);
      }
    }

    if (this.reply) {
      this.replyTicket();
    } else  {
      this.createNewTicket();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
