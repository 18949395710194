import {DomainAdditionalField} from '../domains/domain-additional-field';

export class ProductDomainItem {
  id: string;
  name: string;
  extension: string;
  domain: string;
  price: number;
  priceDiscount: number;
  prices: {id: number, value: number}[];
  status: string;
  months: number;
  added: boolean;
  whois: string;
  idprotect: any;
  pricingPeriodId: number;
  dateAdded: number;
  cartProductId: number;
  domainType: string;
  useIdProtect: boolean;
  eppCode: string;
  tag: boolean;
  edit: boolean;
  additionalFields: DomainAdditionalField[];
  tax: boolean;
  domainrenewals: number;
}
