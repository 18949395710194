<div class="bd-bottom p-3 bg-white">
  <h3 class="font-weight-bold text-color-2" i18n>Stanje kredita</h3>
</div>

<div class="p-3">
  <div class="form-group row mb-3">
    <label for="balance" class="col-12 col-sm-6 col-lg-3 col-form-label color-dark-blue" i18n>Trenutno stanje kredita:</label>
    <div class="col-12 col-sm-6 col-lg-9" *ngIf="!loading.credit">
      <input type="text" readonly class="form-control-plaintext" id="balance" value="{{client?.credit | currency : currentCurrency.code : 'symbol-narrow'}}" />
    </div> 
    <app-loader *ngIf="loading.credit"></app-loader>
  </div>
  <button (click)="openModal(transactions)" class="btn btn-primary rounded-light" i18n>Pregled korištenja kredita</button>
  <div class="euro-banner p-2 text-center" *ngIf="currentCurrency.code === 'EUR' && displayHrk">Prema čl. 42 Zakona o uvođenju eura kao službene valute u Republici Hrvatskoj, dvojno iskazivanja cijena prema potrošačima u RH prikazuje se po fiksnom tečaju 1€ = 7,53450kn</div>
</div>
<ng-template #transactions class="modal modal-lg">
  <app-credit-transactions [modalRef]="modalRef"></app-credit-transactions>
</ng-template>
