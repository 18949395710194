<div [formGroup]="parentForm">
  <div class="form-group">
    <label for="email" i18n>Email</label>
    <input id="email" type="email" formControlName="email" class="form-control"
           [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
  </div>

  <div class="form-group row">
    <div class="col-12 col-sm-6 mb-2 mb-sm-0">
      <label for="firstname" i18n>Ime</label>
      <input id="firstname" type="text" formControlName="firstname" class="form-control"
             [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"/>
      <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback w-100">
        <div *ngIf="f.firstname.errors.required" i18n>Ime je obavezno polje</div>
      </div>
    </div>

    <div class="col-12 col-sm-6">
      <label for="lastname" i18n>Prezime</label>
      <input id="lastname" type="text" formControlName="lastname" class="form-control"
             [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"/>
      <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback w-100">
        <div *ngIf="f.lastname.errors.required" i18n>Prezime je obavezno polje</div>
      </div>
    </div>
  </div>
  <hr>
  <div class="form-group row">
    <div class="col">
      <div class="form-check">
        <input type="radio" class="form-check-input" name="person_type"
               id="simple_person" (click)="user.persontype = 'private'" [checked]="user && user.persontype === 'private'">
        <label for="simple_person" class="form-check-label" i18n>Fizička osoba</label>
      </div>
    </div>
    <div class="col">
      <div class="form-check">
        <input type="radio" class="form-check-input" name="person_type" [checked]="user && user.persontype === 'business'"
               id="legal_person" (click)="user.persontype = 'business'">
        <label for="legal_person" class="form-check-label" i18n>Pravna osoba</label>
      </div>
    </div>
  </div>
  <hr>

  <div class="form-group row">
    <div class="col-12 col-sm-6" *ngIf="(edit || source === 'cart')">
      <label for="tax_id" class="col-form-label" i18n>OIB</label>
      <input id="tax_id" type="text" formControlName="tax_id" class="form-control"
             [ngClass]="{ 'is-invalid': submitted && f.tax_id.errors }"/>
      <div *ngIf="submitted && f.tax_id.errors" class="invalid-feedback w-100">
        <div *ngIf="f.tax_id.errors.minlength" i18n>Minimalno 5 brojeva</div>
        <div *ngIf="f.tax_id.errors.invalidNumber" i18n>Samo brojevi</div>
      </div>
    </div>

    <div class="col-12 col-sm-6 mb-2 mb-sm-0" *ngIf="(edit || source === 'cart') && user.persontype === 'business'">
      <label for="companyname" class="col-form-label" i18n>Tvrtka</label>
      <input id="companyname" type="text" formControlName="companyname" class="form-control"
             [ngClass]="{ 'is-invalid': submitted && f.companyname.errors }"/>
      <div *ngIf="submitted && f.companyname.errors" class="invalid-feedback w-100">
      </div>
    </div>
  </div>

  <div class="form-group" *ngIf="!edit && source !== 'cart'">
    <label for="password" i18n>Lozinka</label>
    <div class="input-group">
      <input id="password" type="password" formControlName="password" class="form-control"
             [ngClass]="{ 'is-invalid': submitted && f.password.errors }"  [type]="!shown1 ? 'password' : 'text'"/>
      <div class="input-group-append toggable">
        <div class="input-group-text" (click)="shown1 = !shown1"><svg-icon src="assets/svg/oko.svg" tooltip="Vidi / sakrij lozinku" i18n-tooltip="Vidi / sakrij lozinku" [class.active]="shown1" class="icon icon-15"></svg-icon></div>
      </div>
      <div *ngIf="submitted && f.password.errors" class="invalid-feedback w-100">
        <div *ngIf="f.password.errors.required" i18n>Lozinka je obavezno polje</div>
        <div *ngIf="f.password.errors.minlength">Lozinka mora biti minimalno 8 znakova</div>
        <div *ngIf="f.password.errors.maxlength">Lozinka mora biti maksimalno 16 znakova</div>
        <div *ngIf="f.password.errors.pattern">Lozinka mora sadržavati najmanje jedno veliko slovo, jedno malo slovo i jedan broj</div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label for="address1" i18n>Adresa</label>
    <input id="address1" type="text" formControlName="address1" class="form-control"
           [ngClass]="{ 'is-invalid': submitted && f.address1.errors }"/>
    <div *ngIf="submitted && f.address1.errors" class="invalid-feedback w-100">
    </div>
  </div>

  <div class="form-group">
    <label for="address2" i18n>Adresa 2</label>
    <input id="address2" type="text" formControlName="address2" class="form-control"
           [ngClass]="{ 'is-invalid': submitted && f.address2.errors }"/>
    <div *ngIf="submitted && f.address2.errors" class="invalid-feedback w-100">
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-5">
      <label for="city" class="col-form-label" i18n>Grad</label>
      <input id="city" type="text" formControlName="city" class="form-control"
             [ngClass]="{ 'is-invalid': submitted && f.city.errors }"/>
      <div *ngIf="submitted && f.city.errors" class="invalid-feedback w-100">
      </div>
    </div>

    <div class="form-group col-md-4">
      <label for="state" class="col-form-label" i18n>Županija</label>
      <input id="state" type="text" formControlName="state" class="form-control"
             [ngClass]="{ 'is-invalid': submitted && f.state.errors }"/>
      <div *ngIf="submitted && f.state.errors" class="invalid-feedback w-100">
      </div>
    </div>

    <div class="form-group col-md-3">
      <label for="postcode" class="col-form-label"  i18n>Poštanski broj</label>
      <input id="postcode" type="text" formControlName="postcode" class="form-control"
             [ngClass]="{ 'is-invalid': submitted && f.postcode.errors }"/>
      <div *ngIf="submitted && f.postcode.errors" class="invalid-feedback w-100">
        <div *ngIf="f.postcode.errors.minlength" i18n>Minimalno 2 broja</div>
        <div *ngIf="f.postcode.errors.invalidNumber" i18n>Samo brojevi</div>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-3">
      <label for="country" class="col-form-label"  i18n>Država</label>
      <ng-select [items]="countries"
                 formControlName="country"
                 bindValue="code"
                 bindLabel="name"
                 id="country"
                 i18n-placeholder="Odaberi zemlju"
                 placeholder="Odaberi zemlju">
      </ng-select>
    </div>

    <div class="form-group col-md-4" *ngIf="source !== 'cart'">
      <label for="telephoneNumber" class="col-form-label" i18n>Telefonski broj</label>
     <p>
       <ngx-intl-tel-input *ngIf="currentCountry && edit"
                           [cssClass]="telInvalidPhone()"
                           [preferredCountries]="preferredCountries"
                           [enableAutoCountrySelect]="true"
                           [enablePlaceholder]="true"
                           [searchCountryFlag]="true"
                           [selectedCountryISO]="currentCountry.code"
                           [selectFirstCountry]="false"
                           [maxLength]="'15'"
                           [phoneValidation]="true"
                           id="telephoneNumber"
                           formControlName="telephoneNumber"></ngx-intl-tel-input>
     </p>
      <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback w-100"></div>
    </div>
  </div>

  <hr>

  <div class="mb-3" *ngIf="hasPermission('contacts')">
    <h4 class="mb-2" i18n>Aktiviraj podračun</h4>
    <div class="form-check mb-2" *ngIf="source !== 'cart'">
      <input id="subaccount" type="checkbox" formControlName="subaccount" (click)="toggleSubaccount()" class="form-check-input"/>
      <label for="subaccount" i18n>Želim kreirati podračun za ovu osobu kako bi mogla pristupati korisničkim stranicama i upravljati određenim dijelovima mog računa </label>
    </div>

    <ng-container *ngIf="f.subaccount && f.subaccount.value">
      <div class="form-group row align-items-baseline" *ngIf="source !== 'cart'">
        <div class="col-12 mb-3">
          <label for="password2" i18n>Lozinka</label>
          <div class="input-group">
            <input id="password2" type="password" formControlName="password2" class="form-control"
                   [ngClass]="{ 'is-invalid': submitted && f.password2.errors }" [type]="!shown ? 'password' : 'text'"/>
            <div class="input-group-append toggable">
              <div class="input-group-text" (click)="viewInput()">
                <fa-icon tooltip="Vidi lozinku" i18n-tooltip="Vidi lozinku" [icon]="faEyeSlash" *ngIf="!shown"></fa-icon>
                <fa-icon tooltip="Sakrij lozinku" i18n-tooltip="Sakrij lozinku" [icon]="faEye" *ngIf="shown"></fa-icon>
              </div>
            </div>
            <div *ngIf="submitted && f.password2.errors" class="invalid-feedback w-100">
              <div *ngIf="f.password2.errors.required" i18n>Lozinka je obavezno polje</div>
              <div *ngIf="f.password2.errors.minlength">Lozinka mora biti najmanje 8 znakova</div>
              <div *ngIf="f.password2.errors.maxlength">Lozinka mora biti najviše 16 znakova</div>
              <div *ngIf="f.password2.errors.pattern">Lozinka mora sadržavati najmanje jedno veliko slovo, jedno malo slovo i jedan broj</div>
            </div>
          </div>
          <password-strength-meter [password]="f.password2.value"></password-strength-meter>
          <p  i18n>Jačina lozinke</p>
        </div>

        <div class="col-12">
          <button class="btn rounded-light btn-outline-info mb-3" type="button" (click)="generateRootPassword()" i18n>Kreiraj novu</button>

          <div class="font-italic mb-3">
            <p i18n>Lozinka mora sadržavati 8-16 znakova</p>
            <p><strong i18n>Savjeti za sigurnu lozinku:</strong></p>
            <p i18n>Koristi velika i mala slova</p>
            <p i18n>Koristi brojeve</p>
            <p i18n>Izbjegavaj riječi iz rječnika</p>
          </div>
        </div>
      </div>

      <app-contact-permissions [parentForm]="parentForm" [source]="source" [user]="user" [edit]="edit" ></app-contact-permissions>
    </ng-container>
  </div>

  <div class="form-check" *ngIf="isContactSameAsParent">
    <input
      *ngIf="!edit"
      id="twofa"
      type="checkbox"
      aria-describedby="twofa-desc"
      [disabled]="true"
      [checked]="user?.twofa"
      class="form-check-input"
    />
    <input
      *ngIf="edit"
      id="twofa"
      type="checkbox"
      aria-describedby="twofa-desc"
      formControlName="twofa"
      class="form-check-input"
      [ngClass]="{ 'is-invalid': submitted && f.twofa.errors }"
    />
    <label for="twofa" class="form-check-label" i18n>
      Google dvofaktorska autentikacija
    </label>
    <p id="twofa-desc" class="fsmaller">
      <span i18n class="block">
        Nakon uključivanja ove opcije, molimo Vas da se odjavite i ponovno prijavite kako biste
        povezali svoj kod s Google Autentifikatora.
      </span>
    </p>
  </div>
  
  <hr>

  <h4 class="mb-3" i18n>Kontakt može primati sljedeće e-mail obavijesti:</h4>
  <div class="form-check" *ngIf="source !== 'cart'">
    <input id="generalemails" type="checkbox" formControlName="generalemails" class="form-check-input"/>
    <label for="generalemails" i18n>Općenite obavijesti</label>
  </div>

  <div class="form-check" *ngIf="source !== 'cart'">
    <input id="productemails" type="checkbox" formControlName="productemails" class="form-check-input"/>
    <label for="productemails" i18n>Obavijesti o proizvodima</label>
  </div>

  <div class="form-check" *ngIf="source !== 'cart'">
    <input id="domainemails" type="checkbox" formControlName="domainemails" class="form-check-input"/>
    <label for="domainemails" i18n>Obavijesti o domenama</label>
  </div>

  <div class="form-check" *ngIf="source !== 'cart'">
    <input id="invoiceemails" type="checkbox" formControlName="invoiceemails" class="form-check-input"/>
    <label for="invoiceemails" i18n>Obavijesti o ponudama i računima</label>
  </div>

  <div class="form-check" *ngIf="source !== 'cart'">
    <input id="supportemails" type="checkbox" formControlName="supportemails" class="form-check-input"/>
    <label for="supportemails" i18n>Obavijesti korisničke podrške</label>
  </div>
</div>
