import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './server-status.component.html'
})
export class ServerStatusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
