import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from './http-error-handler.service';
import {APP_CONFIG, AppConfig} from '../app-config.module';
import {Observable} from 'rxjs';
import {PaymentConfigData} from '../_models/payment-config-data';
import {catchError, map} from 'rxjs/operators';
import {PayMethod} from '../_models/pay-method';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.handleError = httpErrorHandler.createHandleError('PaymentService');
  }

  /**
   * Returns payment configuration data needed to create payment view.
   * NOTE: only monri components implemented
   * @param gateway string, default monri
   */
  public getPaymentConfigData(gateway: string = 'monri'): Observable<PaymentConfigData> {
    const data = {
      gateway,
      cache: false
    };
    return this.http.post<HttpResponse<PaymentConfigData>>(
      `${this.config.apiEndpoint}/user/payment/get-payment-config`, data, httpOptions)
      .pipe(
        map((res) => {
          return res.body;
        }),
        catchError(this.handleError('getPaymentConfigData', null))
      );
  }

  public getClientSecret(currency: string, amount: number, transactionType: string = 'authorize',
                         action: string = 'add_payment_method', gateway: string = 'monri', invoiceId: number = null):
    Observable<PaymentConfigData> {
    const data = {
      amount,
      action,
      currency,
      gateway,
      transactionType,
      invoiceId,
      cache: false
    };
    return this.http.post<HttpResponse<PaymentConfigData>>(
      `${this.config.apiEndpoint}/user/payment/get-client-secret`, data, httpOptions)
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(this.handleError('getClientSecret', null))
      );
  }

  /**
   * Adds payment method
   * @param formValue card and user data
   */
  public addRemoteToken(formValue: any = null): Observable<{ result: string, paymethod: PayMethod }> {
    const data = {
      action: 'AddRemoteToken',
      clientid: formValue.clientid !== undefined ? formValue.clientid : null,
      gateway: formValue.gateway !== undefined ? formValue.gateway : null,
      cardnumber: formValue.cardnumber !== undefined ? formValue.cardnumber : null,
      cardexpirydate: formValue.cardexpirydate !== undefined ? formValue.cardexpirydate : null,
      remotetoken: formValue.remotetoken !== undefined ? formValue.remotetoken : null,
      billingcid: formValue.billingcid !== undefined ? formValue.billingcid : null,
      description: formValue.description !== undefined ? formValue.description : null,
      cardtype: formValue.cardtype !== undefined ? formValue.cardtype : null
    };
    return this.http.post<HttpResponse<{ result: string, paymethod: PayMethod } | any>>(
      `${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          return res.body;
        }),
        catchError(this.handleError('addRemoteToken', null))
      );
  }

  /**
   * Returns list of user cards
   * @param filterData - paymethodid and/or type
   * @param clientId number
   */
  public getUserCardsList(filterData: {}, clientId: number): Observable<{ result: string, clientid: number, paymethods: PayMethod[] }> {
    const data = {
      action: 'GetPayMethods',
      clientid: clientId
    };
    return this.http.post<HttpResponse<{result: string, clientid: number, paymethods: PayMethod[]} | any>>(
      `${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(this.handleError('getUserCardsList', null))
      );
  }

  /**
   * Deletes payment card for user
   * @param payMethodId number
   */
  public deletePayMethod(payMethodId: number): Observable<boolean> {
    const data = {
      action: 'DeletePayMethod',
      paymethodid: payMethodId
    };
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          return res.body.result !== undefined && res.body.result === 'success';
        }),
        catchError(this.handleError('deletePayMethod', false))
      );
  }
}
