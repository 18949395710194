<ng-container *ngIf="!loading && domain?.id">
  <ng-container *ngIf="!filter || filter === domain.status">
    <div class="row align-items-center bd-bottom text-lg-center product domain pt-3 pb-3 pt-lg-2 pb-lg-2">
      <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Domena</div>
      <div class="col-8 mb-2 col-lg-2 mb-lg-0 text-left" [ngClass]="{'color-shadow': domain.status === 'unavailable'}">{{domain.name}}</div>

      <div class="col-4 mb-3 font-weight-bold d-lg-none" i18n>Status</div>
      <div class="col-8 mb-3 col-lg-2 mb-lg-0">
        <button type="button" (click)="openWhois(whoIsTemplate)" class="btn btn-sm rounded-light btn-info height-36 fsmaller" *ngIf="domain?.status === 'unavailable'">WHOIS</button>
        <svg-icon src="assets/svg/kvacica.svg" class="icon icon-15 icon-check mr-sm width-170" *ngIf="domain?.status === 'available'"></svg-icon>
      </div>

      <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>ID Zaštita</div>
      <div class="col-8 mb-2 col-lg-2 mb-lg-0 item-idprotect">
        <label class="check-container" for="idprotect{{index}}"  tooltip="WHOIS privatnost. Sakriva privatne i podatke kompanije"
        i18n-tooltip="WHOIS privatnost. Sakriva privatne i podatke kompanije">
          <input type="checkbox" class="apple-switch" id="idprotect{{index}}" (change)="toggleIdProtect()" [(ngModel)]="domain.useIdProtect" [disabled]="domain.idprotect.availilable == false || domain.added">
          <span class="checkmark"></span>
        </label>
      </div>

      <div class="col-4 mb-3 font-weight-bold d-lg-none" i18n>Ciklus plaćanja</div>
      <div class="col-8 mb-3 col-lg-3 mb-lg-0">
        <div class="prices" *ngIf="domain?.prices">
<!--          [disabled]="domain.added"-->
          <ng-select [items]="domain.prices"
                     [searchable]="false"
                     [clearable]="false"
                     class="w-100"
                     bindValue="id"
                     bindLabel="value"
                     [(ngModel)]="domain.pricingPeriodId"
                     (change)="setMonths()"
                     i18n-placeholder="Odaberi period"
                     placeholder="Odaberi period">

            <ng-template ng-label-tmp let-item="item">
              {{getPricing(domain.pricingPeriodId) | currency: cartCurrency.code : 'symbol-narrow'}}&nbsp;/&nbsp;<span *ngIf="item.id === 1" i18n>1 godina</span><span *ngIf="item.id === 2" i18n>2 godine</span><span *ngIf="item.id === 3" i18n>3 godine</span><span *ngIf="item.id === 4" i18n>4 godine</span><span *ngIf="item.id === 5" i18n>5 godina</span>&nbsp;
              <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(item.value*7.53450) | number: '1.2-2'}} HRK)</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <span class="font-weight-bold">{{item.value | currency: cartCurrency.code : 'symbol-narrow'}}</span>&nbsp;/&nbsp;<span *ngIf="item.id === 1" i18n>1 godina</span><span *ngIf="item.id === 2" i18n>2 godine</span><span *ngIf="item.id === 3" i18n>3 godine</span><span *ngIf="item.id === 4" i18n>4 godine</span><span *ngIf="item.id === 5" i18n>5 godina</span>
              <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{item.value*7.53450 | number: '1.2-2'}} HRK)</span>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Košarica</div>
      <div class="col-8 mb-2 col-lg-3 mb-lg-0">
        <button class="d-flex justify-content-center align-items-center btn btn-bordered rounded-light btn-green btn-sm height-36 w-100"
                [ngClass]="domain.added ? 'btn-active' : ''"
                (click)="updateCart($event, false, domain.added)"
                *ngIf="domain.status === 'available'">
          <svg-icon src="assets/svg/shop-white.svg" class="icon icon-15 icon-shop mr-3"></svg-icon>
          <span *ngIf="domain.added" i18n>Ukloni iz košarice</span>
          <span *ngIf="!domain.added" i18n>Dodaj u košaricu</span>
        </button>

        <button class="flex justify-content-center align-items-center btn btn-bordered rounded-light btn-sm height-36 w-100"
                (click)="updateCart($event, false, domain.added)"
                *ngIf="domain.status !== 'available'">
          <svg-icon src="assets/svg/shop-white.svg" class="icon icon-15 icon-shop mr-3"></svg-icon>
          <span class="mr-3" *ngIf="domain.added" i18n>Ukloni iz košarice</span>
          <span class="mr-3" tooltip="Prenesi domenu" i18n-tooltip="Prenesi domenu" *ngIf="!domain.added" i18n>Transfer</span>
          <svg-icon tooltip="Prenesi domenu" i18n-tooltip="Prenesi domenu" src="assets/svg/info-gray.svg" class="icon icon-15 icon-info"></svg-icon>
        </button>
      </div>
    </div>

    <div *ngIf="domain.name.indexOf('.hr') !== -1 || domain.name.indexOf('.com.hr') !== -1" class="fsmaller">
      <p i18n><i>Za .hr i .com.hr domene potrebno je imati hrvatski OIB. Navedene domene mogu biti i besplatne, provjeri <a appExternalUrl target="_blank" [href]="getFreeDomainLink()">ovdje</a>.</i></p>
      <p i18n><i>Podatke o vlasniku domene tražit ćemo te u koraku 'Korisnički podaci'</i></p>
    </div>
  </ng-container>

  <ng-template #whoIsTemplate>
    <div class="modal-header">
      <h4 class="modal-title pull-left" i18n>WHOIS - {{domain.name}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" [innerHTML]="domain.whois"></div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary rounded-light pull-right" aria-label="Close" (click)="modalRef.hide()" i18n>Zatvori</button>
    </div>
  </ng-template>

  <ng-template #eppTemplate>
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        <span *ngIf="domain.domainType === 'transfer'" i18n>Epp kod za transfer domene</span>
        <span *ngIf="domain.domainType !== 'transfer'" i18n>Postavke domene</span>
      </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3">
      <div class="alert alert-info" *ngIf="domain.domainType !== 'transfer'" i18n>Molimo pregledaj odabir domena i eventualne dodatke dostupne za njih.</div>
      <div class="form-group">
        <label for="domainName" class="col-form-label" i18n>Domena</label>
        <input id="domainName" type="text" readonly [(ngModel)]="domain.name" class="form-control-plaintext font-weight-bold" />
      </div>
      <div class="form-group" *ngIf="domain.domainType === 'transfer'">
        <label for="eppCode" class="col-form-label" i18n>EPP kod</label>
        <input id="eppCode" type="text" autocomplete="off" [(ngModel)]="domain.eppCode" class="form-control" />
      </div>

      <ng-container *ngIf="domainPricing.additionalFields">
        <div class="form-group" *ngFor="let addField of domainPricing.additionalFields; let index = index">
          <label for="domain_{{addField.langVar}}" class="col-form-label" i18n>{{addField.name}}</label>
          <input id="domain_{{addField.langVar}}" type="text" [required]="addField.required" [(ngModel)]="domain.additionalFields[index].value"
                 [ngClass]="{ 'is-invalid': submitted && f[index].errors }"
                 name="name" #name="ngModel" class="form-control" *ngIf="addField.type !== 'dropdown'" />
          <ng-select *ngIf="addField.type === 'dropdown'"
                     [items]="domainPricing.additionalFields[index].options"
                     [ngClass]="{ 'is-invalid': submitted && f[index].errors }"
                     [searchable]="false"
                     [clearable]="false"
                     [(ngModel)]="domain.additionalFields[index].value"
                     i18n-placeholder="Odaberi"
                     placeholder="Odaberi">
          </ng-select>
          <div *ngIf="submitted && f[index].errors" class="invalid-feedback w-100">
            <div *ngIf="f[index].errors.required" i18n>Obavezno polje</div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn rounded-light btn-green" (click)="updateCart($event, true)" i18n>Dodaj u košaricu</button>
    </div>
  </ng-template>
</ng-container>

<div class="d-flex" *ngIf="loading">
  <span class="mr-3" *ngIf="this.domainFullName" i18n>Provjeravam: {{this.domainFullName}}</span>
  <app-loader></app-loader>
</div>
