<div class="bd-bottom p-3 bg-white">
  <h4 class="font-weight-bold text-color-2 pl-2" i18n>Vijesti i obavijesti</h4>
</div>

<ng-container *ngIf="announcements?.length">
    <div class="row bd-bottom p-2" *ngFor="let announcement of announcements">
        <h3>
            <a routerLink="../announcement/{{announcement.id}}">{{announcement.title}}</a>
        </h3>
        <span>
            {{(announcement.announcementstripped.length > 300) ? announcement.announcementstripped.substr(0, 300) : announcement.announcementstripped}}
            <a routerLink="../announcement/{{announcement.id}}" i18n>Više...</a>
        </span>
        <span>
            {{announcement.date | date:'d.M.yyyy H:mm'}}
        </span>
    </div>
</ng-container>
<div *ngIf="!announcements?.length" i18n>Nema vijesti niti obavijesti</div>
<app-loader *ngIf="loading"></app-loader>
