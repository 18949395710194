import {Component, OnDestroy, OnInit} from '@angular/core';
import {Announcement} from '../announcement';
import {Subscription} from 'rxjs';
import {AnnouncementService} from '../announcement.service';
import {ActivatedRoute} from '@angular/router';
import {switchMap} from 'rxjs/operators';

@Component({
    templateUrl: './announcement-list.component.html'
})
export class AnnouncementListComponent implements OnInit, OnDestroy {
    loading = false;
    announcements: Announcement[];
    private subscription: Subscription = new Subscription();

    constructor(
        private service: AnnouncementService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.loading = true;
        const announcementsSubs = this.route.paramMap.pipe(
            switchMap(params => this.service.getList(params))
        ).subscribe((announcemets: Announcement[]) => {
            this.loading = false;
            this.announcements = announcemets;
        });
        this.subscription.add(announcementsSubs);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
