import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {OnExecuteData, ReCaptchaV3Service} from 'ng-recaptcha';

import {AlertService, AuthenticationService, ClientService} from '../_services';

@Component({
  templateUrl: 'password.component.html'
})
export class PasswordComponent implements OnInit, OnDestroy {
  passwordForm: FormGroup;
  loading = false;
  submitted = false;
  public recentToken = '';
  public readonly executionLog: OnExecuteData[] = [];

  private allExecutionsSubscription: Subscription;
  private singleExecutionSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: ClientService,
    private alertService: AlertService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.allExecutionsSubscription = this.recaptchaV3Service.onExecute
      .subscribe((data) => this.executionLog.push(data));
    this.passwordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      recaptcha: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.passwordForm.controls; }

  public executeAction(action: string): void {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    this.singleExecutionSubscription = this.recaptchaV3Service.execute(action)
      .subscribe((token) => {
        this.recentToken = token;

        this.passwordForm.patchValue({recaptcha : token});
        this.submitted = true;
        // stop here if form is invalid
        if (this.passwordForm.invalid) {
          return;
        }

        this.loading = true;

        this.userService.passReset(this.passwordForm.value)
          .pipe(first())
          .subscribe(
            res => {
              this.loading = false;
              if (res) {
                this.alertService.success(res.body.message, true);
                this.router.navigate(['/']);
              }
            },
            error => {
              this.alertService.error(error);
              this.loading = false;
            });
      },
    error => {});
  }

  onSubmit() {
    this.executeAction('passwordreset');
  }

  public ngOnDestroy() {
    if (this.allExecutionsSubscription) {
      this.allExecutionsSubscription.unsubscribe();
    }
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }
}
