import {Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {of, Subscription} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {CartService} from '../../cart.service';
import {Currency} from '../../../_models/currency';
import {ProductService} from '../../../_services/product.service';
import {Product} from '../../../_models/product';
import {ProductGroup} from '../../../_models/product-group';
import {CustomValidator} from '../../../_components';
import {ProductDomainItem} from '../../product-domain-item';
import {CartOrderItem} from '../../cart-order-item';
import { APP_CONFIG, AppConfig } from 'src/app/app-config.module';

@Component({
  selector: 'app-product-web-hosting',
  templateUrl: './product-web-hosting.component.html'
})
export class ProductWebHostingComponent implements OnInit, OnDestroy {
  @Input() productGroup: ProductGroup;
  @Input() cartCurrency: Currency;
  @Input() hideTitle = false;
  private subscription: Subscription = new Subscription();
  hostingForm: FormGroup;
  hosts: Product[];
  submitted = false;
  modalRef: BsModalRef;
  domains: ProductDomainItem[] = [];
  loading = {
    product: false
  };
  product: Product;
  connectedCartItem: CartOrderItem;
  public displayHrk = false;

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    @Inject(APP_CONFIG) private config: AppConfig,
    private cartService: CartService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: BsModalService
  ) {
    this.hostingForm = this.formBuilder.group({
      cartId: null,
      quantity: [1, [Validators.required, CustomValidator.numbericValidator]],
      pricingPeriod: null,
      totalPrice: null,
      domainType: 'register',
      regPeriod: null,
      domain: null
    });

    const domainsSub = this.cartService.domainItems$
      .subscribe(domains => {
        this.domains = domains;
        if (domains !== null && this.f.domainType.value === 'register') {
          const firstFreeDomain = domains.find(x => x.cartProductId === undefined);
          if (firstFreeDomain) {
            this.hostingForm.patchValue({domain: firstFreeDomain.name});
          }
        }
      });
    this.subscription.add(domainsSub);
  }

  get f() { return this.hostingForm.controls; }

  ngOnInit() {
    this.displayHrk = this.config.displayHrk;

    if (!this.productGroup) {
      const prodGroupSub = this.productService.getProductGroups()
        .pipe(take(1))
        .subscribe(groups => {
          this.productGroup = this.productService.getGroupByTag('hosting_standard');
        });
      this.subscription.add(prodGroupSub);
    }

    if (!this.cartCurrency) {
      const cartCurrSub = this.cartService.getCartCurrency()
        .subscribe(currency => {
          this.cartCurrency = currency;
        });
      this.subscription.add(cartCurrSub);
    }

    const routerQidSub = this.route.queryParams
      .pipe(switchMap(params => of(params)))
      .subscribe(params => {
        const productId = (typeof params.pid !== 'undefined') ? parseInt(params.pid, 10) : null;
        const groupId = (typeof params.gid !== 'undefined') ? parseInt(params.gid, 10) : this.productGroup.id;
        const cartId = (typeof params.cid !== 'undefined') ? parseInt(params.cid, 10) : null;
        const pricingPeriodId = (typeof params.period !== 'undefined') ? parseInt(params.period, 10) : null;
        const filterProduct = (productId) ? [productId] : null;
        this.loading.product = true;
        const prodSub = this.productService.getProducts(null, filterProduct, groupId, this.cartCurrency.code, true)
          .subscribe(prods => {
            this.loading.product = false;
            this.hosts = prods;
            this.product = (productId) ? prods[0] : null;
            if (productId) {
              if (this.product.showdomainoptions === 1) {
                this.hostingForm.get('domain').setValidators([
                  Validators.required, Validators.minLength(3),
                  Validators.pattern('^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\\.[a-zA-Z]{2,})+$')
                ]);
                this.hostingForm.get('domainType').setValidators([Validators.required]);
              }
              this.product.pricingPeriodId = (pricingPeriodId) ? pricingPeriodId : this.product.prices[0].id;
            } else {
              this.setPricingDefaults();
            }

            if (cartId !== null) {
              this.hostingForm.patchValue({cartId});
              this.connectedCartItem = this.cartService.getCartItemByCartId(cartId);
              if (this.connectedCartItem) {
                this.product.pricingPeriodId = this.connectedCartItem.pricingPeriodId;
                this.product.domain = this.connectedCartItem.domain;
                this.hostingForm.patchValue({domain: this.product.domain});
              }
            }
            if (this.product) {
              const price = this.product.prices.find(x => x.id === this.product.pricingPeriodId);
              this.setPricingPeriod(price);
            }
          });
        this.subscription.add(prodSub);
      });
    this.subscription.add(routerQidSub);
  }

  checkDomain(template: TemplateRef<any>) {
    const domain = this.domains.find(x => x.name === this.f.domain.value);
    if (domain !== undefined && typeof domain.cartProductId !== 'undefined') {
      this.connectedCartItem = this.cartService.getCartItemByCartId(domain.cartProductId);
      const currentSet = this.productService.getProductSetByTag(this.productGroup.tag);
      const connectedProductGroup = this.productService.getGroupByGroupId(this.connectedCartItem.gid);
      const connectedProductSet = this.productService.getProductSetByTag(connectedProductGroup.tag);
      if (connectedProductSet === currentSet) {
        this.modalRef = this.modalService.show(template);
      }
    } else {
      this.connectedCartItem = null;
    }
  }

  getGroupName(): string {
    return this.productService.getGroupNameByTag(this.productGroup.tag);
  }

  setPricingDefaults(): void {
    this.productService.setPricingDefaults(this.hosts);
  }

  setProduct(host: Product) {
    if (!host.pricingPeriodId) {
      return false;
    }
    this.router.navigate(['/cart/product'], {
      queryParams: {
        gid: host.gid,
        pid: host.pid,
        period: host.pricingPeriodId
      }
    });
  }

  getPriceForPeriodId(host: Product, pricingPeriodId: number): number {
    return this.productService.getPriceForPeriodId(host, pricingPeriodId);
  }

  getPricing(prices: any, selectedId: number, field: string): number|string {
    return this.productService.getPricing(prices, selectedId, field);
  }

  /**
   * Returns specific description string constructed from products description field specific to web hosting.
   */
  getDescription(host: Product): string {
    return this.productService.getDescription(host);
  }

  resetProduct() {
    this.product = null;
    this.router.navigate(['cart/product'], {queryParams: {
      gid: this.productGroup.id
      }});
  }

  getName(host: Product): string {
    return this.productService.getName(host);
  }

  getSelectedDomain(field: string = null) {
    let selectedDomain;
    if (this.domains && this.domains.length > 0) {
      selectedDomain = this.domains.find(x => x.name === this.f.domain.value);
    }
    if (!selectedDomain) {
      return;
    }
    return (field && selectedDomain) ? selectedDomain[field] : selectedDomain;
  }

  setPricingPeriod(price: {id: number, name: string}) {
    this.product.pricingPeriodId = price.id;
    this.hostingForm.patchValue({pricingPeriod: price.id});
    this.hostingForm.patchValue({totalPrice: this.getPriceForPeriodId(this.product, price.id)});
  }

  updateCart() {
    this.submitted = true;
    if (!this.product || this.hostingForm.invalid) {
      return false;
    }

    let cartItem = this.cartService.generateCartItem(this.product, this.productGroup,
      this.getName(this.product), this.getDescription(this.product), this.hostingForm.value);
    cartItem.domain = this.hostingForm.value.domain;
    cartItem = this.cartService.addOrderItem(cartItem);
    if (this.hostingForm.value.domainType === 'register' && cartItem.cartId !== undefined) {
      let domain = this.getSelectedDomain();
      if (domain) {
        domain = this.cartService.domainInCart(domain);
        domain.cartProductId = cartItem.cartId;
      }
      this.cartService.addDomain(domain);
    }
    this.hostingForm.patchValue({cartId: cartItem.cartId});
    this.submitted = false;

    return this.router.navigate([`/cart/options/${cartItem.cartId}`]);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
