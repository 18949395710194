<app-login *ngIf="!user"></app-login>

<div [formGroup]="billUserForm" *ngIf="user">
<table class="table table-striped text-left">
  <thead class="thead-dark">
  <tr>
    <td colspan="2">
      <div class="flex flex-between">
        <span i18n>Podaci za račun</span>
        <ng-select [items]="contacts"
                   (change)="setBillContact($event)"
                   formControlName="clientId"
                   bindValue="id"
                   bindLabel="email"
                   [searchFn]="customSearchFn"
                   i18n-placeholder="Odaberi postojeći kontakt"
                   placeholder="Odaberi postojeći kontakt">
          <ng-template ng-label-tmp let-item="item">
            <span *ngIf="item.firstname">{{item.firstname + item.lastname }}&nbsp;-&nbsp;</span><span *ngIf="item.id === null" i18n>Novi kontakt</span><span *ngIf="item.id !== null"><span *ngIf="item.id !== null">(</span>{{item.email}}<span *ngIf="item.id !== null">)</span></span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <span *ngIf="item.firstname">{{item.firstname + item.lastname }}&nbsp;-&nbsp;</span><span *ngIf="item.id === null" i18n>Novi kontakt</span><span *ngIf="item.id !== null"><span *ngIf="item.id !== null">(</span>{{item.email}}<span *ngIf="item.id !== null">)</span></span>
          </ng-template>
        </ng-select>
      </div>
    </td>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td class="width-150">
      <label for="bill_name" i18n>Ime i prezime:</label>
    </td>
    <td>
      <div class="form-group">
        <div class="input-group">
          <input id="bill_name" type="text" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
          <div *ngIf="submitted && f.name.errors" class="invalid-feedback w-100">
            <div *ngIf="f.name.errors.required" i18n>Ime je obavezno polje</div>
            <div *ngIf="f.name.errors.minlength" i18n>Ime mora sadržavati najmanje 6 znakova</div>
          </div>
        </div>
      </div>
    </td>
  </tr>
  <tr>
    <td><label for="bill_address" i18n>Adresa:</label></td>
    <td>
      <div class="form-group">
        <div class="input-group">
          <input id="bill_address" class="form-control" type="text" formControlName="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
          <div *ngIf="submitted && f.address.errors" class="invalid-feedback w-100">
            <div *ngIf="f.address.errors.required" i18n>Adresa je obavezno polje</div>
            <div *ngIf="f.address.errors.minlength" i18n>Adresa mora sadržavati najmanje 3 znaka</div>
          </div>
        </div>
      </div>
    </td>
  </tr>
  </tbody>
</table>
</div>
