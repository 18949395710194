<ng-container *ngIf="template === 'list'">
  <div class="d-flex justify-content-between bd-bottom p-3 bg-white">
    <div class="d-flex">
      <span class="link-black mr-3" (click)="clearCurrentAnnouncement()" tooltip="Nazad na popis objava" i18n-tooltip="Nazad na popis objava" *ngIf="title">&#8592;</span>
      <h4 class="font-weight-bold text-color-2 pl-2"><span *ngIf="!title" i18n>Obavijesti</span><span *ngIf="title">{{title}}</span></h4>
    </div>
  </div>

  <div class="p-3">
    <div class="container-fluid">
      <div class="row bg-color2 p-2 d-none d-lg-flex" *ngIf="!currentAnnouncement">
        <div class="col-9" i18n>Naslov</div>
        <div class="col-3" i18n>Datum</div>
      </div>

      <ng-container *ngIf="announcements?.length && !currentAnnouncement">
        <div class="row bg-white bd-bottom p-2" tooltip="Klikni za čitanje obavijesti" i18n-tooltip="Klikni za čitanje obavijesti" *ngFor="let announcement of announcements">
          <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Naslov</div>
          <div class="col-8 mb-2 col-lg-9 mb-lg-0 link-black" (click)="showAnnouncement(announcement)">{{announcement.title}}</div>

          <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Datum</div>
          <div class="col-8 mb-2 col-lg-3 mb-lg-0">{{announcement.date | date: 'shortDate' }}</div>
        </div>
      </ng-container>

      <div *ngIf="announcements?.length && currentAnnouncement" class="p-3 bg-white border rounded-light shadow" [innerHTML]="currentAnnouncement.announcement"></div>

      <div *ngIf="!announcements?.length && !loading" i18n>Trenutno nema objava</div>
      <app-pager *ngIf="this.currentAnnouncement === null || this.currentAnnouncement === undefined" [pager]="pager"></app-pager>
      <app-loader *ngIf="loading"></app-loader>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="template === 'div'">
    <ng-container>
      <div class="panel shadow p-2">
        <div class="d-flex flex-between">
          <a routerLink="/announcements" class="d-flex">
            <svg-icon src="assets/svg/cpanel-obavijest.svg" class="icon icon-20 mr-2"></svg-icon>
            <h5 i18n>Obavijesti</h5>
          </a>
        </div>
        <hr>
        <div *ngFor="let announcement of announcements">
          <p class="font-weight-bold">{{announcement.title}}</p>
          <div class="expandable-div" [ngClass]="{'active' : announcement.expanded}"
               [innerHTML]="announcement.announcement"></div>
        </div>
        <app-loader *ngIf="loading"></app-loader>
      </div>
    </ng-container>

    <ng-container *ngIf="!announcements?.length">
      <div class="width-13 mb-3" *ngFor="let announcement of announcements">
        <div class="panel shadow p-2 mr-3">
          <p i18n>Nema novih obavijesti</p>
        </div>
      </div>
    </ng-container>
</ng-container>
