import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {ProductCancelComponent} from './product-cancel.component';

@NgModule({
  declarations: [
    ProductCancelComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule
  ],
  exports: [
    ProductCancelComponent
  ]
})
export class ProductCancelModule {}
