import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {catchError, map, publishLast, refCount, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import RandExp from 'randexp';

import {APP_CONFIG, AppConfig} from '../app-config.module';
import {HandleError, HttpErrorHandler} from './http-error-handler.service';
import {Currency} from '../_models/currency';
import {PaymentMethod} from '../_models/payment-method';
import {CreditOptions} from '../_models/credit-options';
import {Credit} from '../_models/credit';
import {Pager} from '../support/tickets/pager';
import {StartupResults} from '../_models/startup-results';
import {TicketService} from '../support/tickets';
import {InvoiceService} from '../accounting/bills/invoice.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  private paymentMethodSubscription;
  private currencySubscription;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    @Inject(APP_CONFIG) private config: AppConfig,
    private ticketS: TicketService,
    private invoicesS: InvoiceService,
    @Inject(LOCALE_ID) protected localeId: string
  ) {
    this.handleError = httpErrorHandler.createHandleError('SystemService');
  }

  handleError: HandleError;
  startupRequestsData: Observable<StartupResults>;

  genRandomPattern(pattern: string) {
    const randexp = new RandExp(pattern);
    const pass = randexp.gen();
    // Check if password is in pattern
    const regexCheck = new RegExp(pattern);
    if (regexCheck.test(pass)) {
      return pass;
    }
    // Generate new one if it's not
    return this.genRandomPattern(pattern);
  }

  getConfig(attribute: string = null) {
    return (attribute && this.config[attribute] !== undefined) ? this.config[attribute] : this.config;
  }

  /**
   * Returns a list of all system defined currencies.
   */
  getCurrencies(): Observable<Currency[]> {
    const data = {
      action: 'GetCurrencies'
    };

    if (this.currencySubscription === undefined) {
      this.currencySubscription = this.http.post<HttpResponse<Currency[] | any>>
      (`${this.config.apiEndpoint}/public/request`, data, httpOptions)
        .pipe(
          map(res => {
            return res.body.currencies.currency !== undefined ? res.body.currencies.currency : [];
          }),
          take(1),
          publishLast(),
          refCount(),
          catchError(this.handleError('getCurrencies', []))
        );
    }

    return this.currencySubscription;
  }

  /**
   * Returns available payment methods list.
   */
  getPaymentMethods(): Observable<PaymentMethod[]> {
    const data = {
      action: 'GetPaymentMethods'
    };


    if (this.paymentMethodSubscription === undefined) {
      this.paymentMethodSubscription = this.http.post<HttpResponse<PaymentMethod[] | any>>
      (`${this.config.apiEndpoint}/public/request`, data, httpOptions)
        .pipe(
          map(res => {
            return res.body.paymentmethods !== undefined ? res.body.paymentmethods : [];
          }),
          take(1),
          publishLast(),
          refCount(),
          catchError(this.handleError('getPaymentMethods', []))
        );
    }

    return this.paymentMethodSubscription;
  }

  /**
   * Returns credit options for user.
   */
  getCreditOptions(): Observable<CreditOptions> {
    const data = {
      action: 'GetConfigurationValue',
      setting: 'CreditOptions'
    };
    return this.http.post<HttpResponse<{ result: string, creditoptions: CreditOptions } | any>>
    (`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          if (res.body.result !== undefined && res.body.creditoptions !== undefined && res.body.result === 'success') {
            const credit: CreditOptions = res.body.creditoptions;
            credit.minimumdepositNum = parseFloat(credit.minimumdeposit.replace('.', '').replace(',', '.'));
            credit.maximumbalanceNum = parseFloat(credit.maximumbalance.replace('.', '').replace(',', '.'));
            credit.maximumdepositNum = parseFloat(credit.maximumdeposit.replace('.', '').replace(',', '.'));
            credit.creditbalanceNum = parseFloat(credit.creditbalance);
            return credit;
          }
          return null;
        }),
        catchError(this.handleError('getCreditOptions', null))
      );
  }

  /**
   * Returns user credit transactions.
   */
  getUsersCredits(page: number, pageSize: number = 10): Observable<{ pager: Pager, userCredits: Credit[] }> {
    const data = {
      action: 'GetCredits'
    };
    const pager = new Pager();
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          let userCredits = new Array<Credit>();
          if (res.body.totalresults !== undefined) {
            pager.currentPage = page;
            pager.pageSize = pageSize;
            const totalItems = parseInt(res.body.totalresults, 10);
            pager.totalPages = Math.ceil(totalItems / pager.pageSize);
            pager.pages = [...Array(pager.totalPages).keys()].map(i => (i + 1));
          }
          if (res.body.credits !== undefined && res.body.credits.credit !== undefined) {
            userCredits = res.body.credits.credit;
          }
          return {
            pager,
            userCredits
          };
        }),
        catchError(this.handleError('getUsersCredits', {pager, userCredits: []}))
      );
  }

  /**
   * Get all requests required at startup.
   * NOTE: Requests are controlled on proxy, only params are allowed fore each request to be passed here.
   */
  getStartupRequests(actionParams: {}): Observable<StartupResults> {
    const data = {
      action: 'GetStartupRequests',
      language: this.localeId,
      cache: true
    };
    // Add action params if there are any
    for (const action in actionParams) {
      if (actionParams.hasOwnProperty(action)) {
        for (const param of actionParams[action]) {
          if (data[action] === undefined) {
            data[action] = [];
          }
          data[action].push(param);
        }
      }
    }
    // TODO: add invalidating of requests here if needed
    if (this.startupRequestsData === undefined) {
      this.startupRequestsData = this.sendGetStartupRequests(data, httpOptions);
    }
    return this.startupRequestsData;
  }

  resetSubscriptions() {
    this.startupRequestsData = undefined;
  }

  private sendGetStartupRequests(data = null, options = httpOptions): Observable<StartupResults> {
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          this.ticketS.setTicketCount(res.body.GetTicketCounts);
          this.invoicesS.setInvoices(res.body.GetInvoices);
          return res.body;
        }),
        take(1),
        publishLast(),
        refCount(),
        catchError(this.handleError('getStartupRequests', null))
      );
  }
}
