import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, map, publishLast, refCount, take} from 'rxjs/operators';

import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {HandleError, HttpErrorHandler} from '../../_services';
import {Ticket} from './ticket';
import {TicketCounts} from './ticket-counts';
import {Pager} from './pager';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  handleError: HandleError;
  ticketCounts: Observable<null | TicketCounts | any>;
  private ticketCountSubject: BehaviorSubject<TicketCounts>;
  public ticketCount$: Observable<TicketCounts>;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('TicketService');
    this.ticketCountSubject = new BehaviorSubject<TicketCounts>(null);
    this.ticketCount$ = this.ticketCountSubject.asObservable();
  }

  setTicketCount(ticketCounts: TicketCounts) {
    this.ticketCountSubject.next(ticketCounts);
  }

  /**
   * Get tickets from the server by specified filters in filterData.
   * Returns tickets collection and pager.
   * @param filterData Can contain search parameters such as page, status, deptid, clientid, email, subject
   * @param ticketCounts Holds all ticket counts on server.
   * @param pageSize Determines the size of page size for display
   * @param loadFromCache boolean
   */
  getList(filterData: {}, ticketCounts: TicketCounts | null, pageSize: number | 10, loadFromCache = false): Observable<{
    pager: Pager;
    tickets: Ticket[];
  }> {
    const keyPage = 'page';
    const keyStatus = 'status';
    const keyOrderBy = 'orderby';
    const keyOrder = 'order';
    /**
     * @todo Mario needs to add sorting params to backend/API
     */
    const allowedOrdering = [
      'deptid',
      'subject',
      'status',
      'date'
    ];
    const status = filterData.hasOwnProperty(keyStatus) ? filterData[keyStatus] : null;
    const page = filterData.hasOwnProperty(keyPage) ? parseInt(filterData[keyPage], 10) : 1;
    const orderby = filterData.hasOwnProperty(keyOrderBy) && allowedOrdering.includes(filterData[keyOrderBy]) ?
      filterData[keyOrderBy] : null;
    const order = filterData.hasOwnProperty(keyOrder) ? filterData[keyOrder] : 'asc';

    const startIndex = (page === 1) ? 0 : (pageSize * (page - 1));

    const data = {
      action: 'GetTickets',
      limitstart: startIndex,
      limitnum: pageSize,
      sortby: orderby,
      sort: order,
      ignore_dept_assignments: true,
      loadFromCache,
      saveCache: !loadFromCache
    };

    let totalCount = ticketCounts.allActive;
    if (!status || status === 'all') {
      totalCount += ticketCounts.closed;
    } else {
      totalCount = ticketCounts[status];
      data[keyStatus] = status;
    }
    return this.http.post<HttpResponse<Ticket[] | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          let tickets = [];
          let pages = [];
          let totalPages = 0;
          if (res.body.tickets.ticket !== undefined) {
            tickets = res.body.tickets.ticket;
            pages = [...Array(Math.ceil(totalCount / pageSize)).keys()].map(i => (i + 1));
            totalPages = Math.ceil(totalCount / pageSize);
          }

          /**
           * todo remove this when api functionality provided
           */
          if (order !== '') {
            tickets.sort((a, b) => {
              if (typeof a[orderby] === 'number') {
                return (order === 'asc') ? a[orderby] - b[orderby] : b[orderby] - a[orderby];
              } else {
                return (order === 'asc') ? a[orderby].localeCompare(b[orderby]) : b[orderby].localeCompare(a[orderby]);
              }
            });
          }

          return { pager: {
              pages,
              currentPage: page,
              totalPages,
              pageSize
            },
            tickets
          };
        }),
        catchError(this.handleError('getList', {
          pager: {
            pages: [],
            currentPage: page,
            totalPages: 0,
            pageSize
          }, tickets: []
        }))
      );
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  downloadAttachment(filename: string): Observable<Blob> {
    const data = {
      action: 'GetTicketAttachment',
      filename
    };
    const downloadOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'blob' as 'json',
      observe: 'response' as 'body'
    };

    // return this.http.post<HttpResponse<Blob>>(`${this.config.apiEndpoint}/user/download`, data, downloadOptions)
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          const file = res.body.filedata.data;
          return this.base64toBlob(file, '');
        }),
        catchError(this.handleError('downloadAttachment', null))
      );
  }

  /**
   * Returns ticket by tid and c.
   * @param tid Specific Client Ticket Number
   * @param c The client unique access of the ticket
   * @param loadFromCache boolean
   */
  getTicket(tid: string, c: string, loadFromCache = false): Observable<Ticket> {
    const data = {
      action: 'GetTicket',
      ticketnum: tid,
      repliessort: 'ASC',
      loadFromCache,
      saveCache: !loadFromCache
    };
    return this.http.post<HttpResponse<Ticket | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(this.handleError('getTicket', null))
      );
  }

  /**
   * Opens/creates a new ticket
   * @param ticket Data set for ticket
   */
  open(ticket: FormData): Observable<Ticket> {
    ticket.append('action', 'OpenTicket');
    ticket.append('cache', 'false');
    ticket.append('invalidateKeys', JSON.stringify(['GetTickets', 'GetTicketCounts']));

    return this.http.post<Ticket>(`${this.config.apiEndpoint}/user/request-upload`, ticket, {
      observe: 'response' as 'body'
    })
      .pipe(
        catchError(this.handleError('open', null))
      );
  }

  setAsRead(ticket: Ticket): Observable<any> {
    const data = {
      action: 'UpdateTicketReadStatus',
      ticketnum: ticket.tid,
      cache: false,
      invalidateKeys: ['GetTickets', 'GetTicketCounts', 'GetTicket']
    }
    return this.http.post<any>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(this.handleError('setAsRead', null))
      );
  }

  /**
   * update ticket
   */
  update(ticket: Ticket): Observable<Ticket> {
    const data = {
      action: 'UpdateTicket',
      ticketid: (ticket.id === undefined) ? ticket.ticketid : ticket.id,
      deptid: ticket.deptid,
      status: ticket.status,
      subject: ticket.subject,
      userid: ticket.clientid,
      name: ticket.name,
      cc: ticket.cc,
      priority: ticket.priority,
      message: ticket.message,
      customfields: ticket.customfields,
      cache: false,
      invalidateKeys: ['GetTickets', 'GetTicketCounts', 'GetTicket']
    };
    return this.http.post<any>(`${this.config.apiEndpoint}/user/request-upload`, data, httpOptions)
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(this.handleError('update', null))
      );
  }

  /**
   * Send a reply to already open ticket.
   * @param ticket Data set for ticket
   */
  reply(ticket: FormData): Observable<Ticket> {
    ticket.append('action', 'AddTicketReply');
    ticket.append('cache', 'false');
    ticket.append('invalidateKeys', JSON.stringify(['GetTickets', 'GetTicketCounts', 'GetTicket']));

    return this.http.post<Ticket>(`${this.config.apiEndpoint}/user/request-upload`, ticket, {
      observe: 'response' as 'body'
    })
      .pipe(
        catchError(this.handleError('reply', null))
      );
  }

  /**
   * Retrieves ticket counts.
   * @param filterData can contain optional parameters: ignoreDepartmentAssignments bool and includeCountsByStatus bool.
   */
  getTicketCounts(filterData: {}): Observable<TicketCounts> {
    const data = {
      action: 'GetTicketCounts',
      includeCountsByStatus: true
    };

    if (!this.ticketCounts) {
      this.ticketCounts = this.http.post<HttpResponse<TicketCounts | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
        .pipe(
          map(res => {
            const ticketCounts = res.body;
            if (res.body.status) {
              for (const key in res.body.status) {
                if (res.body.status.hasOwnProperty(key)) {
                  ticketCounts[key] = res.body.status[key].count;
                }
              }
            }
            return ticketCounts;
          }),
          take(1),
          publishLast(),
          refCount(),
          catchError(this.handleError('getTicketCounts', null))
        );
    }
    return this.ticketCounts;
  }
}